import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SBConversation from '@sendbird/uikit-react/GroupChannel';
import SBChannelList from '@sendbird/uikit-react/GroupChannelList';
import SBChannelSettings from '@sendbird/uikit-react/ChannelSettings';

export default function CustomizedApp() {
  // Get the current channel URL from url
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  // useState
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');

  const handleSelectChannel = url => {
    urlParams.set('id', url);
    navigate(`?${urlParams.toString()}`);
  };

  useEffect(() => {
    if (urlParams.has('id')) {
      setCurrentChannelUrl(urlParams.get('id'));
    }
  }, [urlParams]);

  return (
    <div className="customized-app">
      <div className="sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
          <SBChannelList
            selectedChannelUrl={currentChannelUrl}
            onChannelCreated={channel => {
              handleSelectChannel(channel?.url);
            }}
            onChannelSelect={channel => {
              handleSelectChannel(channel?.url);
            }}
          />
        </div>
        <div className="sendbird-app__conversation-wrap">
          <SBConversation
            channelUrl={currentChannelUrl}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
        </div>
        {showSettings && (
          <div className="sendbird-app__settingspanel-wrap">
            <SBChannelSettings
              channelUrl={currentChannelUrl}
              onCloseClick={() => {
                setShowSettings(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
