import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetCleaningCalendar = (start_date: string, period: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR, start_date, period],
    queryFn: () => API.room.getCleaningCalendar(start_date, period),
    enabled: !!start_date && !!period
  });

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => a.attributes.room_no.localeCompare(b.attributes.room_no));
  }, [data]);

  return {
    data: sortedData || [],
    status,
    isLoading,
    isFetching
  };
};
