import { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import useCommonStore from 'stores/useCommon';
import { useGetBooking } from 'hooks/useGetBooking';
import { MappingColumnBookingStatus } from 'constants/table';
import dayjs from 'dayjs';

interface Props {
  fileName: string;
  selectedState?: string;
  disabled: boolean;
  filterOptions: any;
}

const ExportBookingList = ({ fileName, selectedState, disabled, filterOptions }: Props) => {
  const { data: bookingListData } = useGetBooking(filterOptions, 0, 0);

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { currentBranch } = useCommonStore();

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Transactions`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:M1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      sheet.mergeCells('A2:M2');
      sheet.getCell('A2').value = 'BÁO CÁO DANH SÁCH ĐẶT PHÒNG';
      sheet.getCell('A2').font = { size: 11, bold: true };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      sheet.getCell('A4').value = `Ngày`;
      sheet.getCell('A4').font = { bold: true };
      sheet.getCell('B4').value = filterOptions.check_in_from;
      sheet.getCell('C4').value = `Đến`;
      sheet.getCell('C4').font = { bold: true };
      sheet.getCell('D4').value = filterOptions.check_in_to;
      sheet.getCell('E4').value = `Trạng thái`;
      sheet.getCell('E4').font = { bold: true };
      sheet.getCell('F4').value = selectedState || 'Tất cả';

      sheet.getRow(6).values = [
        'Mã đặt phòng',
        'Trạng thái',
        'Phòng',
        'Tên khách',
        'TA',
        'NL/TE',
        'Ghi chú',
        'Nhóm',
        'Ngày đến',
        'Ngày đi',
        'Loại giá',
        'Công ty',
        'OTA ID'
      ];
      sheet.getRow(6).eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' };
      });

      sheet.columns = [
        { key: 'booking_id', width: 15 },
        { key: 'status', width: 20 },
        { key: 'room_name', width: 15 },
        { key: 'partner_name', width: 25 },
        { key: 'hotel_travel_agency_name', width: 20 },
        { key: 'adult', width: 10 },
        { key: 'note', width: 35 },
        { key: 'labels', width: 10 },
        { key: 'check_in', width: 20 },
        { key: 'check_out', width: 20 },
        { key: 'pricelist', width: 20 },
        { key: 'medium_name', width: 20 },
        { key: 'cms_ota_id', width: 20 }
      ];

      bookingListData.forEach(item => {
        if (!item.expandableRows || item.expandableRows.length === 0) {
          const row = sheet.addRow({
            booking_id: item.booking_id,
            status: MappingColumnBookingStatus[item.status] || '',
            room_name: item.room_name || item.room_type_name + 'N/A',
            partner_name: item.partner_name,
            hotel_travel_agency_name: item.hotel_travel_agency_name || '',
            adult: `${item.adult}/${item.child}`,
            note: item.note || '',
            labels: item.labels.length > 0 ? item.labels.join(', ') : '',
            check_in: dayjs(item.check_in).format('DD/MM/YYYY - HH:mm'),
            check_out: dayjs(item.check_out).format('DD/MM/YYYY - HH:mm'),
            medium_name: item.medium_name || '',
            cms_ota_id: item.cms_ota_id || ''
          });

          row.eachCell({ includeEmpty: true }, cell => {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            cell.alignment = { horizontal: 'center' };
          });
        }

        if (item.expandableRows && item.expandableRows.length > 0) {
          item.expandableRows.forEach(expandableRow => {
            const subRow = sheet.addRow({
              booking_id: expandableRow.booking_line_id,
              status: MappingColumnBookingStatus[expandableRow.status] || '',
              room_name: expandableRow.room_name || expandableRow.room_type_name + ' N/A',
              partner_name: expandableRow.partner_name,
              hotel_travel_agency_name: expandableRow.hotel_travel_agency_name || '',
              adult: `${expandableRow.adult}/${expandableRow.child}`,
              note: expandableRow.note || '',
              labels: expandableRow.labels.length > 0 ? expandableRow.labels.join(', ') : '',
              check_in: dayjs(expandableRow.check_in).format('DD/MM/YYYY - HH:mm'),
              check_out: dayjs(expandableRow.check_out).format('DD/MM/YYYY - HH:mm'),
              medium_name: expandableRow.medium_name || '',
              cms_ota_id: expandableRow.cms_ota_id || ''
            });

            subRow.eachCell({ includeEmpty: true }, cell => {
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
              cell.alignment = { horizontal: 'center' };
            });
          });
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
      disabled={disabled}
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        Xuất dữ liệu
      </Button>
    </Dropdown>
  );
};

export default ExportBookingList;
