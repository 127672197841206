import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { getBreakfasts } from 'services/api/module/breakfasts.api';

export const useGetBreakfasts = (date?: string) => {
  const { data, isFetching, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.GET_BREAKFASTS, date],
    queryFn: () => {
      if (!date) {
        return;
      }
      return getBreakfasts(date);
    }
  });

  return {
    data,
    isFetching,
    isFetched
  };
};
