import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column'
  },

  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },

  image: {
    width: 60,
    height: 50,
    marginTop: 10,
    marginLeft: 40
  },

  titleContainer: { flexDirection: 'row', marginTop: 8, marginBottom: 16 },
  billContainer: { flexDirection: 'column', paddingBottom: '12px' },

  totalRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },

  logo: { width: 90 },
  text: { fontSize: 11, fontFamily: 'Inter' },
  textExSmallBold: { fontSize: 11, fontWeight: 600, fontFamily: 'Inter' },
  textBold: { fontSize: 14, fontWeight: 600, fontFamily: 'Inter' },
  textSmallBold: { fontSize: 12, fontWeight: 600, fontFamily: 'Inter' },

  tableHeader: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 10,
    backgroundColor: '#fafafa',
    padding: 5,
    paddingTop: 8
  },
  subHeader: {
    fontWeight: 'bold',
    fontSize: 8,
    backgroundColor: '#fafafa',
    padding: 3
  },
  tableCell: {
    fontSize: 8,
    padding: 3
  }
});

export default styles;
