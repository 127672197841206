import { useQuery } from '@tanstack/react-query';
// import { useEffect } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
// import { queryClient } from 'index';

export const useGetPmsUser = () => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_SUB_HK_STAFF],
    queryFn: () => API.hk.getPmsUser()
  });

  return {
    data: data || [],
    isLoading,
    isFetching
  };
};
