import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetLatestOcrCustomerInfo = (date_from?: string) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_LATEST_OCR_CUSTOMER_INFO],
    staleTime: 1000 * 60,
    queryFn: () => API.ocr.getLatestOcrCustomerInfo(String(date_from)),
    enabled: !!date_from,
    refetchInterval: 3000,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false
  });

  return {
    data: data?.data,
    isLoading
  };
};
