import _ from 'lodash';
import { create } from 'zustand';
import { BookingLine } from 'services/api/type/booking.type';
import { RoomLock } from 'services/api/type/room.type';

export interface BookingStoreType {
  isLoadingBookingDetail: boolean;
  bookingLineDetail: BookingLine | undefined;
  pageBookingList: number;
  bookingLineId: number | undefined;
  roomId: number | undefined;
  roomNo: string | undefined;
  roomLock: RoomLock | undefined;
  selectedCustomerId: number | undefined;
  checkedListBookingLineId: number[];
  isActionOnGroup: boolean;

  setIsLoadingBookingDetail: (isLoading: boolean) => void;
  setBookingLineDetail: (bookingLine: BookingLine | undefined) => void;
  setPageBookingList: (page: number) => void;
  setBookingLineId: (bookingLineId: number) => void;
  setRoom: (roomId?: number, roomNo?: string, roomLock?: RoomLock) => void;
  resetRoom: () => void;
  setSelectedCustomerId: (customerId: number | undefined) => void;
  setCheckListBookingLineId: (checkedListBookingLineId: number[]) => void;
  addCheckListBookingLineId: (id: number) => void;
  removeCheckListBookingLineId: (removedId: number) => void;
  addCheckListBookingLineIds: (ids: number[]) => void;
  removeCheckListBookingLineIds: (ids: number[]) => void;
  setIsActionOnGroup: (isActionOnGroup: boolean) => void;
}

const useBookingStore = create<BookingStoreType>(set => ({
  isLoadingBookingDetail: false,
  bookingLineDetail: undefined,
  bookingLineId: undefined,
  roomId: undefined,
  roomNo: '',
  roomLock: undefined,
  selectedCustomerId: undefined,
  isActionOnGroup: false,

  setBookingLineId: (bookingLineId: number) => set(() => ({ bookingLineId })),
  setRoom: (roomId?: number, roomNo?: string, roomLock?: RoomLock) =>
    set(() => ({ roomId, roomNo, roomLock })),
  resetRoom: () =>
    set(() => ({
      roomId: undefined,
      roomNo: undefined,
      roomLock: undefined
    })),

  setIsLoadingBookingDetail: (isLoading: boolean) =>
    set(() => ({ isLoadingBookingDetail: isLoading })),
  setBookingLineDetail: (bookingLine: BookingLine | undefined) =>
    set(() => ({ bookingLineDetail: bookingLine })),

  pageBookingList: 1,
  setPageBookingList: (page: number) => set(() => ({ pageBookingList: page })),

  setSelectedCustomerId: (selectedCustomerId: number | undefined) =>
    set(() => ({ selectedCustomerId })),

  checkedListBookingLineId: [],
  setCheckListBookingLineId: (checkedListBookingLineId: number[]) =>
    set(() => ({ checkedListBookingLineId })),
  addCheckListBookingLineId: (id: number) =>
    set(state => ({ checkedListBookingLineId: [...state.checkedListBookingLineId, id] })),
  removeCheckListBookingLineId: (removedId: number) =>
    set(state => ({
      checkedListBookingLineId: state.checkedListBookingLineId.filter(id => id !== removedId)
    })),

  addCheckListBookingLineIds: (ids: number[]) =>
    set(state => ({
      checkedListBookingLineId: _.uniq([...state.checkedListBookingLineId, ...ids])
    })),
  removeCheckListBookingLineIds: (ids: number[]) =>
    set(state => ({
      checkedListBookingLineId: _.difference(state.checkedListBookingLineId, ids)
    })),

  setIsActionOnGroup: (isActionOnGroup: boolean) => set(() => ({ isActionOnGroup }))
}));

export default useBookingStore;
