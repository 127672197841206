import './index.scss';
import SBProvider from '@sendbird/uikit-react/SendbirdProvider';
import '@sendbird/uikit-react/dist/index.css';
import CustomizedApp from './CustomizedApp';

function ChatPage() {
  const APP_ID = process.env.REACT_APP_SEND_BIRD_APP_ID;
  const USER_ID = process.env.REACT_APP_SEND_BIRD_USER_ID;
  const accessToken = process.env.REACT_APP_SEND_BIRD_ACCESS_TOKEN;
  if (!APP_ID) {
    return <p>Set APP_ID in const.js</p>;
  }
  return (
    <SBProvider
      appId={APP_ID}
      allowProfileEdit
      accessToken={accessToken}
      userId={USER_ID as string}
      nickname={'Kinship'}
    >
      <CustomizedApp />
    </SBProvider>
  );
}

export default ChatPage;
