import { matchPath } from 'react-router-dom';
import PAGES from 'routes/constants';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

export function refreshBookingList(location: any) {
  if (
    matchPath(PAGES.bookingList, location.pathname) ||
    matchPath(PAGES.receptionistGuestAllot, location.pathname) ||
    matchPath(PAGES.receptionistGuestCheckIn, location.pathname) ||
    matchPath(PAGES.receptionistGuestCheckout, location.pathname) ||
    matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname) ||
    matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname)
  ) {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
    });
  }
}

export function refreshRoomMap(location: any) {
  if (matchPath(PAGES.roomMap, location.pathname)) {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_STATUS]
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
    });
  }
}
