import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useCommonStore from 'stores/useCommon';
import { toLocalTime } from 'utils';
import { STATUS_STAYING_GUEST_OPTIONS } from 'constants/form';
import { StayingGuestType } from 'services/api/type/report.type';
import { ACTION_EXPORT_DATA } from 'constants/common';
import { jsonToXml } from 'utils';
import API from 'services/api/index';
import { COUNTRIES_CODE } from 'components/common/constant';
import Logo from 'assets/images/logo.png';

interface Props {
  fileName: string;
  filterOptions: any;
}
const MAP_GENDER: any = {
  male: 'M',
  female: 'F'
};

const ExportStayingGuest = ({ fileName, filterOptions }: Props) => {
  const { currentBranch } = useCommonStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_EXPORT_DATA.EXCEL:
        exportToCSV();
        break;
      case ACTION_EXPORT_DATA.XML:
        exportToXML();
        break;
      default:
        break;
    }
  };

  const getAllData = async () => {
    const result = await API.report.getStayingGuests(filterOptions);
    return result;
  };

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);
      const result = await getAllData();
      const dataToExport = (_.get(result, 'data', []) as StayingGuestType[]).map((item, index) => {
        return {
          stt: index + 1,
          name: item.name,
          dob: item.dob ? dayjs(item.dob).format('DD/MM/YYYY') : '',
          phone: item.phone || '',
          gender: MAP_GENDER[item.gender] || '',
          country: item?.country?.name || '',
          identification: item.identification || '',
          room_name: item.room_name,
          check_in: toLocalTime(item.check_in, 'DD/MM/YYYY HH:mm'),
          check_out: toLocalTime(item.check_out, 'DD/MM/YYYY HH:mm')
        };
      });

      // Sheet name
      const workbook = new ExcelJS.Workbook();
      const sheetName = `Police_${dayjs
        .utc(filterOptions.start_date)
        .local()
        .format('DD-MM-YYYY')}_${dayjs.utc(filterOptions.end_date).local().format('DD-MM-YYYY')}`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      // Address
      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 10 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      // Support
      sheet.mergeCells('A3:J3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 10 };
      sheet.getCell('A3').alignment = { horizontal: 'center' };

      // Support subtitle
      sheet.mergeCells('A4:J4');
      sheet.getCell('A4').value = 'HỖ TRỢ KHAI BÁO CÔNG AN / HẢI QUAN';
      sheet.getCell('A4').font = { bold: true, size: 10 };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      // Filter value
      sheet.mergeCells('B6:C6');
      sheet.mergeCells('E6:F6');
      sheet.mergeCells('H6:J6');

      const mapStatus = _.find(STATUS_STAYING_GUEST_OPTIONS, {
        value: filterOptions.status
      });

      sheet.getCell('A6').value = `Ngày`;
      sheet.getCell('A6').font = { bold: true };
      sheet.getCell('B6').value = dayjs.utc(filterOptions.start_date).local().format('DD-MM-YYYY');

      sheet.getCell('D6').value = `Đến`;
      sheet.getCell('D6').font = { bold: true };
      sheet.getCell('E6').value = dayjs.utc(filterOptions.end_date).local().format('DD-MM-YYYY');

      sheet.getCell('G6').value = `Tình trạng khách`;
      sheet.getCell('G6').font = { bold: true };
      sheet.getCell('H6').value = mapStatus?.label;

      sheet.getRow(6).eachCell(function (cell) {
        cell.border = {
          top: { style: 'medium', color: { argb: '#000' } },
          bottom: { style: 'medium', color: { argb: '#000' } }
        };
      });
      sheet.getRow(6).height = 25;

      // Mark column
      sheet.getRow(7).values = [
        '[1]',
        '[2]',
        '[3]',
        '[4]',
        '[5]',
        '[6]',
        '[7]',
        '[8]',
        '[9]',
        '[10]'
      ];

      // Header columns
      sheet.getRow(8).values = [
        'STT',
        'Tên đầy đủ',
        'Ngày sinh',
        'SĐT',
        'Giới tính',
        'Quốc tịch',
        'Giấy tờ',
        'Phòng',
        'Ngày vào',
        'Ngày đi'
      ];

      sheet.getCell('A7').alignment = { horizontal: 'center' };
      sheet.getCell('A8').alignment = { horizontal: 'center' };
      sheet.getRow(8).eachCell(function (cell) {
        cell.border = {
          bottom: { style: 'medium', color: { argb: '#000' } }
        };
        cell.font = { bold: true };
      });

      // Data
      sheet.columns = [
        {
          key: 'stt',
          width: 10,
          style: { alignment: { horizontal: 'center' } }
        },
        { key: 'name', width: 30 },
        { key: 'dob', width: 15 },
        { key: 'phone', width: 12 },
        { key: 'gender', width: 10 },
        { key: 'country', width: 20 },
        { key: 'identification', width: 20 },
        { key: 'room_name', width: 10 },
        { key: 'check_in', width: 15 },
        { key: 'check_out', width: 15 }
      ];

      dataToExport.forEach(item => {
        sheet.addRow(item);
      });

      const imageId2 = workbook.addImage({
        base64: Logo,
        extension: 'png'
      });
      sheet.addImage(imageId2, {
        tl: { col: 1, row: 1 },
        ext: { width: 71, height: 57 }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const exportToXML = async () => {
    const fileType = 'text/xml;charset=utf-8';
    const fileExtension = '.xml';

    try {
      setIsLoadingExport(true);
      const result = await getAllData();
      const dataToExport = (_.get(result, 'data', []) as StayingGuestType[]).map((item, index) => {
        return {
          so_thu_tu: index + 1,
          ho_ten: item.name,
          ngay_sinh: item.dob ? dayjs(item.dob).format('DD/MM/YYYY') : '',
          ngay_sinh_dung_den: 'D',
          gioi_tinh: MAP_GENDER[item.gender] || '',
          ma_quoc_tich: COUNTRIES_CODE[item?.country?.name] || '',
          so_ho_chieu: item.identification || '',
          so_phong: item.room_name,
          ngay_den: toLocalTime(item.check_in, 'DD/MM/YYYY'),
          ngay_di_du_kien: toLocalTime(item.check_out, 'DD/MM/YYYY'),
          ngay_tra_phong: toLocalTime(item.check_out, 'DD/MM/YYYY')
        };
      });

      const convertObject = {
        KHAI_BAO_TAM_TRU: dataToExport.map(data => ({
          THONG_TIN_KHACH: data
        }))
      };

      const xml = jsonToXml(convertObject, true);
      const buffer = Buffer.from(xml, 'utf-8');
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: ACTION_EXPORT_DATA.EXCEL,
        label: 'Excel'
      },
      {
        key: ACTION_EXPORT_DATA.XML,
        label: 'XML'
      }
    ];
  }, []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        Xuất dữ liệu
      </Button>
    </Dropdown>
  );
};

export default ExportStayingGuest;
