import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { useGetSources } from './useGetSources';
import { useGetCompanies } from './useGetCompanies';
import { PaginationType } from 'services/api/type/booking.type';

export const LIMIT_GROUP_LIST = 10;

export const useGetGroupBookings = (
  page: number = 1,
  search?: string,
  filterOptions?: any,
  isEnable: boolean = true
) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_GROUP_BOOKINGS, page, search, filterOptions],
    queryFn: () =>
      API.group.getGroupBookings({
        limit: LIMIT_GROUP_LIST,
        page: page || 1,
        search,
        filterOptions
      }),
    enabled: isEnable
  });

  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanies();
  const { data: sources, isLoading: isLoadingSources } = useGetSources();

  const dataRow = useMemo(() => {
    return (
      _.get(data, 'data', [])?.map(item => ({
        ...item,
        company_name: companies?.find(company => company.id === item.medium_id)?.name,
        source_name: sources?.find(source => source.id === item.source_id)?.name
      })) || []
    );
  }, [data, companies, sources]);

  return {
    data: dataRow,
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isLoading,
    isFetching: isFetching || isLoadingCompanies || isLoadingSources
  };
};

export const useGetGroupsBookingDetails = (filterOptions?: any, isEnable: boolean = true) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_GROUP_BOOKINGS, filterOptions],
    queryFn: () =>
      API.group.getGroupsBookingDetails({
        filterOptions
      }),
    enabled: isEnable
  });

  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanies();
  const { data: sources, isLoading: isLoadingSources } = useGetSources();

  const dataRow = useMemo(() => {
    return (
      _.get(data, 'data', [])?.map(item => ({
        ...item,
        company_name: companies?.find(company => company.id === item.medium_id)?.name,
        source_name: sources?.find(source => source.id === item.source_id)?.name
      })) || []
    );
  }, [data, companies, sources]);

  return {
    data: dataRow,
    isLoading,
    isFetching: isFetching || isLoadingCompanies || isLoadingSources
  };
};

export const useGetGroupBookingDetail = (groupId: number) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, groupId],
    queryFn: () => API.group.getGroupBookingDetail(groupId),
    staleTime: 1000 * 60,
    enabled: !!groupId && !isNaN(groupId)
  });

  return {
    data,
    isLoading,
    isFetching: isFetching
  };
};
