import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { PaginationType } from 'services/api/type/booking.type';
import { AllPaymentItem } from 'services/api/type/payment.type';

export const useGetPayments = (bookingLineId?: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLineId],
    queryFn: () =>
      API.payment
        .getListPayments(Number(bookingLineId))
        .then(data =>
          data.sort(
            (a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime()
          )
        ),
    enabled: !!bookingLineId
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};

export const useGetAllPayments = (
  filterOptions: any,
  page: number,
  limit: number = 10,
  enable: boolean = true
) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRANSACTIONS, page],
    queryFn: () =>
      API.report.getTransactions({
        ...filterOptions,
        page,
        limit
      }),
    enabled: enable
  });

  return {
    data: _.get(data, 'data', []) as AllPaymentItem[],
    status,
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isLoading,
    isFetching
  };
};

export const useGetTransactionsPayment = (
  filterOptions: any,
  page: number,
  limit: number = 10,
  enable: boolean = true
) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRANSACTIONS_PAYMENT_V2, page],
    queryFn: () =>
      API.report.getTransactionsPayment({
        ...filterOptions,
        page,
        limit
      }),
    enabled: enable
  });

  return {
    data: _.get(data, 'data', []) as AllPaymentItem[],
    status,
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isLoading,
    isFetching
  };
};
