import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Button, Card, DatePicker, Select, Form, FormProps, Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import ExportTransactions from 'components/common/export-transactions';

import QUERY_KEYS from 'services/api/queryKeys';
import { PAYMENT_METHOD_FILTER_OPTIONS, PAYMENT_STATE_OPTIONS } from 'constants/form';

import { generateExportFileName, toUtcTime } from 'utils';
import 'styles/transactions.scss';
import { TransactionV1 } from 'components/modal/transactions-payment-report/transaction-v1';

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function Transactions() {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss')
  });

  const [page, setPage] = useState<number>(1);

  // state of those components use for Export data
  const [selectedPaymentState, setSelectedPaymentState] = useState<string | undefined>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>('');

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_TRANSACTIONS, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  return (
    <div className="pms-transactions">
      <Card title="Danh sách giao dịch V1" style={{ width: '100%' }}>
        <Form
          form={form}
          name="staying-guests-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate],
            state: '',
            payment_method: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-transactions__filter-wrapper">
            <div className="pms-transactions__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  showTime
                  className="w-full"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format="YYYY-MM-DD HH:mm"
                  // onChange={handleRangeDateChange}
                />
              </Form.Item>

              <Form.Item name="booking_id">
                <Input placeholder="Mã đặt phòng" />
              </Form.Item>
              <Form.Item name="state">
                <Select
                  options={PAYMENT_STATE_OPTIONS}
                  labelRender={option => <span>Trạng thái: {option.label}</span>}
                />
              </Form.Item>

              <Form.Item name="payment_method">
                <Select
                  options={PAYMENT_METHOD_FILTER_OPTIONS}
                  labelRender={option => <span>Phương thức thanh toán: {option.label}</span>}
                  placeholder="Phương thức thanh toán:"
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
            <ExportTransactions
              fileName={generateExportFileName('transactions-v2')}
              selectedPaymentState={selectedPaymentState}
              selectedPaymentMethod={selectedPaymentMethod}
              englishType={false}
              filterOptions={filterOptions}
            />
          </div>
        </Form>

        <div className="pms-transactions__table">
          <TransactionV1
            filterOptions={filterOptions}
            setSelectedPaymentState={setSelectedPaymentState}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        </div>
      </Card>
    </div>
  );
}

export default Transactions;
