import ReactGA from 'react-ga4';

export const initGA = () => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    return;
  }
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
};

export const logPageView = (page: string) => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    return;
  }
  ReactGA.send({ hitType: 'pageview', page, title: page });
};

export const logEvent = ({
  category,
  action,
  label,
  value
}: {
  category: string;
  action: string;
  label?: string;
  value?: number;
}) => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    return;
  }
  ReactGA.event({
    category,
    action,
    label, // optional
    value // optional, must be a number
  });
};
