import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Modal, notification, Form, FormProps, Row, Col, Select } from 'antd';

import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import API from 'services/api';

import { BookingPrice, ParamsChangePrices } from 'services/api/type/booking.type';
import QUERY_KEYS from 'services/api/queryKeys';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { CURRENCY, CURRENCY_OPTIONS, PAYMENT_METHOD, PAYMENT_METHOD_OPTIONS } from 'constants/form';

function PaymentModal() {
  const [form] = Form.useForm();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { checkedListBookingLineId } = useBookingStore();
  const { isOpenPayment, setIsOpenPayment } = useModal();

  const { mutateAsync: mutateChangePrice, isPending } = useMutation({
    mutationFn: ({
      bookingLineId,
      params
    }: {
      bookingLineId: number;
      params: ParamsChangePrices;
    }) => API.booking.changePrices(bookingLineId, params)
  });

  useEffect(() => {
    if (!isOpenPayment) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        payment_method: PAYMENT_METHOD.CASH,
        currency: CURRENCY.VND
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenPayment]);

  const handleCloseModal = () => {
    setIsOpenPayment(false);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const params: any = {
        prices: (values.booking_prices as BookingPrice[]).map(item => ({
          id: item.id,
          price: item.price
        }))
      };
      if (values.pricelist_id) {
        params.pricelist_id = values.pricelist_id;
      }

      await mutateChangePrice({
        bookingLineId: Number(checkedListBookingLineId),
        params
      });

      notification.success({
        message: 'Thanh toán thành công'
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, checkedListBookingLineId]
      });

      refreshBookingList(location);
      refreshRoomMap(location);

      setIsOpenPayment(false);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  // const selectedBookingLines = useMemo(() => {
  //   const result = []
  //   bookingLineDetail?.group?.booking_lines.forEach(item => {
  //     if (checkedListBookingLineId.includes(item.id)) {
  //       result.push()
  //     }
  //   })
  //   return result
  // }, [bookingLineDetail])

  return (
    <Modal
      title="Thanh toán"
      centered
      width={500}
      open={isOpenPayment}
      onCancel={handleCloseModal}
      okText="Thanh toán"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      closeIcon={null}
      className={classNames('modal-payment')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="payment-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          payment_method: PAYMENT_METHOD.CASH,
          currency: CURRENCY.VND
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item name="payment_method">
              <Select options={PAYMENT_METHOD_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="currency">
              <Select options={CURRENCY_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PaymentModal;
