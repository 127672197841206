import CustomerTableForConsolidationRevenue from './CustomerTableForConsolidateRevenue';
import { useGetConsolidateRevenue } from 'hooks/uesGetConsolidateRevenue';
import { transformDataToTable } from './utils';
import { useMemo } from 'react';
import { GetConsolidateRevenueData, TransformedRevenueData } from 'services/api/type/report.type';

interface NationalityConsolidateRevenueProps {
  type_of_response: string;
  filterOptions: any;
  isEnable: boolean;
  setDataSource: (data: any[]) => void;
}

export default function NationalityTab({
  type_of_response,
  filterOptions,
  isEnable,
  setDataSource
}: NationalityConsolidateRevenueProps) {
  const { data: consolidateRevenue, isLoading } = useGetConsolidateRevenue(
    {
      ...filterOptions,
      type_of_response: type_of_response
    },
    isEnable
  );

  const dataSource = useMemo(() => {
    if (
      consolidateRevenue &&
      typeof consolidateRevenue === 'object' &&
      !Array.isArray(consolidateRevenue)
    ) {
      return transformDataToTable(
        consolidateRevenue as unknown as GetConsolidateRevenueData
      ) as TransformedRevenueData[];
    }
    return [];
  }, [consolidateRevenue]);

  setDataSource(dataSource);

  return (
    <CustomerTableForConsolidationRevenue
      loading={isLoading}
      dataSource={dataSource}
      title="Khu vực"
    />
  );
}
