import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import _ from 'lodash';
import { PaginationType } from 'services/api/type/booking.type';
import { PersonalInfoType } from 'services/api/type/room.type';

interface FilterCustomers {
  id?: number;
  search?: string;
  limit?: number;
  pageSize?: number;
}

export const useGetCustomers = (
  filterOptions: FilterCustomers,
  companyType = 'person',
  enabled = true
) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMERS, companyType, filterOptions],
    queryFn: () =>
      API.room.getCustomers({
        ...filterOptions,
        company_type: companyType
      }),
    enabled
  });

  return {
    data: _.get(data, 'data', []) as PersonalInfoType[],
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    status,
    isLoading,
    isFetching
  };
};
