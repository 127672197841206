import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Badge, Button, Col, DatePicker, Input, Radio, Row, Skeleton } from 'antd';
import { SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery } from 'react-responsive';

import RoomItem from 'components/room-map/room-item';
import TimelineBooking from 'components/room-map/timeline-booking';

import { useGetRoomStatus } from 'hooks/useGetRoomStatus';
import { useGetBookingTimeline } from 'hooks/useGetBookingTimeline';

import {
  DISPLAY_ROOM_VALUE,
  FILTER_DISPLAY_ROOM_MAP,
  FILTER_DISPLAY_ROOM_MAP_VALUE,
  FILTER_STATUS_ROOM_MAP,
  FILTER_STATUS_ROOM_MAP_VALUE,
  FILTER_TIMELINE_UNIT,
  GROUP_DISPLAY_OPTIONS,
  GROUP_DISPLAY_TYPE,
  LEGEND_STATUS_TIMELINE,
  MAP_FILTER_STATUS_LABEL,
  TIMELINE_UNIT
} from 'constants/common';

import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';
import { RoomDetailStatus } from 'services/api/type/room.type';
import { ReactComponent as DetailView } from 'assets/images/list.svg';
import { ReactComponent as SimpleView } from 'assets/images/simple.svg';
import { ReactComponent as TimelineView } from 'assets/images/timeline.svg';
import { ReactComponent as IconDirty } from 'assets/images/dirty.svg';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import 'styles/room-map.scss';
import { useOpenBookingDetail } from 'hooks/useOpenBookingDetail';

const STEP: {
  [key: string]: number;
} = {
  [TIMELINE_UNIT.DAY]: 1,
  [TIMELINE_UNIT.WEEK]: 6,
  [TIMELINE_UNIT.MONTH]: 29
};

const STEP_MOBILE: {
  [key: string]: number;
} = {
  [TIMELINE_UNIT.DAY]: 1,
  [TIMELINE_UNIT.WEEK]: 3,
  [TIMELINE_UNIT.MONTH]: 6
};

const today = dayjs();

function RoomMap() {
  const timelineRef = useRef<any>();
  const { openBookingDetail } = useOpenBookingDetail();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [status, setStatus] = useState(FILTER_STATUS_ROOM_MAP_VALUE.ALL);
  const [filterDisplay, setFilterDisplay] = useState(FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR);
  const [displayRoom, setDisplayRoom] = useState(DISPLAY_ROOM_VALUE.SIMPLE);
  const [search, setSearch] = useState('');
  const [searchHook, setSearchHook] = useState('');
  const [timelineUnit, setTimelineUnit] = useState(TIMELINE_UNIT.WEEK);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(today);
  const [groupTypeDisplay, setGroupTypeDisplay] = useState(GROUP_DISPLAY_TYPE.ROOM);

  const FINAL_STEP = isIpadMobile ? STEP_MOBILE : STEP;

  const { data, isLoading } = useGetRoomStatus(getStartOfToday(), getEndOfToday());

  const sortedRooms: RoomDetailStatus[] | undefined = data?.sort((a, b) => {
    const priceDiff = a.room_price - b.room_price;
    const typeNameDiff = a.room_type_name?.localeCompare(b.room_type_name);
    const roomNoSorted = Number(a.attributes.room_no) - Number(b.attributes.room_no);

    return priceDiff || typeNameDiff || roomNoSorted;
  });

  const {
    data: dataTimeline,
    dataLocks,
    dataNotAssignRooms,
    isLoading: isLoadingTimeline,
    isFetchedAfterMount,
    isFetching
  } = useGetBookingTimeline(
    selectedDate.format('YYYY-MM-DD'),
    dayjs(selectedDate).add(FINAL_STEP[timelineUnit], 'day').format('YYYY-MM-DD'),
    displayRoom === DISPLAY_ROOM_VALUE.TIMELINE
  );

  const isTight =
    filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR &&
    displayRoom === DISPLAY_ROOM_VALUE.SIMPLE;

  // calculate quantity of each status and is_clean useMemo
  const quantity = useMemo(() => {
    const quantity: any = {
      [FILTER_STATUS_ROOM_MAP_VALUE.ALL]: data?.length || 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE]: 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.BOOKED]: 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW]: 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN]: 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT]: 0,
      [FILTER_STATUS_ROOM_MAP_VALUE.DIRTY]: 0
    };
    data?.forEach(item => {
      quantity[item.room_status] = (quantity[item.room_status] || 0) + 1;
      if (!item.is_clean) {
        quantity[FILTER_STATUS_ROOM_MAP_VALUE.DIRTY] += 1;
      }
    });
    return quantity;
  }, [data]);

  // use callback to get room by status or is clean
  const getRoomsByStatus = useCallback(
    (status: FILTER_STATUS_ROOM_MAP_VALUE) => {
      if (!data) {
        return [];
      }
      if (status === FILTER_STATUS_ROOM_MAP_VALUE.ALL) {
        const sortedData = data
          .sort((a, b) => a.attributes?.room_no?.localeCompare(b.attributes?.room_no))
          .filter(item =>
            item.attributes?.room_no?.includes(searchHook.toLocaleLowerCase().trim())
          );
        return sortedData;
      }
      return data
        .filter(item => {
          return (
            (item.room_status === status ||
              (!item.is_clean && status === FILTER_STATUS_ROOM_MAP_VALUE.DIRTY)) &&
            item.attributes?.room_no?.includes(searchHook.toLocaleLowerCase().trim())
          );
        })
        .sort((a, b) => a.attributes?.room_no?.localeCompare(b.attributes?.room_no));
    },
    [data, searchHook]
  );

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };

  const handleChangeFilterDisplay = (e: any) => {
    setFilterDisplay(e.target.value);
  };

  const handleChangeTimelineUnit = (e: any) => {
    setTimelineUnit(e.target.value);
    applyNextDay(selectedDate, e.target.value);
  };

  const handleChangeGroupTypeDisplay = (e: any) => {
    setGroupTypeDisplay(e.target.value);
  };

  const handleChangeDisplayRoom = (e: any) => {
    setDisplayRoom(e.target.value);
  };

  const handleViewDetail = (bookingLineId: number) => {
    openBookingDetail(bookingLineId);
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
    handleChangeSearchHook(e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeSearchHook = useCallback(
    _.debounce((e: any) => {
      setSearchHook(e.target.value);
    }, 500),
    []
  );

  const handleMoveToToday = () => {
    timelineRef.current.moveToday();
    setSelectedDate(today);
  };

  const handlePreviousDay = () => {
    const previousDay = dayjs(selectedDate).subtract(FINAL_STEP[timelineUnit], 'day');
    if (timelineUnit === TIMELINE_UNIT.DAY) {
      timelineRef.current.changeVisibleTime(previousDay, previousDay);
    } else {
      timelineRef.current.changeVisibleTime(previousDay, selectedDate);
    }
    setSelectedDate(previousDay);
  };

  const handleNextDay = () => {
    const nextDay = dayjs(selectedDate).add(FINAL_STEP[timelineUnit], 'day');
    applyNextDay(nextDay, timelineUnit);
    setSelectedDate(nextDay);
  };

  const applyNextDay = (nextDay: Dayjs, timelineUnit: TIMELINE_UNIT) => {
    if (timelineUnit === TIMELINE_UNIT.DAY) {
      timelineRef.current.changeVisibleTime(nextDay, nextDay, timelineUnit);
    } else {
      timelineRef.current.changeVisibleTime(
        nextDay,
        nextDay.add(FINAL_STEP[timelineUnit], 'day'),
        timelineUnit
      );
    }
  };

  const handleChangeDate = (date: Dayjs) => {
    applyNextDay(date, timelineUnit);
    setSelectedDate(date);
  };

  const groupDataByBookingStatus = () => {
    const groupedData = _.groupBy(getRoomsByStatus(status), item => item.room_status);
    const newOrderedGroup: _.Dictionary<RoomDetailStatus[]> = {};
    [
      FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT,
      FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN,
      FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW,
      FILTER_STATUS_ROOM_MAP_VALUE.BOOKED,
      FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE,
      FILTER_STATUS_ROOM_MAP_VALUE.LOCKED
    ].forEach(key => {
      if (key in groupedData) {
        _.set(newOrderedGroup, key, groupedData[key]);
      }
    });

    return newOrderedGroup;
  };

  const groupDataByCategories = () => {
    const groupedData = _.groupBy(getRoomsByStatus(status), item => item.room_type_name);
    return groupedData;
  };

  const groupDataByFloor = () => {
    const groupedData = _.groupBy(getRoomsByStatus(status), item => {
      if (item.attributes?.room_no.length === 3) {
        return item.attributes?.room_no[0];
      }
      if (item.attributes?.room_no.length === 4) {
        return item.attributes?.room_no.slice(0, 2);
      }
      // Exception case -> error
      return item.attributes?.room_no;
    });

    if (isTight) {
      Object.keys(groupedData).forEach(key => {
        const arr = groupedData[key];
        arr.unshift({
          label: (
            <p className="m-0">
              Tầng <br /> {key}
            </p>
          ),
          isLabel: true
        } as any);
      });
    }
    return groupedData;
  };

  const getLabelByFloor = (floor: string) => {
    return Number(floor);
  };

  const renderByFilterDisplay = () => {
    if (
      [
        FILTER_DISPLAY_ROOM_MAP_VALUE.BOOKING,
        FILTER_DISPLAY_ROOM_MAP_VALUE.CATEGORIES,
        FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR
      ].includes(filterDisplay)
    ) {
      let groupedData: any = {};
      if (filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.BOOKING) {
        groupedData = groupDataByBookingStatus();
      } else if (filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.CATEGORIES) {
        groupedData = groupDataByCategories();
      } else if (filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR) {
        groupedData = groupDataByFloor();
      }

      const arrayKeys = Object.keys(groupedData);

      // Render layout 5 column for filter type floor and display room simple
      if (isTight) {
        return (
          <Row gutter={[20, 24]} className="w-full">
            {arrayKeys.map((key, index) => (
              <Col xs={24} md={12} lg={8} xxl={6} key={index}>
                <div className="pms-room-map__rooms-section">
                  <div className="pms-room-map__rooms-section__wrapper box">
                    {groupedData[key].map((item: RoomDetailStatus, itemIndex: number) => {
                      // Render floor label
                      if ((item as any).isLabel) {
                        return (
                          <div
                            className={classNames('pms-room-item pms-room-item--floor')}
                            key={itemIndex}
                          >
                            {(item as any).label}
                          </div>
                        );
                      }

                      return (
                        <RoomItem
                          displayRoom={displayRoom}
                          id={item.attributes.room_no}
                          roomId={item.room_id}
                          bookingLineId={item.booking_line_id}
                          key={itemIndex}
                          type={item.room_type_name}
                          status={item.room_status}
                          is_dirty={!item.is_clean}
                          is_group={item.is_group}
                          room_lock={item?.room_lock}
                          bookingId={item?.booking_line?.booking_id}
                          onViewDetail={handleViewDetail}
                          info={
                            item.partner_name
                              ? {
                                  customerName: item.partner_name,
                                  fit: item?.booking_line?.medium_name || '',
                                  rangeDate: `${toLocalTime(
                                    item.check_in,
                                    'DD/MM HH:mm'
                                  )} - ${toLocalTime(item.check_out, 'DD/MM HH:mm')}`,
                                  price: item.subtotal_price,
                                  checkInTime: item.check_in,
                                  checkOutTime: item.check_out,
                                  note: item.note,
                                  adult: item?.booking_line?.adult,
                                  child: item?.booking_line?.child
                                }
                              : undefined
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        );
      }

      return arrayKeys.map((key, index) => (
        <div className="pms-room-map__rooms-section" key={index}>
          <h4 className="title">
            {filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR ? 'Tầng' : ''}{' '}
            {filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.BOOKING
              ? MAP_FILTER_STATUS_LABEL[key]
              : filterDisplay === FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR
                ? getLabelByFloor(key)
                : key}{' '}
            ({groupedData[key].length})
          </h4>
          <div className="pms-room-map__rooms-section__wrapper">
            {groupedData[key].map((item: RoomDetailStatus, itemIndex: number) => (
              <RoomItem
                displayRoom={displayRoom}
                id={item.attributes.room_no}
                roomId={item.room_id}
                bookingLineId={item.booking_line_id}
                key={itemIndex}
                type={item.room_type_name}
                status={item.room_status}
                is_dirty={!item.is_clean}
                is_group={item.is_group}
                room_lock={item?.room_lock}
                bookingId={item?.booking_line?.booking_id}
                onViewDetail={handleViewDetail}
                info={
                  item.partner_name
                    ? {
                        customerName: item.partner_name,
                        fit: item?.booking_line?.medium_name || '',
                        rangeDate: `${toLocalTime(
                          item.check_in,
                          'DD/MM HH:mm'
                        )} - ${toLocalTime(item.check_out, 'DD/MM HH:mm')}`,
                        price: item.subtotal_price,
                        checkInTime: item.check_in,
                        checkOutTime: item.check_out,
                        note: item.note,
                        adult: item?.booking_line?.adult,
                        child: item?.booking_line?.child
                      }
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      ));
    }

    return getRoomsByStatus(status).map((item, index) => (
      <RoomItem
        id={item.attributes.room_no}
        roomId={item.room_id}
        bookingLineId={item.booking_line_id}
        key={index}
        type={item.room_type_name}
        status={item.room_status}
        is_dirty={!item.is_clean}
        is_group={item.is_group}
        displayRoom={displayRoom}
        room_lock={item?.room_lock}
        bookingId={item?.booking_line?.booking_id}
        onViewDetail={handleViewDetail}
        info={
          item.partner_name
            ? {
                customerName: item.partner_name,
                fit: item?.booking_line?.medium_name || '',
                rangeDate: `${toLocalTime(
                  item.check_in,
                  'DD/MM HH:mm'
                )} - ${toLocalTime(item.check_out, 'DD/MM HH:mm')}`,
                price: item.subtotal_price,
                checkInTime: item.check_in,
                checkOutTime: item.check_out,
                note: item.note,
                adult: item?.booking_line?.adult,
                child: item?.booking_line?.child
              }
            : undefined
        }
      />
    ));
  };

  return (
    <div className="pms-room-map">
      <div className="pms-room-map__filter">
        <div className="pms-room-map__filter-status">
          {displayRoom !== DISPLAY_ROOM_VALUE.TIMELINE ? (
            <Radio.Group buttonStyle="solid">
              {FILTER_STATUS_ROOM_MAP.map((item, index) => (
                <Radio.Button
                  key={index}
                  value={item.value}
                  style={{
                    color: item.color,
                    backgroundColor: item.value === status ? item.bgColor : ''
                  }}
                  checked={item.value === status}
                  onChange={handleChangeStatus}
                >
                  {item.label} ({quantity[item.value] || 0})
                </Radio.Button>
              ))}
            </Radio.Group>
          ) : (
            <div className="legend-wrapper">
              {LEGEND_STATUS_TIMELINE.map((item, index) => (
                <Button
                  type="text"
                  key={index}
                  style={{
                    color: item.color
                  }}
                  className="legend-status"
                >
                  {item.value === FILTER_STATUS_ROOM_MAP_VALUE.DIRTY && (
                    <IconDirty className="dirty" />
                  )}
                  {item.value === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED && <IconFix />}
                  {item.label}
                </Button>
              ))}
            </div>
          )}

          {displayRoom !== DISPLAY_ROOM_VALUE.TIMELINE ? (
            <Radio.Group buttonStyle="solid" className="filter-display-group" value={filterDisplay}>
              {FILTER_DISPLAY_ROOM_MAP.map((item, index) => (
                <Radio.Button
                  className="filter-display-item"
                  style={{ width: '100px' }}
                  key={index}
                  value={item.value}
                  checked={item.value === filterDisplay}
                  onChange={handleChangeFilterDisplay}
                >
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          ) : (
            <div className="flex items-center flex-wrap" style={{ gap: 8, marginTop: 4 }}>
              {/* Date picker */}
              <div className="date-picker-wrapper">
                <Button
                  type="link"
                  onClick={handlePreviousDay}
                  className="flex items-center justify-center"
                  style={{ width: 16, height: 16, padding: 0, color: 'rgba(0, 0, 0, 0.88)' }}
                >
                  <LeftOutlined />
                </Button>
                <DatePicker size="large" value={selectedDate} onChange={handleChangeDate} />
                <Button
                  type="link"
                  onClick={handleNextDay}
                  className="flex items-center justify-center"
                  style={{ width: 16, height: 16, padding: 0, color: 'rgba(0, 0, 0, 0.88)' }}
                >
                  <RightOutlined />
                </Button>
              </div>

              <Button
                className="ant-btn-secondary"
                size="large"
                onClick={handleMoveToToday}
                style={{ borderRadius: 8 }}
                disabled={selectedDate.isSame(today)}
              >
                Hôm nay
              </Button>

              {/* Timeline unit */}
              <Radio.Group
                buttonStyle="solid"
                className="filter-display-group"
                value={timelineUnit}
              >
                {FILTER_TIMELINE_UNIT.map((item, index) => {
                  return (
                    <Radio.Button
                      className="filter-display-item"
                      style={{ width: '100px' }}
                      key={index}
                      value={item.value}
                      checked={item.value === timelineUnit}
                      onChange={handleChangeTimelineUnit}
                    >
                      {item.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>

              {/* Group type */}
              <Radio.Group
                buttonStyle="solid"
                className="filter-display-group"
                value={groupTypeDisplay}
              >
                {GROUP_DISPLAY_OPTIONS.map((item, index) => {
                  return (
                    <Radio.Button
                      className="filter-display-item"
                      style={{ width: '100px' }}
                      key={index}
                      value={item.value}
                      checked={item.value === groupTypeDisplay}
                      onChange={handleChangeGroupTypeDisplay}
                    >
                      {item.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
          )}
        </div>

        <div className="pms-room-map__filter-search">
          {displayRoom !== DISPLAY_ROOM_VALUE.TIMELINE && (
            <Input
              placeholder="Tìm phòng"
              size="large"
              value={search}
              onChange={handleChangeSearch}
              prefix={<SearchOutlined style={{ color: '#b4b4b4' }} />}
            />
          )}

          <Radio.Group
            buttonStyle="solid"
            className="display-room-group"
            defaultValue={displayRoom}
          >
            <Radio.Button
              className="display-room-item"
              style={{ width: '100px' }}
              value={DISPLAY_ROOM_VALUE.TIMELINE}
              checked={displayRoom === DISPLAY_ROOM_VALUE.TIMELINE}
              onChange={handleChangeDisplayRoom}
            >
              <div className="flex">
                <Badge count="New" offset={[10, 2]}>
                  <TimelineView className="timeline" />
                </Badge>
              </div>
            </Radio.Button>

            <Radio.Button
              className="display-room-item"
              style={{ width: '100px' }}
              value={DISPLAY_ROOM_VALUE.DETAIL}
              checked={displayRoom === DISPLAY_ROOM_VALUE.DETAIL}
              onChange={handleChangeDisplayRoom}
            >
              <div className="flex">
                <DetailView />
              </div>
            </Radio.Button>

            <Radio.Button
              className="display-room-item"
              style={{ width: '100px' }}
              value={DISPLAY_ROOM_VALUE.SIMPLE}
              checked={displayRoom === DISPLAY_ROOM_VALUE.SIMPLE}
              onChange={handleChangeDisplayRoom}
            >
              <div className="flex">
                <SimpleView />
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div
        className={classNames('pms-room-map__content', {
          'pms-room-map__content--tight': isTight
        })}
      >
        <div className="pms-room-map__rooms-wrapper">
          {/*<p className="title">Trống</p>*/}
          <div
            className={classNames('pms-room-map__rooms', {
              'pms-room-map__rooms--column': [
                FILTER_DISPLAY_ROOM_MAP_VALUE.BOOKING,
                FILTER_DISPLAY_ROOM_MAP_VALUE.CATEGORIES,
                FILTER_DISPLAY_ROOM_MAP_VALUE.FLOOR
              ].includes(filterDisplay),
              'pms-room-map__rooms--tight': isTight
            })}
          >
            {isLoading &&
              [...Array(5)].map((item, index) => (
                <div
                  className="pms-room-map__rooms-section"
                  style={{ justifyContent: 'space-between' }}
                  key={index}
                >
                  <div
                    className={classNames('pms-room-map__rooms-section__wrapper', {
                      'pms-room-map__rooms-section__wrapper--tight-loading': isTight
                    })}
                  >
                    {[...Array(4)].map((item, itemIndex) => (
                      <Skeleton.Button key={itemIndex} shape="square" size="large" active />
                    ))}
                  </div>
                </div>
              ))}

            {displayRoom === DISPLAY_ROOM_VALUE.TIMELINE ? (
              <TimelineBooking
                ref={timelineRef}
                bookings={dataTimeline}
                roomsNotAssign={dataNotAssignRooms}
                sortedRooms={sortedRooms}
                locks={dataLocks}
                timelineUnit={timelineUnit}
                groupTypeDisplay={groupTypeDisplay}
                isLoadingTimeline={isLoadingTimeline}
                isFetchedAfterMount={isFetchedAfterMount}
                isFetching={!isFetching}
              />
            ) : (
              renderByFilterDisplay()
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoomMap;
