import { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import useCommonStore from 'stores/useCommon';
import { formatCurrency } from 'utils';

interface Props {
  fileName: string;
  fromDate: string;
  toDate?: string;
  dataToExport: any;
  typeOfResponse?: string;
}

interface DataItem {
  roomType: string;
  room_price: number;
  service_cost: number;
  discount?: number;
  revenue: number;
  total_night: number;
  occupied_percent?: number;
  occupied_percent_total: number;
  average_price: number;
}

export const ExportConsolidateRevenue = ({
  fileName,
  dataToExport,
  fromDate,
  toDate,
  typeOfResponse
}: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { currentBranch } = useCommonStore();

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Transactions`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 10 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      // Support
      sheet.mergeCells('A3:J3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 10 };
      sheet.getCell('A3').alignment = { horizontal: 'center' };

      sheet.mergeCells('A4:J4');
      sheet.getCell('A4').value = 'BÁO CÁO DOANH THU TỔNG HỢP';
      sheet.getCell('A4').font = { size: 11, bold: true };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      sheet.getCell('A6').value = `Ngày`;
      sheet.getCell('A6').font = { bold: true };
      sheet.getCell('B6').value = fromDate;
      sheet.getCell('C6').value = `Đến`;
      sheet.getCell('C6').font = { bold: true };
      sheet.getCell('D6').value = toDate;

      const styleHeaderRow = (sheet: any, headers: string[]) => {
        sheet.getRow(8).values = headers;
        sheet.getRow(8).eachCell((cell: any) => {
          cell.border = {
            top: { style: 'medium', color: { argb: 'FF000000' } },
            bottom: { style: 'medium', color: { argb: 'FF000000' } },
            left: { style: 'medium', color: { argb: 'FF000000' } },
            right: { style: 'medium', color: { argb: 'FF000000' } }
          };
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
        });
      };

      const populateDataRows = (sheet: any, data: DataItem[], hasOCCColumn: boolean) => {
        data.forEach((item, index) => {
          const row = sheet.addRow({
            orderNumber: index + 1,
            roomType: item.roomType,
            room_price: `${formatCurrency(item.room_price)}`,
            service_cost: `${formatCurrency(item.service_cost)}`,
            discount: `${formatCurrency(item.discount || 0)}`,
            revenue: `${formatCurrency(item.revenue)}`,
            total_night: item.total_night,
            occupied_percent: hasOCCColumn ? `${item.occupied_percent} %` : undefined,
            occupied_percent_total: `${item.occupied_percent_total}%`,
            average_price: `${formatCurrency(item.average_price)}`
          });

          row.eachCell({ includeEmpty: true }, (cell: any) => {
            cell.border = {
              top: { style: 'medium' },
              left: { style: 'medium' },
              bottom: { style: 'medium' },
              right: { style: 'medium' }
            };
            if (cell.col == 2) {
              cell.alignment = { horizontal: 'left' };
            } else {
              cell.alignment = { horizontal: 'center' };
            }
          });
        });

        const lastRow = sheet.lastRow;
        if (lastRow) {
          lastRow.eachCell({ includeEmpty: true }, (cell: any) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
          });
        }
      };

      const headersWithOCC =
        typeOfResponse === 'source'
          ? [
              'Số thứ tự',
              'Nguồn',
              'Tiền phòng (VND)',
              'Dịch vụ (VND)',
              'Giảm giá',
              'Tổng doanh thu (VND)',
              'Tổng số đêm bán',
              'OCC (%)',
              'OCC (%)/ Total room inventory',
              'Giá phòng trung bình (VND)'
            ]
          : typeOfResponse === 'nationality'
            ? [
                'Số thứ tự',
                'Khu vực',
                'Tiền phòng (VND)',
                'Dịch vụ (VND)',
                'Giảm giá',
                'Tổng doanh thu (VND)',
                'Tổng số đêm bán',
                'OCC (%)/ Total room inventory',
                'Giá phòng trung bình (VND)'
              ]
            : [
                'Số thứ tự',
                'Loại phòng',
                'Tiền phòng (VND)',
                'Dịch vụ (VND)',
                'Giảm giá',
                'Tổng doanh thu (VND)',
                'Tổng số đêm bán',
                'OCC (%)',
                'OCC (%)/ Total room inventory',
                'Giá phòng trung bình (VND)'
              ];

      const headersWithoutOCC =
        typeOfResponse === 'source'
          ? [
              'Số thứ tự',
              'Nguồn',
              'Tiền phòng (VND)',
              'Dịch vụ (VND)',
              'Giảm giá',
              'Tổng doanh thu (VND)',
              'Tổng số đêm bán',
              'OCC (%)/ Total room inventory',
              'Giá phòng trung bình (VND)'
            ]
          : typeOfResponse === 'nationality'
            ? [
                'Số thứ tự',
                'Khu vực',
                'Tiền phòng (VND)',
                'Dịch vụ (VND)',
                'Giảm giá',
                'Tổng doanh thu (VND)',
                'Tổng số đêm bán',
                'OCC (%)/ Total room inventory',
                'Giá phòng trung bình (VND)'
              ]
            : [
                'Số thứ tự',
                'Loại phòng',
                'Tiền phòng (VND)',
                'Dịch vụ (VND)',
                'Giảm giá',
                'Tổng doanh thu (VND)',
                'Tổng số đêm bán',
                'OCC (%)/ Total room inventory',
                'Giá phòng trung bình (VND)'
              ];

      if (typeOfResponse === 'room_type') {
        styleHeaderRow(sheet, headersWithOCC);
        sheet.columns = [
          { key: 'orderNumber', width: 10 },
          { key: 'roomType', width: 25 },
          { key: 'room_price', width: 25 },
          { key: 'service_cost', width: 20 },
          { key: 'discount', width: 15 },
          { key: 'revenue', width: 25 },
          { key: 'total_night', width: 20 },
          { key: 'occupied_percent', width: 15 },
          { key: 'occupied_percent_total', width: 30 },
          { key: 'average_price', width: 30 }
        ];
        populateDataRows(sheet, dataToExport, true);
      } else {
        styleHeaderRow(sheet, headersWithoutOCC);
        sheet.columns = [
          { key: 'orderNumber', width: 10 },
          { key: 'roomType', width: 25 },
          { key: 'room_price', width: 25 },
          { key: 'service_cost', width: 20 },
          { key: 'discount', width: 15 },
          { key: 'revenue', width: 25 },
          { key: 'total_night', width: 20 },
          { key: 'occupied_percent_total', width: 30 },
          { key: 'average_price', width: 30 }
        ];
        populateDataRows(sheet, dataToExport, false);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        Xuất dữ liệu
      </Button>
    </Dropdown>
  );
};
