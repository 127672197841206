import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getBookingDetail } from 'services/api/module/booking.api';
import QUERY_KEYS from 'services/api/queryKeys';
import useBookingStore from 'stores/useBooking';

export const useGetBookingDetail = (id?: number) => {
  const { data, status, isLoading, isPending, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, id],
    queryFn: () => getBookingDetail(Number(id)),
    enabled: !!id
  });

  const { setIsLoadingBookingDetail, setBookingLineDetail } = useBookingStore();

  useEffect(() => {
    setIsLoadingBookingDetail(isPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    setBookingLineDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
