import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, Form, FormProps, notification, DatePicker, Input, Button, Select } from 'antd';
import { useMutation } from '@tanstack/react-query';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetRooms } from 'hooks/useGetRooms';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { queryClient } from 'index';
import { ParamsFixRoom } from 'services/api/type/room.type';
import { fixRoom } from 'services/api/module/room.api';
import { MESSAGE_CODE } from 'constants/validate';
import QUERY_KEYS from 'services/api/queryKeys';
import { toUtcTime } from 'utils';

function FixRoomModal() {
  const [form] = Form.useForm();
  const isActive = useRef(true);
  const roomTypeForm = Form.useWatch('roomType', form);

  const { isOpenFixRoom, isCreateFixRoom, setIsOpenFixRoom } = useModalStore();
  const { roomId, roomNo, roomLock, resetRoom } = useBookingStore();
  const [confirmStopFixRoom, setConfirmStopFixRoom] = useState(false);
  const { allRooms, isLoading } = useGetRooms();

  const { mutateAsync: mutateFixRoom, isPending } = useMutation({
    mutationFn: (params: ParamsFixRoom) => fixRoom(params)
  });
  const { mutateAsync: mutateStopFixRoom, isPending: isPendingStop } = useMutation({
    mutationFn: (params: ParamsFixRoom) => fixRoom(params)
  });

  useEffect(() => {
    if (isOpenFixRoom) {
      if (roomLock) {
        form.setFieldsValue({
          reason: roomLock.reason,
          start_date: dayjs.utc(roomLock.start_date).local(),
          end_date: dayjs.utc(roomLock.end_date).local()
        });
      } else {
        form.setFieldsValue({
          start_date: dayjs().set('hour', 14).set('minute', 0).set('second', 0),
          end_date: dayjs().add(1, 'day').set('hour', 12).set('minute', 0).set('second', 0)
        });
      }
    } else {
      resetRoom();
      form.resetFields();
    }
  }, [form, isOpenFixRoom, resetRoom, roomLock]);

  const handleCloseFixRoom = () => {
    setIsOpenFixRoom(false);
  };

  const handleConfirmStopFixRoom = () => {
    setConfirmStopFixRoom(true);
  };

  const handleStopConfirm = () => {
    setConfirmStopFixRoom(false);
  };

  const handleStopFixRoom = () => {
    setConfirmStopFixRoom(false);
    isActive.current = false;
    form.submit();
  };

  const handleFixRoom = () => {
    isActive.current = true;
    form.submit();
  };

  const handleChangeRoomType = () => {
    form.setFieldValue('roomId', undefined);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (values.roomId || roomId) {
      try {
        const mutateFunc = isActive.current ? mutateFixRoom : mutateStopFixRoom;
        await mutateFunc({
          reason: values.reason,
          room_id: values.roomId || roomId,
          start_date: String(toUtcTime(values.start_date)),
          end_date: String(toUtcTime(values.end_date)),
          active: isActive.current,
          lock_id: roomLock?.lock_id
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_STATUS]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ROOM_LOCK]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
        });
        notification.success({
          message: roomLock ? 'Dừng khóa/sửa phòng thành công' : 'Thiết lập sửa phòng thành công'
        });

        resetRoom();
        setIsOpenFixRoom(false);
      } catch (err: any) {
        if (err.error.includes('There is booking for this room during this time period')) {
          notification.error({
            message: 'Không thực hiện được khóa phòng vì phòng có booking trong giai đoạn này'
          });
        } else {
          notification.error({
            message: err.error || 'Có lỗi xảy ra'
          });
        }
      }
    }
  };

  const roomTypeOptions = useMemo(() => {
    return _.uniqBy(allRooms, 'room_type_name').map(item => ({
      label: item.room_type_name,
      value: item.room_type_name
    }));
  }, [allRooms]);

  const options = useMemo(() => {
    return allRooms
      .filter(item => item.room_type_name === roomTypeForm)
      .map(item => ({
        label: item.name,
        value: item.id
      }));
  }, [allRooms, roomTypeForm]);

  return (
    <Modal
      title={
        roomLock
          ? `Điều chỉnh khóa/sửa phòng${!isCreateFixRoom ? ` - Phòng ${roomNo}` : ''}`
          : `Thiết lập sửa phòng${!isCreateFixRoom ? ` - Phòng ${roomNo}` : ''}`
      }
      centered
      width={800}
      open={isOpenFixRoom}
      closeIcon={<IconClose />}
      onCancel={handleCloseFixRoom}
      onOk={handleFixRoom}
      className="modal-fix-room"
      footer={
        <>
          <Button
            type={roomLock ? 'primary' : 'default'}
            onClick={roomLock ? handleConfirmStopFixRoom : handleCloseFixRoom}
            loading={roomLock ? isPendingStop : false}
          >
            {roomLock ? 'Dừng khóa/sửa phòng' : 'Bỏ qua'}
          </Button>
          <Button className="ant-btn-secondary" loading={isPending} onClick={handleFixRoom}>
            {roomLock ? 'Lưu thiết lập' : 'Thêm'}
          </Button>
        </>
      }
      destroyOnClose
    >
      <Form
        form={form}
        name="fix-room-form"
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{ width: '100%' }}
        initialValues={{}}
        onFinish={onFinish}
        autoComplete="off"
      >
        {isCreateFixRoom && (
          <Form.Item label="Chọn hạng phòng" name="roomType">
            <Select options={roomTypeOptions} loading={isLoading} onChange={handleChangeRoomType} />
          </Form.Item>
        )}

        {isCreateFixRoom && (
          <Form.Item label="Chọn phòng" name="roomId">
            <Select options={options} loading={isLoading} />
          </Form.Item>
        )}

        <Form.Item label="Ngày bắt đầu" name="start_date">
          <DatePicker showTime format="DD/MM/YYYY HH:mm" className="w-full" />
        </Form.Item>

        <Form.Item label="Ngày kết thúc" name="end_date">
          <DatePicker showTime format="DD/MM/YYYY HH:mm" className="w-full" />
        </Form.Item>

        <Form.Item
          label="Lý do"
          name="reason"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_REASON_FIX_ROOM
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      {confirmStopFixRoom && (
        <div className="confirm-stop">
          <p className="m-0 required">
            Bạn có chắc chắn muốn thiết lập lại thông tin Khóa/Sửa phòng không?
          </p>
          <Button.Group>
            <Button className="ant-btn-secondary" onClick={handleStopFixRoom}>
              Có
            </Button>
            <Button onClick={handleStopConfirm}>Không</Button>
          </Button.Group>
        </div>
      )}
    </Modal>
  );
}

export default FixRoomModal;
