import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setToken } from 'services/storages/userStorage';

function Auth() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get('token');
  const redirect = search.get('redirect');

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate(redirect as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, redirect]);

  return (
    <div className="pms-homepage">
      <h1>Auth</h1>
    </div>
  );
}

export default Auth;
