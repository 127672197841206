import { TransformedRevenueData } from 'services/api/type/report.type';
import { Table } from 'antd';
import { getColumnsConsolidateRevenue } from 'constants/table';
import { useMemo } from 'react';

interface CustomTableProps {
  loading?: boolean;
  dataSource: TransformedRevenueData[];
  onShowOcc?: boolean;
  title: string;
}

export default function CustomerTableForConsolidationRevenue({
  loading,
  dataSource,
  onShowOcc,
  title
}: CustomTableProps) {
  const columnsConsolidateRevenue = useMemo(
    () => getColumnsConsolidateRevenue(title, onShowOcc),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource]
  );

  return (
    <Table
      loading={loading}
      rowKey="id"
      size="small"
      columns={columnsConsolidateRevenue}
      dataSource={dataSource ?? []}
      rowClassName={record => (record.roomType === 'Tổng' ? 'bold-row' : '')}
      bordered
      pagination={false}
      locale={{
        emptyText: <span className="empty-data">Không có dữ liệu</span>
      }}
      scroll={{ x: 1200, y: 'calc(100vh - 400px)' }}
    />
  );
}
