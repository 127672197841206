import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Select,
  message,
  Spin,
  Descriptions,
  Divider
} from 'antd';
import { useGetCurrentShiftHandover } from 'hooks/useGetCurrentShiftHandover';
import { useGetPmsUser } from 'hooks/useGetPmsUser';
import { createShiftHandover } from 'services/api/module/shift-handover.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ParamsCreateShiftHandover } from 'services/api/type/shift-handover.type';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetMe } from 'hooks/useGetMe';
import { SHIFT_HANDOVER_FORM } from 'constants/form';
import { formatMoney, parseMoney } from 'utils';

import 'styles/shift-handover.scss';
import QUERY_KEYS from 'services/api/queryKeys';

const { Text } = Typography;

const ShiftHandoverForm: React.FC = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data: currentShiftHandover, isLoading } = useGetCurrentShiftHandover();
  const { data: pmsUser } = useGetPmsUser();
  const { data: currentUser } = useGetMe();

  const [handoverUserId, setHandoverUserId] = useState<number | null>(null);

  const openingAmount = Form.useWatch('openingAmount', form);
  const incomeAmount = Form.useWatch('incomeAmount', form);
  const expenseAmount = Form.useWatch('expenseAmount', form);
  const actualAmount = Form.useWatch('actualAmount', form);

  const { mutateAsync: mutateCreateShiftHandover } = useMutation({
    mutationFn: async (params: ParamsCreateShiftHandover) => {
      return createShiftHandover(params);
    },
    onSuccess: () => {
      message.success('Shift handover assigned successfully');
      form.resetFields();
      setHandoverUserId(null);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CURRENT_SHIFT_HANDOVER] });
    },
    onError: () => {
      message.error('Failed to assign shift handover');
    }
  });

  const handleShiftHandover = (value: number) => {
    setHandoverUserId(value);
  };

  useEffect(() => {
    if (currentShiftHandover) {
      form.setFieldsValue({
        openingAmount: formatMoney(currentShiftHandover.opening_amount),
        incomeAmount: formatMoney(currentShiftHandover.income_amount),
        expenseAmount: formatMoney(currentShiftHandover.expense_amount),
        currentAmount: formatMoney(currentShiftHandover.current_amount),
        difference: formatMoney(
          Number(currentShiftHandover.current_amount) - Number(currentShiftHandover.actual_amount)
        ),
        actualAmount: formatMoney(currentShiftHandover.actual_amount),
        notes: currentShiftHandover.note || ''
      });
    }
  }, [currentShiftHandover, form]);

  useEffect(() => {
    const currentAmount =
      parseMoney(openingAmount) + parseMoney(incomeAmount) - parseMoney(expenseAmount);
    const difference = currentAmount - parseMoney(actualAmount);

    form.setFieldsValue({
      currentAmount: formatMoney(currentAmount),
      difference: formatMoney(difference)
    });
  }, [openingAmount, incomeAmount, expenseAmount, actualAmount, form]);

  const onFinish = async (values: any) => {
    if (!handoverUserId) {
      message.error('Please select a recipient');
      return;
    }
    await mutateCreateShiftHandover({
      handover_user_id: handoverUserId,
      actual_amount: parseMoney(values.actualAmount),
      note: values.notesSendNewShiftHandover || ''
    });
  };

  if (isLoading) {
    return <Spin fullscreen indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />;
  }

  const handleDisableButton = () => {
    if (currentShiftHandover?.state && currentShiftHandover?.user_id !== Number(currentUser?.id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="shift-handover-form">
      <Card title="Bàn giao tiền mặt" style={{ maxWidth: '600px', margin: '0 auto' }}>
        <Descriptions column={1}>
          <Descriptions.Item label="Ca hiện tại">
            <Text strong>{currentShiftHandover?.user_name}</Text>
            <span style={{ marginLeft: 8 }}>
              <Text type="secondary">Email: </Text>
              <Text code>
                <strong>{currentShiftHandover?.user_email}</strong>
              </Text>
            </span>
          </Descriptions.Item>
        </Descriptions>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Text>Ghi chú của ca trước: {currentShiftHandover?.note}</Text>
          <Divider style={{ margin: '16px 0' }} />

          {SHIFT_HANDOVER_FORM.map((item, index) => (
            <div key={item.name} className="form-row">
              <Text className="label">
                {index + 1 + ' '}
                {item.label}
              </Text>
              <Form.Item name={item.name} noStyle>
                <Input
                  disabled={item.name !== 'actualAmount'}
                  onChange={e => {
                    const value = formatMoney(parseMoney(e.target.value));
                    form.setFieldsValue({ [item.name]: value });
                  }}
                />
              </Form.Item>
            </div>
          ))}

          <Form.Item name="recipient" label="Người nhận" className="full-width-input">
            <Select
              showSearch
              options={pmsUser?.map(staff => ({
                value: staff.id,
                label: `${staff.name} (${staff.email})`
              }))}
              onChange={value => handleShiftHandover(value)}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="notesSendNewShiftHandover" label="Ghi chú" className="full-width-input">
            <Input.TextArea rows={3} />
          </Form.Item>

          <Text className="footnote">* Chú thích: 4 = 1 + 2 - 3 &nbsp;&nbsp;&nbsp; 5 = 4 - 6</Text>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={handleDisableButton()}
            >
              Bàn giao
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ShiftHandoverForm;
