import React, { useCallback, useState } from 'react';
import { Select, Form } from 'antd';

import { useGetTravelAgencies } from 'hooks/useGetTravelAgencies';
import _ from 'lodash';

interface SelectGroupProps {
  form: any;
}

export function SelectGroup({ form }: SelectGroupProps) {
  // Using ant select and server side fetch data
  const [searchCompany, setSearchCompany] = useState({});
  const { data: dataCompany, isLoading } = useGetTravelAgencies(searchCompany);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchCompany = useCallback(
    _.debounce((value: string) => {
      setSearchCompany({ name: value });
    }, 500),
    []
  );

  return (
    <>
      <div className="flex items-center" style={{ gap: '8px' }}>
        <Form.Item label="Đoàn / Công Ty" name="hotel_travel_agency_id" style={{ flex: 1 }}>
          <Select
            showSearch
            allowClear
            onClear={() => setSearchCompany({})}
            placeholder="Chọn Đoàn"
            optionFilterProp="children"
            loading={isLoading}
            onSearch={handleSearchCompany}
            onSelect={value => {
              const selectedCompany = dataCompany?.find(item => item.id === value);
              if (selectedCompany) {
                form.setFieldsValue({
                  source_id: selectedCompany.source_id,
                  medium_id: selectedCompany.medium_id
                });
              }
            }}
          >
            {dataCompany?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.id} - ${item.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </>
  );
}
