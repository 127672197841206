import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetHouseStatus = (date: string) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_HOUSE_STATUS, date],
    queryFn: () => API.report.getHouseStatus(date),
    enabled: !!date
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
