import React from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';

import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetSources } from 'hooks/useGetSources';

import { CustomerForm } from 'components/form/customer-info/CustomerForm';
import 'styles/customer-info.scss';
import { SelectGroup } from './SelectGroup';
import { MESSAGE_CODE } from 'constants/validate';

function CustomerInfo({ form }: { form: FormInstance }) {
  const { data: companies } = useGetCompanies();
  const { data: sources } = useGetSources();

  return (
    <div className="pms-new-group__form">
      <Row gutter={[48, 16]} className="new-group-form">
        <Col span={24} md={12}>
          <h5 className="title">Thông tin đặt phòng</h5>
          <SelectGroup form={form} />

          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea placeholder="Nội dung" rows={8} />
          </Form.Item>

          <Form.Item
            name="medium_id"
            label="Phân khúc thị trường"
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_MARKET
              }
            ]}
          >
            <Select
              options={companies}
              showSearch
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
          <Form.Item
            name="source_id"
            label="Chọn nguồn"
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_SOURCE
              }
            ]}
          >
            <Select
              options={sources}
              showSearch
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>

          {/*<Form.Item label="Chọn thị trường" name="market">*/}
          {/*  <Select options={[]} />*/}
          {/*</Form.Item>*/}
        </Col>
        <Col span={24} md={12}>
          <CustomerForm name="" form={form} selectedCustomerId="customer" isShowUpload={false} />
        </Col>
      </Row>
    </div>
  );
}

export default CustomerInfo;
