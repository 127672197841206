import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { getCurrentShiftHandover } from 'services/api/module/shift-handover.api';

export const useGetCurrentShiftHandover = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CURRENT_SHIFT_HANDOVER],
    queryFn: () => getCurrentShiftHandover()
  });
  return {
    data,
    isLoading
  };
};
