import { Select } from 'antd';
import { useGetCountries } from 'hooks/useGetCountries';

interface Props {
  value: any;
  onValueChange: (value: any) => void;
}

const NationalityPickerEditor = ({ value, onValueChange }: Props) => {
  const { data: countries, isLoading } = useGetCountries();

  return (
    <Select
      value={value}
      options={countries}
      style={{ minWidth: 150 }}
      onChange={value => onValueChange(value === '' ? null : value)}
      loading={isLoading}
      fieldNames={{ label: 'name', value: 'id' }}
    />
  );
};

export default NationalityPickerEditor;
