// useGetRoomMap with useQuery hook, to fetch data from the API api/characters
// and return the data and the status of the request
import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { RoomDetail } from 'services/api/type/room.type';
import { FILTER_STATUS_ROOM_MAP_VALUE } from 'constants/common';

export const useGetRooms = () => {
  const {
    data: dataRoomMap,
    status,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_MAP],
    queryFn: () => API.room.getRooms({})
  });

  const categorizedData = useMemo(() => {
    const allRooms: RoomDetail[] = _.get(dataRoomMap, 'data.data', []);
    const availableRooms = (allRooms || []).filter(item => item.available);
    const confirmRooms = (allRooms || []).filter(item => item.status === 'status');
    const dirtyRooms = (allRooms || []).filter(item => !item.is_clean);

    const quantity = {
      [FILTER_STATUS_ROOM_MAP_VALUE.ALL]: allRooms.length,
      [FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE]: availableRooms.length,
      [FILTER_STATUS_ROOM_MAP_VALUE.BOOKED]: confirmRooms.length,
      [FILTER_STATUS_ROOM_MAP_VALUE.DIRTY]: dirtyRooms.length
    };
    return {
      allRooms,
      availableRooms,
      confirmRooms,
      dirtyRooms,
      quantity
    };
  }, [dataRoomMap]);

  return {
    ...categorizedData,
    status,
    isLoading,
    isFetching
  };
};
