import { columnTransactionsPaymentV2 } from 'constants/table';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetTransactionsPayment } from 'hooks/useGetPayments';
import { PAGE_SIZE_TRANSACTION } from 'services/api/module/report.api';
import { TRANSACTIONS_TYPE_OPTIONS } from 'constants/form';
import { useGetTransactionCodes } from 'hooks/useGetGuestTransactions';
import {
  TransactionCodesGroupType,
  TransactionCodesResponse
} from 'services/api/type/booking.type';

interface TransactionV2Props {
  filterOptions: any;
  setSelectedPaymentState: (state: string | undefined) => void;
  setSelectedPaymentMethod: (method: string | undefined) => void;
  setTransactionCodes: (code: TransactionCodesResponse[]) => void;
}

export function TransactionV2(props: TransactionV2Props) {
  const { filterOptions, setSelectedPaymentState, setSelectedPaymentMethod, setTransactionCodes } =
    props;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_TRANSACTION);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const {
    data: dataSource,
    pagination: pagination,
    isFetching: isFetching
  } = useGetTransactionsPayment(filterOptions, page, pageSize);

  const { data: transactionCodes } = useGetTransactionCodes(
    `${TransactionCodesGroupType.PAYMENT},${TransactionCodesGroupType.REFUND}`
  );

  const selectedPaymentState = TRANSACTIONS_TYPE_OPTIONS.find(
    option => option.value === filterOptions.state
  );
  const selectedPaymentMethod = transactionCodes?.find(
    option => option.id === filterOptions.transaction_code_id
  );

  useEffect(() => {
    if (selectedPaymentState) {
      setSelectedPaymentState(selectedPaymentState?.label);
    }
    if (selectedPaymentMethod) {
      setSelectedPaymentMethod(selectedPaymentMethod?.name);
    }
    if (transactionCodes) {
      setTransactionCodes(transactionCodes);
    }
    // eslint-disable-next-line
  }, [dataSource, selectedPaymentMethod, selectedPaymentState]);

  return (
    <div>
      <Table
        loading={isFetching}
        rowKey="id"
        columns={columnTransactionsPaymentV2}
        dataSource={dataSource}
        pagination={{
          current: page,
          position: ['bottomCenter'],
          total: pagination.total,
          pageSize: pageSize,
          locale: { items_per_page: '/ trang' },
          showTotal: total => `${total} items`,
          onChange: (page: number) => handleChangePage(page),
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          }
        }}
        locale={{
          emptyText: <span className="empty-data">Không có dữ liệu</span>
        }}
        scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
      />
    </div>
  );
}
