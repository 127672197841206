import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetSources = () => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_SOURCES],
    queryFn: API.common.getSources
  });

  const sortedData = useMemo(() => {
    return data?.sort(a => {
      return a.name.includes('SO -') ? -1 : 1;
    });
  }, [data]);

  return {
    data: sortedData,
    status,
    isLoading,
    isFetching
  };
};
