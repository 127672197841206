import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import { IsFullWidthRowParams } from 'ag-grid-community';
import { Button, Card, Checkbox, Col, DatePicker, Flex, Form, FormProps, Input, Row } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import QUERY_KEYS from 'services/api/queryKeys';
import { handleRangeDateChange, toUtcTime } from 'utils';
import { getCashierGuestsColumns } from 'constants/ag-grid-table';
import 'styles/cashier.scss';
import { useGetGuestOrders, useGetGuestTransactions } from 'hooks/useGetGuestTransactions';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import { CashierReservation } from './components/CashierReservation';
import { CashierTransaction } from './components/CashierTransaction';
import { BookingStatus, ParamsGetTransactionsType } from 'services/api/type/booking.type';
import useCashierStore from 'stores/useCashier';

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function Cashier() {
  const [form] = Form.useForm();

  const { selectedReservationId, selectedGroupId, setSelectedReservationId, setSelectedGroupId } =
    useCashierStore();

  const [filterOptions, setFilterOptions] = useState<ParamsGetTransactionsType>({
    main_guest_only: true
  });
  const [enableArrivalTime, setEnableArrivalTime] = useState<boolean>(false);
  const [enableDepartureTime, setEnableDepartureTime] = useState<boolean>(false);

  // const arrivalTimeForm = Form.useWatch('arrival_time', form);
  const { data: guests } = useGetGuestTransactions(filterOptions);
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const { data: bookingLine } = useGetBookingDetail(selectedRow?.booking_line_id);
  const { data: orders } = useGetGuestOrders(selectedRow?.id);

  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW, page]
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  useEffect(() => {
    if (selectedReservationId) {
      setFilterOptions((prevOptions: any) => ({
        ...prevOptions,
        guest_info: selectedReservationId
      }));
      form.setFieldsValue({
        guest_info: selectedReservationId
      });
      setSelectedReservationId('');
    }
  }, [selectedReservationId, form, setSelectedReservationId]);

  useEffect(() => {
    if (selectedGroupId) {
      setFilterOptions((prevOptions: any) => ({
        ...prevOptions,
        group_info: selectedGroupId
      }));
      form.setFieldsValue({
        group_info: selectedGroupId
      });
      setSelectedGroupId('');
    }
  }, [selectedGroupId, form, setSelectedGroupId]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const {
      check_in_range,
      check_out_range,
      is_co_today,
      is_reserved,
      is_in_house,
      is_checked_out,
      ...restFilterOptions
    } = truthyValues;

    const configMap = [
      {
        condition: check_in_range && enableArrivalTime,
        actions: () => {
          const startDate: Dayjs = check_in_range[0];
          const endDate: Dayjs = check_in_range[1];
          if (startDate)
            _.set(
              restFilterOptions,
              'check_in_from',
              toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD')
            );
          if (endDate)
            _.set(restFilterOptions, 'check_in_to', toUtcTime(endDate.add(1, 'day'), 'YYYY-MM-DD'));
        }
      },
      {
        condition: check_out_range && enableDepartureTime,
        actions: () => {
          const startDate: Dayjs = check_out_range[0];
          const endDate: Dayjs = check_out_range[1];
          if (startDate)
            _.set(
              restFilterOptions,
              'check_out_from',
              toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD')
            );
          if (endDate)
            _.set(
              restFilterOptions,
              'check_out_to',
              toUtcTime(endDate.add(1, 'day'), 'YYYY-MM-DD')
            );
        }
      },
      {
        condition: is_co_today || is_reserved || is_in_house || is_checked_out,
        actions: () => {
          const statusBarList = [];
          if (is_co_today) {
            const today = dayjs().format('YYYY-MM-DD');
            _.set(restFilterOptions, 'check_out_from', today);
            _.set(restFilterOptions, 'check_out_to', today);
          }
          if (is_reserved) statusBarList.push(BookingStatus.INITIAL);
          if (is_co_today || is_in_house) statusBarList.push(BookingStatus.CHECK_IN);
          if (is_checked_out) statusBarList.push(BookingStatus.CHECK_OUT);
          if (statusBarList.length > 0) {
            _.set(restFilterOptions, 'status_bar', statusBarList.join(','));
          }
        }
      }
    ];

    configMap.forEach(({ condition, actions }) => {
      if (condition) actions();
    });

    if (page !== 1) setPage(1);

    setFilterOptions(restFilterOptions);
  };

  const isFullWidthRow = (params: IsFullWidthRowParams) => {
    return params.rowNode.data?.is_party;
  };

  const cashierGuestsColumns = useMemo(() => getCashierGuestsColumns(), []);

  const handleCheckboxArrivalClick = (e: any) => {
    setEnableArrivalTime(e.target.checked);
  };

  const handleCheckboxDepartureClick = (e: any) => {
    setEnableDepartureTime(e.target.checked);
  };

  const handleClickParty = (value: string) => {
    setSelectedReservationId(value);
  };

  const handleClickGroupId = (value: string) => {
    setSelectedGroupId(value);
  };

  return (
    <div className="pms-cashier">
      <Row gutter={[8, 16]} className="pms-cashier__row">
        <Col xs={24} xl={11} xxl={8} className="flex flex-col h-full">
          <Card style={{ width: '100%' }}>
            <Form
              form={form}
              name="cashier-filter-form"
              layout={'inline'}
              initialValues={{
                arrivalTime: [initStartDate, initEndDate],
                main_guest_only: true
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={12}>
                  <Form.Item
                    label="Guest Info"
                    name="guest_info"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Group"
                    name="group_info"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="TA/Company"
                    name="travel_agency_info"
                    wrapperCol={{ span: 16 }}
                    labelCol={{ span: 8 }}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/*<Col span={12}>*/}
                {/*  <Form.Item*/}
                {/*    label="Rental Type"*/}
                {/*    name="rentalType"*/}
                {/*    wrapperCol={{ span: 16 }}*/}
                {/*    labelCol={{ span: 8 }}*/}
                {/*  >*/}
                {/*    <Select options={RENTAL_TYPE_OPTIONS} disabled />*/}
                {/*  </Form.Item>*/}
                {/*</Col>*/}
              </Row>

              {/* Arrival */}
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={4}>
                  <Form.Item valuePropName="checked">
                    <Checkbox onClick={handleCheckboxArrivalClick}>Arrival</Checkbox>
                  </Form.Item>
                </Col>

                <Col span={20} style={{ paddingLeft: 2 }}>
                  <Form.Item name="check_in_range" valuePropName="checked" className="w-full">
                    <DatePicker.RangePicker
                      className="w-full"
                      placeholder={['Từ ngày', 'Đến ngày']}
                      format="DD-MM-YYYY"
                      onChange={value => handleRangeDateChange(form, value, 'check_in')}
                      disabled={!enableArrivalTime}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Departure */}
              <Row gutter={[8, 8]} className="w-full" style={{ marginBottom: 8 }}>
                <Col span={4}>
                  <Form.Item valuePropName="checked">
                    <Checkbox onClick={handleCheckboxDepartureClick}>Departure</Checkbox>
                  </Form.Item>
                </Col>

                <Col span={20} style={{ paddingLeft: 2 }}>
                  <Form.Item name="check_out_range" valuePropName="checked" className="w-full">
                    <DatePicker.RangePicker
                      className="w-full"
                      placeholder={['Từ ngày', 'Đến ngày']}
                      format="DD-MM-YYYY"
                      onChange={value => handleRangeDateChange(form, value, 'check_out')}
                      disabled={!enableDepartureTime}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Flex
                align="center"
                justify="space-between"
                wrap
                className="w-full checkbox-wrapper"
                style={{ marginBottom: 12 }}
              >
                <Form.Item name="non_zero_balance" valuePropName="checked">
                  <Checkbox>Non-zero Balance</Checkbox>
                </Form.Item>

                <Form.Item name="is_co_today" valuePropName="checked">
                  <Checkbox>C/O Today</Checkbox>
                </Form.Item>

                <Form.Item name="is_reserved" valuePropName="checked">
                  <Checkbox>Reserved</Checkbox>
                </Form.Item>

                <Form.Item name="is_in_house" valuePropName="checked">
                  <Checkbox>In House</Checkbox>
                </Form.Item>

                <Form.Item name="is_checked_out" valuePropName="checked">
                  <Checkbox>Checked Out</Checkbox>
                </Form.Item>
              </Flex>

              <Flex align="center" justify="space-between" wrap className="w-full checkbox-wrapper">
                <Form.Item name="main_guest_only" valuePropName="checked">
                  <Checkbox>Main guest only</Checkbox>
                </Form.Item>
                <Button htmlType="submit" className="ant-btn-secondary btn-submit ml-auto">
                  Tìm kiếm
                </Button>
              </Flex>
            </Form>
          </Card>

          <Card
            style={{ width: '100%', marginTop: 4, flex: 1 }}
            className="flex flex-col pms-cashier-guests"
          >
            <p className="title">LIST GUESTS</p>
            <div className="pms-cashier-guests__table">
              <div className="pms-cashier-guests__table ag-theme-quartz">
                <AgGridReact
                  rowData={guests}
                  columnDefs={cashierGuestsColumns}
                  // loadingOverlayComponent={isFetchingRoomTypes}
                  // loadingCellRenderer={isFetchingRoomTypes}
                  onRowClicked={event => {
                    if (event.data?.is_party) {
                      return;
                    }
                    setSelectedRow(event.data);
                  }}
                  getRowId={params => params.data.id} // Ensure `id` is unique for each row
                  stopEditingWhenCellsLoseFocus
                  getRowClass={params => {
                    return params.data.id === selectedRow?.id ? 'highlight-row' : '';
                  }}
                  isFullWidthRow={isFullWidthRow}
                  fullWidthCellRenderer={(item: any) => {
                    const data = item.data;
                    const group = data.group_id ? (
                      <>
                        Group:{' '}
                        <>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              handleClickGroupId(String(data.group_id));
                            }}
                            className="hightlight"
                          >
                            {data.group_id}
                          </a>
                        </>{' '}
                        - {data.group_name}{' '}
                        {item.data.party_id && (
                          <>
                            | Party:{' '}
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                handleClickParty(String(item.data.party_id));
                              }}
                              className="hightlight"
                            >
                              {item.data.party_id}
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      ''
                    );
                    return (
                      <div
                        className="flex items-center h-full fullwidth-row"
                        style={{ paddingLeft: 16 }}
                      >
                        <p className="m-0">
                          {group ? (
                            group
                          ) : item.data.party_id === 'FIT' ? (
                            'FIT'
                          ) : (
                            <>
                              Party:{' '}
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  handleClickParty(String(item.data.party_id));
                                }}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                  color: 'blue'
                                }}
                              >
                                {item.data.party_id}
                              </a>
                            </>
                          )}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} xl={13} xxl={16} className="flex flex-col">
          <CashierReservation bookingLine={bookingLine} orders={orders} />

          <CashierTransaction
            orders={orders}
            guest_id={selectedRow?.id}
            booking_line_id={bookingLine?.booking_line_id}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Cashier;
