import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetHKRoom = () => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_HK_ROOM],
    queryFn: () => API.hk.getHKRoom(),
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    refetchInterval: 6000
  });

  return {
    data: data || [],
    isLoading,
    isFetching
  };
};
