import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomStatus = (startDate: string, endDate: string) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_STATUS],
    queryFn: () => API.room.getRoomStatus(startDate, endDate)
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
