import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './invoice-table-header';
import InvoiceTableRow from './invoice-table-row';
import InvoiceTableFooter from './invoice-table-footer';

interface Props {
  totalPayment: number;
  totalSubAmount: number;
  totalPrice: number;
  totalNLTE: string;
  totalFinalService: number;
  totalFinalServiceFee: number;
  totalFinalTax: number;
  bookingLineItems: any[];
}

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    borderWidth: 1,
    borderBottom: 0
  }
});

const InvoiceItemsTable = ({
  totalPayment,
  totalSubAmount,
  totalPrice,
  totalFinalService,
  totalFinalServiceFee,
  totalFinalTax,
  totalNLTE,
  bookingLineItems
}: Props) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow bookingLineItems={bookingLineItems} />
    <InvoiceTableFooter
      totalPayment={totalPayment}
      totalSubAmount={totalSubAmount}
      totalPrice={totalPrice}
      totalNLTE={totalNLTE}
      totalFinalService={totalFinalService}
      totalFinalServiceFee={totalFinalServiceFee}
      totalFinalTax={totalFinalTax}
    />
  </View>
);

export default InvoiceItemsTable;
