import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetServices = () => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_SERVICES],
    queryFn: API.booking.getServices
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
