import { useEffect, useState } from 'react';

export const useUpdateCustomers = (form: any, customersForm: any[]) => {
  const [main_guest, setMainGuest] = useState<number>();

  useEffect(() => {
    const main_guest_id = customersForm?.findIndex(
      (item, index) => item?.guest?.main_guest && index !== main_guest
    );
    if (main_guest_id > -1) {
      setMainGuest(main_guest_id);
      customersForm?.forEach((_, index) => {
        if (index !== main_guest_id) {
          form.setFieldValue(['customers', index, 'guest', 'main_guest'], false);
        }
      });
    }
  }, [customersForm, main_guest, form]);
};
