import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './invoice-table-header';
import InvoiceTableRow from './invoice-table-row';
import InvoiceTableFooter from './invoice-table-footer';

interface Props {
  paymentItems: PaymentItem[];
  remainAmount: number;
  listTax: {
    [key: number]: boolean;
  };
}

export interface PaymentItem {
  date: string;
  detail: string;
  price: string;
  qty: string;
  serviceFee: string;
  tax: string;
  amount: string;
}

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    borderWidth: 1,
    borderBottom: 0
  }
});

const InvoiceItemsTable = ({ paymentItems, remainAmount, listTax }: Props) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader listTax={listTax} />
    <InvoiceTableRow items={paymentItems} listTax={listTax} />
    <InvoiceTableFooter remainAmount={remainAmount} listTax={listTax} />
  </View>
);

export default InvoiceItemsTable;
