import { useMemo } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { Dayjs } from 'dayjs';

import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import { toUtcTime } from 'utils';

interface Props {
  value: any;
  startDate: Dayjs | string;
  endDate: Dayjs | string;
  onValueChange: (value: any) => void;
}

const RoomPickerEditor = ({ value, startDate, endDate, onValueChange }: Props) => {
  const { data: availRoomTypes, isLoading } = useGetAvailRoomType(
    toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss')
  );

  const availRoomTypesOptions = useMemo(() => {
    return _.flatMap(availRoomTypes, roomType =>
      roomType.rooms.filter(room => room.room_status === 'available')
    ).map(item => ({
      label: item.room_name,
      value: item.room_id
    }));
  }, [availRoomTypes]);

  return (
    <Select
      value={value}
      options={availRoomTypesOptions}
      style={{ minWidth: 120 }}
      onChange={value => onValueChange(value === '' ? null : value)}
      loading={isLoading}
    />
  );
};

export default RoomPickerEditor;
