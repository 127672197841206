import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

interface FilterType {
  start_date: string;
  end_date: string;
  status: string;
}

export const useGetAllNationality = (filterOptions: FilterType) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_NATIONALITY],
    queryFn: () =>
      API.report.getAllNationality({
        ...filterOptions
      })
  });

  return {
    data: _.get(data, 'data', []),
    isLoading,
    isFetching
  };
};
