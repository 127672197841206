import _ from 'lodash';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, notification } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { unAssignRoom } from 'services/api/module/room.api';
import QUERY_KEYS from 'services/api/queryKeys';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { batchActionBookings } from 'services/api/module/booking.api';
import { BATCH_ACTION_TYPE, ParamsBatchAction } from 'services/api/type/booking.type';
import PAGES from 'routes/constants';

function CancelAssignRoomModal() {
  const queryClient = useQueryClient();
  const location = useLocation();

  const { isOpenCancelAssignRoom, setIsOpenCancelAssignRoom } = useModalStore();
  const { bookingLineId, checkedListBookingLineId, isActionOnGroup, setIsActionOnGroup } =
    useBookingStore();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { mutateAsync: mutateUnAssign, isPending: isPendingAssign } = useMutation({
    mutationFn: (params: any = {}) => unAssignRoom(params)
  });

  const { mutateAsync: mutateBatchActionBooking } = useMutation({
    mutationFn: (params: ParamsBatchAction) => batchActionBookings(params)
  });

  const handleCloseCancelAssignRoom = () => {
    setIsOpenCancelAssignRoom(false);
    setIsActionOnGroup(false);
  };

  const handleCancelAssignRoom = async () => {
    if (bookingLineDetail) {
      try {
        if (isActionOnGroup && !_.isEmpty(checkedListBookingLineId)) {
          await mutateBatchActionBooking({
            action: BATCH_ACTION_TYPE.UNASSIGN,
            booking_line_ids: checkedListBookingLineId
          });
        } else {
          await mutateUnAssign({
            booking_line_id: bookingLineDetail.booking_line_id
          });
        }

        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_AVAIL_ROOM_TYPE]
        });

        if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
          });
        }

        refreshBookingList(location);
        refreshRoomMap(location);

        setIsOpenCancelAssignRoom(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Xác nhận bỏ gán phòng"
      centered
      width={320}
      open={isOpenCancelAssignRoom}
      onCancel={handleCloseCancelAssignRoom}
      closeIcon={<IconClose />}
      okText="Bỏ gán phòng"
      cancelText="Đóng"
      onOk={handleCancelAssignRoom}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-cancel-assign-room"
      destroyOnClose
      confirmLoading={isPendingAssign}
    ></Modal>
  );
}

export default CancelAssignRoomModal;
