export interface LoginType {
  email: string;
  password: string;
}

export interface RegisterType {
  phoneNumber: string;
  otp: string;
}

export enum JobCode {
  HK = 'HK',
  SUB_HK = 'SUB_HK'
}

export interface MyInfoType {
  id: string;
  name: string;
  email: string;
  branch_ids: number[];
  job_code: JobCode;
  department_code: string;
}

export interface ParamsChangePassword {
  old_password: string;
  password: string;
}
