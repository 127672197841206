import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Button, Card, DatePicker, Select, Form, FormProps, Input } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import ExportTransactions from 'components/common/export-transactions';

import QUERY_KEYS from 'services/api/queryKeys';
import { TRANSACTIONS_TYPE_OPTIONS } from 'constants/form';

import { generateExportFileName, toUtcTime } from 'utils';
import 'styles/transactions.scss';
import { TransactionV2 } from 'components/modal/transactions-payment-report/transaction-v2';
import useCommonStore from 'stores/useCommon';
import { TransactionCodesResponse } from '@/services/api/type/booking.type';

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function TransactionsV2() {
  const [form] = Form.useForm();
  const { currentBranch } = useCommonStore();

  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD')
  });

  const [page, setPage] = useState<number>(1);

  // state of those components use for Export data
  const [selectedPaymentState, setSelectedPaymentState] = useState<string | undefined>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>('');

  // state of transactionV2 (transaction Codes)
  const [transactionCodes, setTransactionCodes] = useState<TransactionCodesResponse[]>([]);
  const mapTransactionPaymentTypes = (transactionCodes: TransactionCodesResponse[]) => {
    const options = transactionCodes.map(item => ({
      label: item.name,
      value: item.id
    }));

    return [{ label: 'All', value: '' }, ...options];
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_TRANSACTIONS_PAYMENT_V2, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  return (
    <div className="pms-transactions">
      <Card title="Danh sách giao dịch V2" style={{ width: '100%' }}>
        <Form
          form={form}
          name="staying-guests-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate],
            state: '',
            transaction_code_id: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-transactions__filter-wrapper">
            <div className="pms-transactions__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  showTime
                  className="w-full"
                  placeholder={['From date', 'To date']}
                  format="YYYY-MM-DD HH:mm"
                  // onChange={handleRangeDateChange}
                />
              </Form.Item>

              <Form.Item name="booking_id">
                <Input placeholder="Booking Id" />
              </Form.Item>

              <Form.Item name="state">
                <Select
                  options={TRANSACTIONS_TYPE_OPTIONS}
                  labelRender={option => <span>Status: {option.label}</span>}
                />
              </Form.Item>

              <Form.Item name="transaction_code_id">
                <Select
                  options={mapTransactionPaymentTypes(transactionCodes || [])}
                  labelRender={option => <span>Payment method: {option.label}</span>}
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
            <ExportTransactions
              fileName={generateExportFileName('transactions-v2')}
              selectedPaymentState={selectedPaymentState}
              selectedPaymentMethod={selectedPaymentMethod}
              englishType={true}
              filterOptions={filterOptions}
            />
          </div>
        </Form>

        <div className="pms-transactions__table">
          {currentBranch?.is_enable_v2 && (
            <TransactionV2
              filterOptions={filterOptions}
              setSelectedPaymentState={setSelectedPaymentState}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              setTransactionCodes={setTransactionCodes}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default TransactionsV2;
