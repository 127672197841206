import dayjs, { Dayjs } from 'dayjs';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Checkbox } from 'antd';
import { EditFilled } from '@ant-design/icons';

import NumberEditor from 'components/ag-grid/number-editor';
import DatePickerEditor from 'components/ag-grid/datepicker-editor';
import CheckboxEditor from 'components/ag-grid/checkbox-editor';
import GenderPickerEditor from 'components/ag-grid/gender-picker-editor';
import NationalityPickerEditor from 'components/ag-grid/nationality-picker-editor';
import RoomPickerEditor from 'components/ag-grid/room-picker-editor';
import {
  DATE_TIME_SHORT_FORMAT,
  formatNumber,
  renderWithLineThroughAgTable,
  toLocalTime,
  renderTextWithEllipsis
} from 'utils';
import { BOOKING_STATUS, GROUP_STATUS_NAMES } from 'constants/form';

const RegexDecimal = /\B(?=(\d{3})+(?!\d))/g;

export const getNewGroupCols = (arrival: Dayjs, departure: Dayjs) => {
  const cols: ColDef[] = [
    {
      field: 'room_type_name',
      headerName: 'Type',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'available_rooms',
      headerName: 'Avail',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'from_date',
      headerName: 'From date',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      cellEditorParams: {
        arrival,
        departure
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.from_date).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'to_date',
      headerName: 'To date',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      cellEditorParams: {
        arrival,
        departure
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.to_date).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'numberOfRooms',
      headerName: '#Rms',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.numberOfRooms}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'pax',
      headerName: '#Pax',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.pax}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'isRateOverride',
      headerName: 'Rate override',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      // singleClickEdit: true,
      cellEditor: CheckboxEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (e: any) => {
          params?.setValue && params.setValue(e.target.checked);
        };
        return <Checkbox checked={params.value} onChange={handleChangeOverride} />;
      }
    },
    {
      field: 'rateAmount',
      headerName: 'Rate amount',
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: params => {
        return params.data?.isRateOverride;
      },
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.rateAmount}`.replace(new RegExp(RegexDecimal), ',');
      }
    }
  ];
  return cols;
};

export const getGroupRoomingListCols = (startDate: Dayjs, endDate: Dayjs) => {
  const cols: ColDef[] = [
    {
      field: 'seq',
      headerName: 'Seq',
      width: 100,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      checkboxSelection: true,
      valueGetter: params => {
        return (params.node?.rowIndex || 0) + 1;
      }
    },
    {
      field: 'partner_name',
      headerName: 'Name',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'gender',
      headerName: 'M_F',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: GenderPickerEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return <GenderPickerEditor value={params.value} onValueChange={handleChangeOverride} />;
      }
    },
    {
      field: 'dob',
      headerName: 'Date Birth',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: DatePickerEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.data?.dob).format('DD/MM/YYYY') || '';
      }
    },
    {
      field: 'country_id',
      headerName: 'Nationality',
      width: 180,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NationalityPickerEditor,
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return (
          <NationalityPickerEditor value={params.value} onValueChange={handleChangeOverride} />
        );
      }
    },
    {
      field: 'contact_address_complete',
      headerName: 'Address',
      width: 200,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'room_id',
      headerName: 'Room',
      width: 160,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: RoomPickerEditor,
      cellEditorParams: {
        startDate,
        endDate
      },
      cellRenderer: (params: CustomCellRendererProps) => {
        const handleChangeOverride = (value: any) => {
          params?.setValue && params.setValue(value);
        };
        return (
          <RoomPickerEditor
            startDate={startDate}
            endDate={endDate}
            value={params.value}
            onValueChange={handleChangeOverride}
          />
        );
      }
    },
    {
      field: 'adult',
      headerName: 'Adult',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.adult}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'child',
      headerName: 'Child',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true,
      cellEditor: NumberEditor,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.child}`.replace(new RegExp(RegexDecimal), ',');
      }
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      editable: true
    }
  ];
  return cols;
};

export const getCashierGuestsColumns = () => {
  const cashierGuestsColumns: ColDef[] = [
    {
      field: 'status',
      headerName: 'Sts',
      width: 60,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return GROUP_STATUS_NAMES[params?.data?.status as BOOKING_STATUS];
      }
    },
    {
      field: 'booking_id',
      headerName: 'B. No',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'booking_line_id',
      headerName: 'Resv',
      width: 80,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center'
    },
    {
      field: 'guest_name',
      headerName: 'Guest Name',
      width: 160,
      headerClass: 'text-center',
      cellRenderer: (params: ValueFormatterParams) => {
        const data = params?.data;
        // if main guest then add some icon
        return data.main_guest ? (
          <>
            {data.guest_name} <span>*</span>
          </>
        ) : (
          data?.guest_name
        );
      }
    },
    {
      field: 'room_no',
      headerName: 'RN',
      width: 70,
      headerClass: 'text-center'
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 120,
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params?.data?.balance);
      }
    },
    {
      field: 'check_in',
      headerName: 'Arr',
      width: 75,
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return toLocalTime(params?.data?.check_in, 'DD/MM');
      }
    },
    {
      field: 'check_out',
      headerName: 'Dept',
      width: 75,
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return toLocalTime(params?.data?.check_out, 'DD/MM');
      }
    }
  ];

  return cashierGuestsColumns;
};

export const getCashierTransactionCols = () => {
  const cols: ColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 80,
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'transaction_date',
      headerName: 'Tx Date',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return dayjs(params.value).format('DD/MM') || '';
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'code',
      headerName: 'Tx Code',
      filter: 'agTextColumnFilter',
      width: 120,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-center',
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'transaction_code_name',
      headerName: 'Tx Name',
      width: 240,
      cellStyle: { textAlign: 'left' },
      filter: 'agTextColumnFilter',
      headerClass: 'text-left'
    },
    {
      field: 'name',
      headerName: 'Description',
      headerClass: 'text-center',
      flex: 1,
      minWidth: 200,
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'qty',
      headerName: 'Qty',
      width: 80,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params.value);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params.value);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return formatNumber(params.value);
        } else {
          return renderWithLineThroughAgTable(formatNumber(params.value), params.data.is_deleted);
        }
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 120,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params.value);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return formatNumber(params.value);
        } else {
          return renderWithLineThroughAgTable(formatNumber(params.value), params.data.is_deleted);
        }
      }
    },
    {
      field: 'tax_amount',
      headerName: 'Tax',
      width: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params.value);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return formatNumber(params.value);
        } else {
          return renderWithLineThroughAgTable(formatNumber(params.value), params.data.is_deleted);
        }
      }
    },
    {
      field: 'service_charge',
      headerName: 'Service Charge',
      width: 140,
      valueFormatter: (params: ValueFormatterParams) => {
        return formatNumber(params.value);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return formatNumber(params.value);
        } else {
          return renderWithLineThroughAgTable(formatNumber(params.value), params.data.is_deleted);
        }
      }
    },
    {
      field: 'reference',
      headerName: 'Ref.',
      width: 150,
      headerClass: 'text-center',
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return renderTextWithEllipsis(params.value);
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'room_no',
      headerName: 'Original Rm',
      width: 120,
      headerClass: 'text-center',
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'note',
      headerName: 'Notice',
      headerComponent: () => (
        <p
          className="flex items-center m-0 justify-center w-full"
          style={{ color: 'white', fontWeight: 600 }}
        >
          Notice
          <EditFilled style={{ marginLeft: 4 }} />
        </p>
      ),
      width: 160,
      headerClass: 'text-center',
      editable: true,
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'created_by',
      headerName: 'ClerkID',
      width: 120,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    },
    {
      field: 'created_at',
      headerName: 'Posted Time',
      width: 180,
      cellStyle: { textAlign: 'center' },
      headerClass: 'text-center',
      valueFormatter: (params: ValueFormatterParams) => {
        return toLocalTime(params.value, DATE_TIME_SHORT_FORMAT);
      },
      cellRenderer: (params: any) => {
        if (!params.data.is_deleted) {
          return params.value;
        } else {
          return renderWithLineThroughAgTable(params.value, params.data.is_deleted);
        }
      }
    }
  ];
  return cols;
};
