import _ from 'lodash';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const borderColor = '#000';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    height: 80
  },
  date: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  detail: {
    width: '30%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  price: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  qty: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  serviceFee: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  tax: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  amount: {
    width: '15%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  text: { fontSize: 11, fontFamily: 'Inter', fontWeight: 400 }
});

interface Props {
  listTax: {
    [key: number]: boolean;
  };
}

const InvoiceTableHeader = ({ listTax }: Props) => (
  <View style={styles.container}>
    <View style={styles.date}>
      <Text style={styles.text}>Ngày</Text>
      <Text style={styles.text}>(Date)</Text>
    </View>
    <View style={styles.detail}>
      <Text style={styles.text}>Chi tiết</Text>
      <Text style={styles.text}>(Description)</Text>
    </View>
    <View style={styles.price}>
      <Text style={styles.text}>Tiền</Text>
      <Text style={styles.text}>(Sub</Text>
      <Text style={styles.text}>Amount)</Text>
    </View>
    <View style={styles.qty}>
      <Text style={styles.text}>Số lượng</Text>
      <Text style={styles.text}>(Quantity)</Text>
    </View>
    <View style={styles.serviceFee}>
      <Text style={styles.text}>Phí dịch vụ</Text>
      <Text style={styles.text}>5%</Text>
      <Text style={styles.text}>(Service</Text>
      <Text style={styles.text}>charge)</Text>
    </View>
    {Object.keys(listTax).map((tax, index) => (
      <View style={styles.tax} key={index}>
        <Text style={styles.text}>Thuế {tax}%</Text>
        <Text style={styles.text}>(Tax</Text>
        <Text style={styles.text}>amount)</Text>
      </View>
    ))}
    <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
      <Text style={styles.text}>Số tiền</Text>
      <Text style={styles.text}>(Amount)</Text>
    </View>
  </View>
);

export default InvoiceTableHeader;
