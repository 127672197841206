import React from 'react';
import { Modal } from 'antd';
import useModal from 'stores/useModal';

function ConfirmModal() {
  const { isOpenConfirmModal, propsConfirmModal, confirmLoading, setInfoConfirmModal } = useModal();

  const handleCancel = () => {
    setInfoConfirmModal(false, undefined);
  };

  return (
    <Modal
      title={propsConfirmModal?.title}
      className="modal-confirm"
      centered
      width={400}
      open={isOpenConfirmModal}
      okText={propsConfirmModal?.okText || 'Xác nhận'}
      cancelText={propsConfirmModal?.cancelText || 'Đóng'}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={propsConfirmModal?.onOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      destroyOnClose
    ></Modal>
  );
}

export default ConfirmModal;
