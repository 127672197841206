import React from 'react';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import ConfirmModal from 'components/modal/confirm-modal';

import { useGetMe } from 'hooks/useGetMe';

function AppMobile() {
  const { isLoading } = useGetMe();

  if (isLoading) {
    return <Spin fullscreen indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />;
  }

  return (
    <div className="pms-app-mobile">
      <main className={classNames('pms-app-content')}>
        <Outlet />
      </main>

      <ConfirmModal />
    </div>
  );
}

export default AppMobile;
