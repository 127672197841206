import { Button, Modal, Table } from 'antd';
import { columnCustomerBookings } from 'constants/table';
import React from 'react';
import { useGetAllBooking } from 'hooks/useGetBooking';

interface Props {
  customer_id?: number;
  isOpen?: boolean;
  onCancel?: () => void;
}
function CustomersHistoryBookings({ customer_id, isOpen, onCancel }: Props) {
  const { data: bookingData, isLoading } = useGetAllBooking({ customer_id: customer_id }, isOpen);
  return (
    <Modal
      title="Chi tiết booking đã đặt"
      className="modal-add-expense"
      centered
      width={1400}
      open={isOpen}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Đóng
        </Button>
      ]}
      onCancel={onCancel}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      confirmLoading={isLoading}
      destroyOnClose
    >
      <Table
        loading={isLoading}
        rowKey="id"
        columns={columnCustomerBookings}
        dataSource={bookingData}
        bordered
        locale={{
          emptyText: <span className="empty-data">Không có dữ liệu</span>
        }}
        pagination={false}
        scroll={{ x: 2000, y: 'calc(100vh - 400px)' }}
      ></Table>
    </Modal>
  );
}

export default CustomersHistoryBookings;
