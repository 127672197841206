import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import classNames from 'classnames';
import { IdcardOutlined } from '@ant-design/icons';

import { SubHKRoom } from 'services/api/type/hk.type';
import { ROOM_STATUS } from 'constants/form';
import { CLEANING_STATUS, MINIBAR_STATUS } from 'constants/common';

import { ReactComponent as IconDirty } from 'assets/images/ic_house_keeping_dirty_no_fill.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { ReactComponent as IconWine } from 'assets/images/wine.svg';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';

interface Props {
  data: SubHKRoom;
  onShowInspectCleaning?: () => void;
  onFillMinibar?: () => void;
}

function SubHKItem({ data, onShowInspectCleaning, onFillMinibar }: Props) {
  const handleInspect = () => {
    onShowInspectCleaning && onShowInspectCleaning();
  };

  const handleFillMinibar = () => {
    onFillMinibar && onFillMinibar();
  };

  const getTextMinibar = () => {
    return data.minibar_check?.map(item => `${item.check_qty} ${item.product_name}`).join(', ');
  };

  const isShowCleaning = [CLEANING_STATUS.APPROVE, CLEANING_STATUS.WAIT_FOR_APPROVAL].includes(
    data.cleaning_status
  );
  const isShowMinibar = [MINIBAR_STATUS.APPROVE, MINIBAR_STATUS.CHECKED].includes(
    data.minibar_status
  );
  return (
    <div
      className={classNames('pms-sub-hk-item', {
        'pms-sub-hk-item--locked': data.room_status === ROOM_STATUS.LOCKED.toLowerCase()
      })}
    >
      <div className="pms-sub-hk-item__head">
        <div className="flex items-center" style={{ gap: 4 }}>
          <span className="pms-sub-hk-item__room">
            {data.room_type_name} {data.attributes.room_no}
          </span>
          <div className="flex items-center" style={{ gap: 8 }}>
            {data.is_clean === false && (
              <div className="pms-sub-hk-item__status dirty">
                <IconDirty style={{ width: 20, height: 20 }} />
              </div>
            )}
            {data.room_status === ROOM_STATUS.LOCKED.toLowerCase() && (
              <div className="pms-sub-hk-item__status locked">
                <IconFix />
              </div>
            )}
            {data.room_status === 'booked' && (
              <div className="pms-sub-hk-item__status checkin">
                <IconBedUser style={{ width: 20, height: 20 }} /> ({data.booked_status})
              </div>
            )}
          </div>
        </div>
        <span
          className={classNames('pms-sub-hk-item__partner', {
            'pms-sub-hk-item__partner--empty': _.isEmpty(data.hk_assigned)
          })}
        >
          <IdcardOutlined />
          <p className="truncate">
            {data.hk_assigned?.name || <span style={{ color: '#42a046' }}>Chưa phân ca</span>}
          </p>
        </span>
      </div>

      {!_.isEmpty(data.minibar_check) && (
        <div className="pms-sub-hk-item__body">
          <div className="flex items-center" style={{ gap: 8 }}>
            <div className="pms-sub-hk-item__status fill">
              <IconWine style={{ width: 24, height: 24 }} />
            </div>
            <span className="pms-sub-hk-item__status-minibar">({getTextMinibar()})</span>
          </div>
        </div>
      )}

      {(isShowCleaning || isShowMinibar) && (
        <div className="pms-sub-hk-item_footer">
          {isShowCleaning && (
            <Button
              className="ant-btn-secondary-mobile"
              onClick={handleInspect}
              disabled={data.cleaning_status === CLEANING_STATUS.APPROVE}
            >
              {data.cleaning_status === CLEANING_STATUS.APPROVE ? 'Đã kiểm duyệt' : 'Kiểm duyệt'}
            </Button>
          )}
          {isShowMinibar && (
            <Button
              className="ant-btn-fill-bar"
              onClick={handleFillMinibar}
              disabled={data.minibar_status === MINIBAR_STATUS.APPROVE}
            >
              {data.minibar_status === MINIBAR_STATUS.APPROVE ? 'Đã Fill Minibar' : 'Fill Minibar'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default SubHKItem;
