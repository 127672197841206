import { useQuery } from '@tanstack/react-query';

import API from 'services/api/index';
import useCommonStore from 'stores/useCommon';
import QUERY_KEYS from 'services/api/queryKeys';
import { useEffect } from 'react';
import { useGetMe } from 'hooks/useGetMe';

export const useGetCurrentBranch = () => {
  const { data: user } = useGetMe();
  const { setCurrentBranch } = useCommonStore();
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CURRENT_BRANCH],
    queryFn: API.branches.getCurrentBranch,
    enabled: !!user
  });

  useEffect(() => {
    setCurrentBranch(data);
  }, [data, setCurrentBranch]);

  return {
    data,
    isLoading
  };
};

export const useEnableV2 = () => {
  const { data } = useGetCurrentBranch();

  return {
    enable: data?.is_enable_v2
  };
};
