import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { PDFViewer } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Button, Form, FormProps, Input, Modal } from 'antd';

import BillGroupPDF from '../bill-group-pdf';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import useCommonStore from 'stores/useCommon';
import { useGetAllBookingLineByBookingId } from 'hooks/useGetBooking';

import { toLocalTime } from 'utils';

const today = dayjs();

export enum CHARGE_TYPE {
  ALL = 'all',
  ROOM = 'room',
  SERVICE = 'service'
}

const SERVICE_FEE = 0.05;

function BillGroupPDFModal() {
  const [form] = Form.useForm();
  const { isOpenViewPDFGroup, setIsOpenViewPDFGroup, setIsOpenSignature } = useModal();
  const { bookingLineDetail } = useBookingStore();
  const { currentBranch } = useCommonStore();
  const { data: bookingLines } = useGetAllBookingLineByBookingId(
    bookingLineDetail?.booking_id,
    !_.isEmpty(bookingLineDetail?.group)
  );

  const [dataChange, setDataChange] = useState<any>({
    // chargeType: CHARGE_TYPE.ALL,
    // createdDate: today,
    partnerName: bookingLineDetail?.partner_name,
    company: ''
    // companyAddress: "",
    // taxCode: "",
  });
  const [bookingLineItems, setBookingLineItems] = useState<any[]>([]);
  const [totalFinalService, setTotalFinalService] = useState<number>(0);
  const [totalFinalServiceFee, setTotalFinalServiceFee] = useState<number>(0);
  const [totalFinalTax, setTotalFinalTax] = useState<number>(0);

  const [signatureStaff, setSignatureStaff] = useState('');
  const [signatureGuest, setSignatureGuest] = useState('');

  useEffect(() => {
    if (isOpenViewPDFGroup) {
      form.resetFields();
      const initData = {
        chargeType: CHARGE_TYPE.ALL,
        createdDate: today,
        partnerName: bookingLineDetail?.partner_name,
        company: '',
        companyAddress: ''
      };
      form.setFieldsValue(initData);
      setDataChange(initData);
      setSignatureStaff('');
      setSignatureGuest('');
    }
  }, [form, bookingLineDetail, isOpenViewPDFGroup]);

  useEffect(() => {
    let totalFinalService = 0;
    let totalFinalServiceFee = 0;
    let totalFinalTax = 0;

    const result = bookingLines.map(item => {
      const totalService =
        item.services?.reduce((cur, next) => {
          return cur + next.total_price;
        }, 0) || 0;
      totalFinalService += totalService;

      const taxBooking = (item?.tax || 0) / 100;

      const subAmount = Math.ceil(item.subtotal_price / (1 + SERVICE_FEE + taxBooking));
      const serviceFee = Math.ceil(subAmount * SERVICE_FEE);
      totalFinalServiceFee += serviceFee;
      const tax = Math.ceil(subAmount * taxBooking);
      totalFinalTax += tax;

      return {
        ...item,
        check_in: toLocalTime(item.check_in, 'DD/MM HH:mm'),
        check_out: toLocalTime(item.check_out, 'DD/MM HH:mm'),
        nlte: `${item.adult}/${item.child}`,
        totalService,
        subAmount,
        serviceFee,
        tax
      };
    });

    setTotalFinalService(totalFinalService);
    setTotalFinalServiceFee(totalFinalServiceFee);
    setTotalFinalTax(totalFinalTax);
    setBookingLineItems(result);
  }, [bookingLines]);

  const handleCancel = () => {
    setIsOpenViewPDFGroup(false);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    setDataChange(values);
  };

  const handleOpenSignatureStaff = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký nhân viên',
      onOk: (data: any) => {
        setSignatureStaff(data);
      }
    });
  };

  const handleOpenSignatureGuest = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký của khách',
      onOk: (data: any) => {
        setSignatureGuest(data);
      }
    });
  };

  const totalPayment = useMemo(() => {
    return bookingLines.reduce((cur, next) => {
      return cur + next.paid_amount;
    }, 0);
  }, [bookingLines]);

  const totalSubAmount = useMemo(() => {
    return bookingLines.reduce((cur, next) => {
      const taxBooking = (next?.tax || 0) / 100;
      const subAmount = Math.ceil(next.subtotal_price / (1 + SERVICE_FEE + taxBooking));

      return cur + subAmount;
    }, 0);
  }, [bookingLines]);

  const totalPrice = useMemo(() => {
    return bookingLines.reduce((cur, next) => {
      return cur + next.total_price;
    }, 0);
  }, [bookingLines]);

  const totalNLTE = useMemo(() => {
    const totalAdult = bookingLines.reduce((cur, next) => {
      return cur + next.adult;
    }, 0);
    const totalChild = bookingLines.reduce((cur, next) => {
      return cur + next.child;
    }, 0);
    return `${totalAdult}/${totalChild}`;
  }, [bookingLines]);

  return (
    <Modal
      title="Xem trước khi in"
      className="modal-view-bill-pdf"
      centered
      width={1200}
      open={isOpenViewPDFGroup}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <PDFViewer width="1150" height="650" className="bill-pdf-file">
        <BillGroupPDF
          bookingLineDetail={bookingLineDetail}
          totalPayment={totalPayment}
          totalSubAmount={totalSubAmount}
          totalPrice={totalPrice}
          totalNLTE={totalNLTE}
          totalFinalService={totalFinalService}
          totalFinalServiceFee={totalFinalServiceFee}
          totalFinalTax={totalFinalTax}
          bookingLineItems={bookingLineItems}
          dataChange={dataChange}
          signatureStaff={signatureStaff}
          signatureGuest={signatureGuest}
          currentBranch={currentBranch}
        />
      </PDFViewer>
      <Form
        form={form}
        name="bill-pdf-form"
        layout="vertical"
        style={{ width: '250px' }}
        initialValues={{
          // chargeType: CHARGE_TYPE.ALL,
          // createdDate: today,
          partnerName: bookingLineDetail?.partner_name,
          company: ''
          // companyAddress: "",
          // taxCode: "",
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* <Form.Item name="chargeType" label="Thanh toán cho">
          <Radio.Group>
            <Radio value={CHARGE_TYPE.ALL}>Tất cả</Radio>
            <Radio value={CHARGE_TYPE.ROOM}>Tiền phòng</Radio>
            <Radio value={CHARGE_TYPE.SERVICE}>Tiền dịch vụ</Radio>
          </Radio.Group>
        </Form.Item> */}

        {/* <Form.Item name="createdDate" label="Ngày tạo">
          <DatePicker format="DD/MM/YYYY" className="w-full" />
        </Form.Item> */}
        <Form.Item name="partnerName" label="Tên khách hàng">
          <Input />
        </Form.Item>
        <Form.Item name="company" label="Công ty">
          <Input />
        </Form.Item>
        <Form.Item name="tax_code" label="Mã số thuế">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Địa chỉ">
          <Input />
        </Form.Item>
        {/* <Form.Item name="companyAddress" label="Địa chỉ">
          <Input />
        </Form.Item>
        <Form.Item name="taxCode" label="Tax Code">
          <Input />
        </Form.Item> */}

        <Button htmlType="submit" className="ant-btn-secondary w-full">
          Lưu thay đổi
        </Button>

        <div className="flex" style={{ flexDirection: 'column', gap: '8px', marginTop: 16 }}>
          <Button onClick={handleOpenSignatureStaff}>Chữ ký nhân viên</Button>
          <Button onClick={handleOpenSignatureGuest}>Chữ ký của khách</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default BillGroupPDFModal;
