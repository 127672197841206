import React, { useEffect } from 'react';
import { Form, FormProps, Input, Modal, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

import useModal from 'stores/useModal';
import { MESSAGE_CODE } from 'constants/validate';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import useBookingStore from 'stores/useBooking';
import API from 'services/api';

function ConfirmDeleteExpenseTransactionModal() {
  const [form] = Form.useForm();
  const {
    isOpenConfirmDeleteExpenseTransaction,
    transactionId,
    setIsOpenConfirmDeleteExpenseTransaction
  } = useModal();
  const { bookingLineId } = useBookingStore();

  const { mutateAsync: mutateCancelExpenseCashTransaction, isPending } = useMutation({
    mutationFn: (params: { transaction_id: number; reason: string }) =>
      API.booking.cancelExpenseCashTransactionV2(params.transaction_id, params.reason)
  });

  useEffect(() => {
    if (!isOpenConfirmDeleteExpenseTransaction) {
      form.resetFields();
    }
  }, [form, isOpenConfirmDeleteExpenseTransaction]);

  const handleCancel = () => {
    setIsOpenConfirmDeleteExpenseTransaction(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (transactionId) {
      try {
        await mutateCancelExpenseCashTransaction({
          transaction_id: transactionId,
          reason: values?.reason
        });
        setIsOpenConfirmDeleteExpenseTransaction(false);
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLineId]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_TRANSACTIONS_CASH_FLOW]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_TRANSACTIONS_CASH_FLOW_ALL]
        });
        notification.success({
          message: 'Hủy thanh toán thành công'
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Xác nhận xóa thanh toán"
      className="modal-confirm-delete-payment"
      centered
      width={400}
      open={isOpenConfirmDeleteExpenseTransaction}
      okText="Xác nhận"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="confirm-delete-payment-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          reason: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_REASON_FIX_ROOM
            }
          ]}
        >
          <Input.TextArea rows={5} placeholder="Lý do (bắt buộc)" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ConfirmDeleteExpenseTransactionModal;
