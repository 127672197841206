import { InputNumber } from 'antd';

interface Props {
  value: any;
  onValueChange: (value: any) => void;
}

const NumberEditor = ({ value, onValueChange }: Props) => {
  const handleChangeValue = (value: any) => {
    onValueChange(value === '' ? null : String(value));
  };

  return (
    <InputNumber
      autoFocus
      defaultValue={value}
      className="number-editor w-full h-full"
      style={{ border: 0, boxShadow: 'none' }}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      onChange={handleChangeValue}
    />
  );
};

export default NumberEditor;
