import { ThemeConfig } from 'antd';

export const ANTD_CONFIG: ThemeConfig = {
  hashed: false,
  token: {
    colorPrimary: '#2E3E54',
    colorTextSecondary: '#9C4F3B',
    fontFamily: `Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif`
  },
  components: {
    Button: {
      borderRadius: 2,
      borderRadiusLG: 2
    },
    Input: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    InputNumber: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Card: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Select: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    DatePicker: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Modal: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Menu: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Table: {
      borderRadius: 1,
      borderRadiusLG: 1
    },
    Checkbox: {
      borderRadius: 2,
      borderRadiusLG: 2
    }
  }
};
