import React, { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, Input, Button, Tabs } from 'antd';
import { generateExportFileName, getEarliestValidDate, toUtcTime } from 'utils';
import { FormProps } from 'rc-field-form';
import _ from 'lodash';
import 'styles/consolidate-revenue.scss';
import { ExportConsolidateRevenue } from 'components/common/export-consolidate-revenue';
import RoomTypeTab from 'components/modal/consolidate-components/room-type-consolidate-revenue';
import SourceTab from 'components/modal/consolidate-components/source-consolidate-revenue';
import NationalityTab from 'components/modal/consolidate-components/nationality-consolidate-revenue';

const INITIAL_RANGE_DATE = 14;
const initStartDate = dayjs().startOf('day');

function ConsolidateRevenue() {
  const [form] = Form.useForm();
  const [keyTab, setKeyTab] = useState<string>('room_type');
  const [dataSource, setDataSource] = useState<any[]>();

  const onTabClick = (key: string) => {
    setKeyTab(key);
  };

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate.add(1, 'day'), 'YYYY-MM-DD'),
    period: INITIAL_RANGE_DATE
  });

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, period, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        const dayCount = endDate.diff(startDate, 'day');
        _.set(restFilterOptions, 'period', dayCount);
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));
      }
    }

    _.set(restFilterOptions, 'period', period || INITIAL_RANGE_DATE);

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day');
      form.setFieldsValue({ period: dayCount });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period, 'day');
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
    }
  };

  const fromDate = getEarliestValidDate(filterOptions.start_date);

  const toDate = filterOptions.start_date
    ? dayjs(filterOptions.start_date)
        .add(filterOptions.period, 'day')
        .subtract(1, 'day')
        .format('DD-MM-YYYY')
    : undefined;

  const handleSetDataSource = useCallback((data: any[]) => {
    setDataSource(data);
  }, []);

  return (
    <div className="pms-consolidate-revenue">
      <div className="pms-consolidate-revenue__timetable">
        <Form
          form={form}
          initialValues={{
            rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
            period: INITIAL_RANGE_DATE
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-consolidate-revenue__filter">
            <div className="pms-consolidate-revenue__filter__left">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format="DD-MM-YYYY"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="period">
                <Input placeholder="Số ngày" onChange={handlePeriodChange} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </div>
            <ExportConsolidateRevenue
              fileName={generateExportFileName('consolidate-revenue')}
              fromDate={fromDate}
              toDate={toDate}
              dataToExport={dataSource}
              typeOfResponse={keyTab}
            />
          </div>
        </Form>
        <div className="pms-consolidate-revenue__table">
          <Tabs type="card" activeKey={keyTab} onTabClick={onTabClick} defaultActiveKey="1">
            <Tabs.TabPane tab="Theo loại phòng" key="room_type">
              <RoomTypeTab
                filterOptions={filterOptions}
                isEnable={keyTab == 'room_type'}
                type_of_response={keyTab}
                setDataSource={handleSetDataSource}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Theo nguồn" key="source">
              <SourceTab
                filterOptions={filterOptions}
                isEnable={keyTab == 'source'}
                type_of_response={keyTab}
                setDataSource={handleSetDataSource}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Theo khu vực" key="nationality">
              <NationalityTab
                filterOptions={filterOptions}
                isEnable={keyTab == 'nationality'}
                type_of_response={keyTab}
                setDataSource={handleSetDataSource}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default ConsolidateRevenue;
