import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { PaginationType } from 'services/api/type/booking.type';
import { PAGE_SIZE_STAYING_GUESTS } from 'services/api/module/report.api';

interface FilterType {
  start_date: string;
  end_date: string;
  status: string;
  country: string;
}

export const useGetStayingGuests = (
  filterOptions: FilterType,
  page: number,
  pageSize: number = PAGE_SIZE_STAYING_GUESTS
) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_STAYING_GUESTS, page, pageSize],
    queryFn: () =>
      API.report.getStayingGuests({
        ...filterOptions,
        page,
        limit: pageSize
      })
  });

  return {
    data: _.get(data, 'data', []),
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    status,
    isLoading,
    isFetching
  };
};
