import createStorage from './createStorage';

const instance = createStorage('kin-pms');
const KEY_URL = 'url';
const KEY_TOKEN = 'token';
const KEY_BRANCH = 'branch';

export const setURL = (data: any) => instance.setValue(KEY_URL, data);

export const getValueUser = (key: string) => instance.getValue(key);

export const getURL = () => instance.getValue(KEY_URL);

export const setToken = (data: any) => instance.setValue(KEY_TOKEN, data);

export const getToken = () => instance.getValue(KEY_TOKEN);

export const clearToken = () => instance.clear();

export const setBranch = (data: any) => instance.setValue(KEY_BRANCH, data);

export const getBranch = () => instance.getValue(KEY_BRANCH);
