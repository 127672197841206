import { create } from 'zustand';

export interface CashierType {
  selectedReservationId: string;
  setSelectedReservationId: (id: string) => void;
  selectedGroupId: string;
  setSelectedGroupId: (id: string) => void;
  folioBalanceCode: string;
  setFolioBalanceCode: (folioBalanceCode: string) => void;

  selectedTransactionId: number | undefined;
  setSelectedTransactionId: (selectedTransactionId: number | undefined) => void;
}

const useCashierStore = create<CashierType>(set => ({
  selectedReservationId: '',
  setSelectedReservationId: (id: string) => set(() => ({ selectedReservationId: id })),

  selectedGroupId: '',
  setSelectedGroupId: (id: string) => set(() => ({ selectedGroupId: id })),

  folioBalanceCode: '',
  setFolioBalanceCode: (folioBalanceCode: string) => set(() => ({ folioBalanceCode })),

  selectedTransactionId: undefined,
  setSelectedTransactionId: (selectedTransactionId: number | undefined) =>
    set(() => ({ selectedTransactionId }))
}));

export default useCashierStore;
