import React, { ReactNode, useEffect } from 'react';
import { Navigate, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import { useGetMe } from 'hooks/useGetMe';

import PAGES, { PATTERN_URL } from './constants';
import { getToken } from 'services/storages/userStorage';
import { JobCode } from 'services/api/type/user.type';

interface Props {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useGetMe();
  const token = getToken();

  useEffect(() => {
    if (!isMobileOnly) {
      return;
    }

    if (data) {
      if (data.job_code === JobCode.HK && !matchPath(PATTERN_URL.HK, location.pathname)) {
        navigate(PAGES.hk);
      } else if (
        data.job_code === JobCode.SUB_HK &&
        !matchPath(PATTERN_URL.SUB_HK, location.pathname)
      ) {
        navigate(PAGES.subHK);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location.pathname]);

  if (!token) {
    return <Navigate to={PAGES.login} />;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
