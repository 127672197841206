import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { LoginType, MyInfoType, ParamsChangePassword } from '../type/user.type';

export const login = (body: LoginType) => {
  const payload = {
    email: body.email,
    password: body.password
  };
  return axiosInstance.post(paths.login(), payload);
};

export const getMe = (): Promise<MyInfoType> => {
  return axiosInstance.get(paths.me()).then(item => item.data.data);
};

export const changePassword = (params: ParamsChangePassword): Promise<any> => {
  return axiosInstance.post(paths.changePassword(), params).then(item => item.data.data);
};
