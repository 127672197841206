import * as user from './module/user.api';
import * as room from './module/room.api';
import * as payment from './module/payment.api';
import * as common from './module/common.api';
import * as customer from './module/customer.api';
import * as booking from './module/booking.api';
import * as report from './module/report.api';
import * as nightAudit from './module/night-audit.api';
import * as logs from './module/tracking-logs.api';
import * as breakfasts from './module/breakfasts.api';
import * as hk from './module/hk.api';
import * as branches from './module/branches.api';
import * as labels from './module/labels.api';
import * as ocr from './module/ocr-customer-info.api';
import * as warehouse from './module/warehouse-management.api';
import * as ta from './module/travel-agency.api';
import * as group from './module/group.api';

const apis = {
  user,
  room,
  payment,
  common,
  customer,
  booking,
  report,
  nightAudit,
  logs,
  breakfasts,
  hk,
  branches,
  labels,
  ocr,
  warehouse,
  ta,
  group
};

export default apis;
