import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Form, FormProps, Input, Modal, notification } from 'antd';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { cancelTransactions } from 'services/api/module/booking.api';
import { ParamsCancelTransaction } from 'services/api/type/booking.type';
import useModal from 'stores/useModal';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';

function CancelTransactions() {
  const [form] = Form.useForm();

  const {
    cancelTransactions: { transaction_ids, isOpenCancelTransactions },
    closeCancelTransaction
  } = useModal();
  const { mutateAsync: mutateCancelTransactions, isPending } = useMutation({
    mutationFn: ({ transaction_ids, note }: { transaction_ids: number[]; note: string }) =>
      cancelTransactions({ transaction_ids, note })
  });

  const handleCloseCancelTransactions = () => {
    closeCancelTransaction();
    form.resetFields();
  };

  const handleCancelTransactions = async () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: ParamsCancelTransaction) => {
    try {
      await mutateCancelTransactions({
        transaction_ids: transaction_ids,
        note: values.note
      });
      closeCancelTransaction();
      form.resetFields();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      notification.success({
        message: 'Hủy giao dịch thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title="Xác nhận hủy giao dịch"
      centered
      width={410}
      open={isOpenCancelTransactions}
      onCancel={handleCloseCancelTransactions}
      closeIcon={<IconClose />}
      okText="Hủy giao dịch"
      cancelText="Đóng"
      onOk={handleCancelTransactions}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-cancel-transactions"
      destroyOnClose
      confirmLoading={isPending}
    >
      <Form
        form={form}
        name="cancel-transactions-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          note: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="note"
          rules={[
            {
              required: true,
              message: 'Hãy nhập lý do'
            }
          ]}
        >
          <Input.TextArea placeholder="Lý do (bắt buộc)" rows={6}></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CancelTransactions;
