import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { WarehouseMagamentType } from 'services/api/type/warehouse-management.type';

export const useGetAllWarehouseProducts = () => {
  const { data, isLoading } = useQuery<WarehouseMagamentType>({
    queryKey: [QUERY_KEYS.GET_ALL_WAREHOUSE_PRODUCTS],
    queryFn: () => API.warehouse.getAllWarehouseProducts()
  });

  return {
    data: data?.stocks,
    isLoading
  };
};
