import { useEffect, useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import { PAYMENT_METHOD_NAMES } from 'constants/form';
import { MAP_PAYMENT_TYPE } from 'constants/text';
import { formatCurrency, getEarliestValidDate, toLocalTime } from 'utils';
import useCommonStore from 'stores/useCommon';
import { useGetAllPayments, useGetTransactionsPayment } from 'hooks/useGetPayments';

interface Props {
  fileName: string;
  selectedPaymentState?: string;
  selectedPaymentMethod?: string;
  englishType?: boolean;
  filterOptions: any;
}

const ExportTransactions = ({
  fileName,
  selectedPaymentState,
  selectedPaymentMethod,
  englishType,
  filterOptions
}: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [enableV1, setEnableV1] = useState<boolean>(false);
  const [enableV2, setEnableV2] = useState<boolean>(false);

  const { data: dataToExportV1 } = useGetAllPayments(filterOptions, 0, 0, enableV1);
  const { data: dataToExportV2 } = useGetTransactionsPayment(filterOptions, 0, 0, enableV2);

  const { currentBranch } = useCommonStore();

  useEffect(() => {
    if (isLoadingExport) {
      setEnableV1(false);
      setEnableV2(false);
    } else {
      setEnableV1(!englishType);
      setEnableV2(!!englishType);
    }
  }, [isLoadingExport, englishType]);

  const dataToExport = useMemo(() => {
    return (englishType ? dataToExportV2 || [] : dataToExportV1 || []).filter(
      item => item.state !== 'cancelled'
    );
  }, [englishType, dataToExportV1, dataToExportV2]);

  const fromDate = getEarliestValidDate(filterOptions.start_date);
  const toDate = getEarliestValidDate(filterOptions.end_date);
  const totals = useMemo(() => {
    const totalAmount = dataToExport?.reduce((sum, item) => sum + (item.amount || 0), 0);
    const totalCustomers = dataToExport?.reduce(
      (sum, item) => sum + (item.customer_name ? 1 : 0),
      0
    );
    return { totalAmount, totalCustomers };
  }, [dataToExport]);

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = englishType ? `Transactions` : `Giao dịch`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = englishType ? 'TRANSACTION REPORT' : 'BÁO CÁO GIAO DỊCH';
      sheet.getCell('A2').font = { size: 11, bold: true };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      sheet.getCell('A4').value = englishType ? `From Date` : `Ngày`;
      sheet.getCell('A4').font = { bold: true };
      sheet.getCell('B4').value = fromDate;
      sheet.getCell('C4').value = englishType ? `To Date` : `Đến`;
      sheet.getCell('C4').font = { bold: true };
      sheet.getCell('D4').value = toDate;
      sheet.getCell('E4').value = englishType ? `Status` : `Trạng thái`;
      sheet.getCell('E4').font = { bold: true };
      sheet.getCell('F4').value = selectedPaymentState || (englishType ? 'All' : 'Tất cả');
      sheet.getCell('G4').value = englishType ? `Payment Method` : `Phương thức thanh toán`;
      sheet.getCell('G4').font = { bold: true };
      sheet.getCell('H4').value = selectedPaymentMethod || (englishType ? 'All' : 'Tất cả');

      // Header row
      sheet.getRow(5).values = englishType
        ? [
            'ID',
            'Booking Code',
            'Transaction Date',
            'Note',
            'Amount',
            'Category',
            'Payment Method',
            'Creator',
            'Customer Name',
            'Description'
          ]
        : [
            'ID',
            'Mã đặt phòng',
            'Ngày giao dịch',
            'Ghi chú',
            'Số tiền',
            'Mục',
            'Phương thức thanh toán',
            'Người tạo',
            'Tên khách',
            'Mô tả'
          ];
      sheet.getRow(5).eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'left' };
      });

      sheet.columns = [
        { key: 'id', width: 15 },
        { key: 'booking_id', width: 16 },
        { key: 'payment_date', width: 20 },
        { key: 'note', width: 30 },
        { key: 'amount', width: 20 },
        { key: 'payment_type', width: 15 },
        { key: 'payment_method', width: 25 },
        { key: 'user_name', width: 20 },
        { key: 'customer_name', width: 25 },
        { key: 'description', width: 90 }
      ];

      dataToExport.forEach(item => {
        const row = sheet.addRow({
          id: item.id,
          booking_id: item.booking_id ? item.booking_id : '',
          payment_date: toLocalTime(item.payment_date, 'DD/MM/YYYY HH:mm'),
          note: item.note ? item.note : '',
          amount: formatCurrency(item.amount),
          payment_type: englishType ? item.payment_type : MAP_PAYMENT_TYPE[item.payment_type],
          payment_method: englishType
            ? item.payment_method
            : PAYMENT_METHOD_NAMES[item.payment_method],
          user_name: item.user_name,
          customer_name: item.customer_name,
          description: item.description
        });

        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.alignment = { horizontal: 'left' };
        });
      });

      const totalRowIndex = dataToExport.length + 6;

      sheet.mergeCells(`A${totalRowIndex}:D${totalRowIndex}`);
      sheet.getCell(`A${totalRowIndex}`).value = englishType ? 'Total' : 'Tổng Cộng';
      sheet.getCell(`A${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`A${totalRowIndex}`).alignment = { horizontal: 'center' };

      sheet.getCell(`E${totalRowIndex}`).value = formatCurrency(totals.totalAmount);
      sheet.getCell(`E${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`E${totalRowIndex}`).alignment = { horizontal: 'center' };

      sheet.getCell(`I${totalRowIndex}`).value = totals.totalCustomers;
      sheet.getCell(`I${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`I${totalRowIndex}`).alignment = { horizontal: 'center' };

      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        {englishType ? 'Export Data' : 'Xuất dữ liệu'}
      </Button>
    </Dropdown>
  );
};

export default ExportTransactions;
