import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props {
  value: any;
  onValueChange: (value: any) => void;
}

const CheckboxEditor = ({ value, onValueChange }: Props) => {
  const handleChangeValue = (e: CheckboxChangeEvent) => {
    onValueChange(e.target.checked);
  };

  return (
    <Checkbox defaultChecked={value} className="checkbox-editor" onChange={handleChangeValue} />
  );
};

export default CheckboxEditor;
