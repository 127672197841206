import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import {
  CleaningItem,
  ParamsFixRoom,
  ParamsGetRoomLock,
  ParamsUnlockRoom,
  PersonalInfoType,
  RoomDetail,
  RoomDetailStatus,
  RoomLockType,
  RoomType
} from '../type/room.type';

export const getRooms = (filterOptions: any): Promise<RoomDetail[]> => {
  return axiosInstance.get(paths.getRooms(), {
    params: filterOptions
  });
};

export const PAGE_SIZE_CUSTOMERS = 15;
export const getCustomers = async (filterOptions: any): Promise<PersonalInfoType[]> => {
  return axiosInstance
    .get(paths.getCustomers(), {
      params: filterOptions
    })
    .then(item => item.data);
};

export const getRoomStatus = async (
  start_date: string,
  end_date: string
): Promise<RoomDetailStatus[]> => {
  return axiosInstance
    .get(paths.getRoomStatus(), {
      params: {
        start_date,
        end_date
      }
    })
    .then(item => item.data.data);
};

export const cleanRoom = async (ids: number[], is_clean = false): Promise<RoomType[]> => {
  return axiosInstance.post(paths.cleanRoom(), {
    ids,
    is_clean
  });
};

export const assignRoom = async (params: {
  booking_line_id: number;
  room_id: number;
  is_change_rate?: boolean;
  pricelist_id?: number;
}): Promise<void> => {
  return axiosInstance.post(paths.assignRoom(), params);
};

export const changeRoomType = async (params: {
  booking_line_id: number;
  room_type_id: number;
}): Promise<void> => {
  return axiosInstance.post(paths.changeRoomType(), params);
};

export const exchangeRoom = async (params: {
  booking_line_id: number;
  room_id: number;
  note: string;
  is_change_rate?: boolean;
  pricelist_id?: number;
  exchange_fee?: number;
}): Promise<void> => {
  return axiosInstance.post(paths.exchangeRoom(), params);
};

export const unAssignRoom = async (params: { booking_line_id: number }): Promise<void> => {
  return axiosInstance.post(paths.unassignRoom(), params);
};

export const getCleaningCalendar = async (
  start_date: string,
  period: number
): Promise<CleaningItem[]> => {
  return axiosInstance
    .get(paths.getCleaningCalendar(), {
      params: {
        start_date,
        period
      }
    })
    .then(item => item.data.data);
};

export const fixRoom = async (params: ParamsFixRoom): Promise<void> => {
  return axiosInstance.post(paths.fixRoom(), params);
};

export const unlockRoom = async (params: ParamsUnlockRoom): Promise<void> => {
  return axiosInstance.post(paths.unlockRoom(), params);
};

export const PAGE_SIZE_ROOM_LOCKS = 15;
export const getRoomLock = async (params: ParamsGetRoomLock): Promise<RoomLockType[]> => {
  return axiosInstance
    .get(paths.getRoomLock(), {
      params
    })
    .then(item => item.data);
};
