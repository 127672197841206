import { columnRoomsFocTab } from 'constants/table';
import CustomTable from '../room-available-components/CustomTable';
import { useGetRoomsFoc } from 'hooks/useGetFocs';
import { GetFocsType } from 'services/api/type/report.type';

interface RoomsFocProps {
  isEnable: boolean;
  filterOptions: GetFocsType;
}

export default function RoomsFocTab({ isEnable, filterOptions }: RoomsFocProps) {
  const { data: roomsFoc, isLoading } = useGetRoomsFoc(filterOptions, isEnable);

  const totalRoomQty = roomsFoc?.reduce((acc: any, room: any) => acc + room.room_qty, 0) || 0;

  const dataWithTotal = [
    ...roomsFoc,
    {
      booking_id: 'TOTAL',
      room_qty: totalRoomQty,
      partner_name: '',
      room_type: '',
      date_of_foc: ''
    }
  ];

  return (
    <CustomTable
      rowKey="id"
      loading={isLoading}
      columns={columnRoomsFocTab}
      dataSource={dataWithTotal}
    />
  );
}
