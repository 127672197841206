import { columnTransactions } from 'constants/table';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetAllPayments } from 'hooks/useGetPayments';
import { PAGE_SIZE_TRANSACTION } from 'services/api/module/report.api';
import { PAYMENT_METHOD_FILTER_OPTIONS, PAYMENT_STATE_OPTIONS } from 'constants/form';

interface TransactionV1Props {
  filterOptions: any;
  setSelectedPaymentState: (state: string | undefined) => void;
  setSelectedPaymentMethod: (method: string | undefined) => void;
}

export function TransactionV1(props: TransactionV1Props) {
  const { filterOptions, setSelectedPaymentState, setSelectedPaymentMethod } = props;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_TRANSACTION);

  const handleChangePage = (page: number) => {
    setPage(page);
  };
  const {
    data: dataSource,
    pagination,
    isFetching
  } = useGetAllPayments(filterOptions, page, pageSize);

  const selectedPaymentState = PAYMENT_STATE_OPTIONS.find(
    option => option.value === filterOptions.state
  );
  const selectedPaymentMethod = PAYMENT_METHOD_FILTER_OPTIONS.find(
    option => option.value === filterOptions.payment_method
  );

  useEffect(() => {
    if (selectedPaymentState) {
      setSelectedPaymentState(selectedPaymentState?.label);
    }
    if (selectedPaymentMethod) {
      setSelectedPaymentMethod(selectedPaymentMethod?.label);
    }
    // eslint-disable-next-line
  }, [dataSource, selectedPaymentMethod, selectedPaymentState]);

  return (
    <div>
      <Table
        loading={isFetching}
        rowKey="id"
        columns={columnTransactions}
        dataSource={dataSource}
        pagination={{
          current: page,
          position: ['bottomCenter'],
          total: pagination.total,
          pageSize: pageSize,
          locale: { items_per_page: '/ trang' },
          showTotal: total => `${total} items`,
          onChange: (page: number) => handleChangePage(page),
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          }
        }}
        locale={{
          emptyText: <span className="empty-data">Không có dữ liệu</span>
        }}
        scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
      />
    </div>
  );
}
