import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';
import CustomTable from './CustomTable';
import { columnSubGroupsTab } from 'constants/table';
import { useGetGroupsBookingDetails } from 'hooks/useGetGroupBookings';

interface SubGroupsProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
  onCountChange: (count: number) => void;
}

export default function SubGroupsTab({
  isEnable,
  dateToQuery,
  roomTypeToQuery,
  onCountChange
}: SubGroupsProps) {
  const { data: listGroups, isLoading } = useGetGroupsBookingDetails(
    {
      check_in: toLocalTime(getStartOfToday(dateToQuery), 'YYYY-MM-DD'),
      check_out: toLocalTime(getEndOfToday(dateToQuery), 'YYYY-MM-DD'),
      short_code: roomTypeToQuery
    },
    isEnable
  );

  onCountChange(listGroups.length);

  return (
    <CustomTable
      rowKey="ref_code"
      loading={isLoading}
      columns={columnSubGroupsTab}
      dataSource={listGroups || []}
    />
  );
}
