export enum RENT_METHOD {
  LONG_TERM = 'long-term',
  SHORT_TERM = 'short-term'
}

export const RENT_METHOD_OPTIONS = [
  { label: 'Dài hạn', value: RENT_METHOD.LONG_TERM },
  { label: 'Ngắn hạn', value: RENT_METHOD.SHORT_TERM }
];

export enum SEX {
  MALE = 'male',
  FEMALE = 'female'
}

export const GENDER_OPTIONS = [
  { label: 'Male', value: SEX.MALE },
  { label: 'Female', value: SEX.FEMALE }
];

export enum CUSTOMER_TYPE {
  PERSONAL = 'person',
  BUSINESS = 'company'
}

export enum PAYMENT_METHOD {
  CASH = 'cash',
  CREDIT = 'credit',
  BANK_TRANSFER = 'bank_transfer',
  OTA_DEBT = 'ota_debt',
  DEBT = 'debt',
  ONEPAY = 'onepay'
}

export const PAYMENT_METHOD_NAMES: {
  [key: string]: string;
} = {
  [PAYMENT_METHOD.CASH]: 'Tiền mặt',
  [PAYMENT_METHOD.CREDIT]: 'Thẻ tín dụng',
  [PAYMENT_METHOD.BANK_TRANSFER]: 'Chuyển khoản NH',
  [PAYMENT_METHOD.OTA_DEBT]: 'Qua OTA',
  [PAYMENT_METHOD.DEBT]: 'Công nợ',
  [PAYMENT_METHOD.ONEPAY]: 'Qua Onepay'
};

export enum PAYMENT_TYPE {
  INCOME = 'income',
  EXPENSE = 'expense'
}

export enum PAYMENT_STATUS {
  DRAFT = 'draft',
  POSTED = 'posted',
  CANCELLED = 'cancelled'
}

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.CASH],
    value: PAYMENT_METHOD.CASH
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.CREDIT],
    value: PAYMENT_METHOD.CREDIT
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.BANK_TRANSFER],
    value: PAYMENT_METHOD.BANK_TRANSFER
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.OTA_DEBT],
    value: PAYMENT_METHOD.OTA_DEBT
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.ONEPAY],
    value: PAYMENT_METHOD.ONEPAY
  }
];

export enum BOOKING_STATUS {
  ALLOT = 'allot',
  CHECKOUT = 'checkout',
  CONFIRM = 'initial',
  CANCEL = 'cancel'
}

export const GROUP_STATUS_NAMES: { [key in BOOKING_STATUS]: string } = {
  [BOOKING_STATUS.CONFIRM]: 'RS',
  [BOOKING_STATUS.ALLOT]: 'IH',
  [BOOKING_STATUS.CANCEL]: 'CL',
  [BOOKING_STATUS.CHECKOUT]: 'C/O'
};

export const BOOKING_STATUS_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Đã đặt', value: BOOKING_STATUS.CONFIRM },
  { label: 'Đang ở', value: BOOKING_STATUS.ALLOT },
  { label: 'Hủy', value: BOOKING_STATUS.CANCEL },
  { label: 'Đã đi', value: BOOKING_STATUS.CHECKOUT }
];

export const TITLE_STATUS_OPTION: any = {
  [BOOKING_STATUS.CONFIRM]: 'KHÁCH SẼ ĐẾN',
  [BOOKING_STATUS.ALLOT]: 'KHÁCH ĐANG Ở',
  [BOOKING_STATUS.CANCEL]: 'KHÁCH ĐÃ HỦY',
  [BOOKING_STATUS.CHECKOUT]: 'KHÁCH ĐÃ ĐI'
};

export enum CURRENCY {
  VND = 'vnd'
}

export const CURRENCY_OPTIONS = [{ label: 'VND', value: CURRENCY.VND }];

export enum PAYMENT_FOR {
  ROOM = 'room',
  SERVICE = 'service'
}
export const PAYMENT_FOR_OPTIONS = [
  { label: 'Tiền phòng', value: PAYMENT_FOR.ROOM },
  { label: 'Tiền dịch vụ', value: PAYMENT_FOR.SERVICE }
];

export const PAYMENT_FOR_NAMES: {
  [key: string]: string;
} = {
  [PAYMENT_FOR.ROOM]: 'Tiền phòng',
  [PAYMENT_FOR.SERVICE]: 'Tiền dịch vụ'
};

export enum ROOM_STATUS {
  S = 'S',
  FS = 'FS',
  ARR = 'ARR',
  DEP = 'DEP',
  LOCKED = 'LOCKED'
}

export const ROOM_STATUS_NAMES: any = {
  [ROOM_STATUS.S]: 'S',
  [ROOM_STATUS.FS]: 'FS',
  [ROOM_STATUS.ARR]: 'ARR',
  [ROOM_STATUS.DEP]: 'DEP',
  [ROOM_STATUS.LOCKED]: 'OOO'
};

export const ROOM_STATUS_OPTIONS = [
  { label: 'S', value: ROOM_STATUS.S },
  { label: 'FS', value: ROOM_STATUS.FS },
  { label: 'ARR', value: ROOM_STATUS.ARR },
  { label: 'DEP', value: ROOM_STATUS.DEP },
  { label: 'LOCKED', value: ROOM_STATUS.LOCKED }
];

export const TYPE_OF_QUARTERS = ['1', '2', '3', '4'];

export enum STATUS_STAYING_GUEST {
  ALLOT = 'allot',
  ALLOT_CHECKOUT = 'allot_checkout'
}

export const STATUS_STAYING_GUEST_OPTIONS = [
  { label: 'Đang ở', value: STATUS_STAYING_GUEST.ALLOT },
  { label: 'Đang ở và đã đi', value: STATUS_STAYING_GUEST.ALLOT_CHECKOUT }
];

export enum COUNTRY_STAYING_GUEST {
  VIETNAM = 'vietnam',
  GLOBAL = 'global'
}

export const COUNTRY_STAYING_GUEST_OPTIONS = [
  { label: 'Tất cả', value: '' },
  { label: 'Việt nam', value: COUNTRY_STAYING_GUEST.VIETNAM },
  { label: 'Quốc tế', value: COUNTRY_STAYING_GUEST.GLOBAL }
];

export const NATIONALITY_STATISTICS_OPTION: { [key: string]: string } = {
  '': 'Tất cả'
};

export const STATUS_NATIONALITY_STATISTICS_OPTIONS: { [key: string]: string } = {
  [STATUS_STAYING_GUEST.ALLOT_CHECKOUT]: 'Đang ở và đã đi'
};

export enum EXPENSE_TYPE {
  EXPENSE_FROM_ROOM = 'room',
  UTILITIES_SERVICES = 'util_service',
  ELECTRICITY = 'electricity',
  WATER = 'water',
  INTERNET = 'internet',
  TELEVISION = 'television',
  PHONE = 'phone',
  MAINTENANCE = 'maintainance',
  ALLOWANCE = 'allowance',
  SALARY = 'salary',
  BONUS = 'bonus',
  SURCHARGE = 'surcharge',
  TAX = 'tax',
  COMMISSION = 'commission',
  ADVERTISE = 'advertise',
  PURCHASING = 'purchasing',
  SUPPLIER = 'supplier',
  FURNITURE = 'furniture_procurement',
  REVENUE = 'revenue_submission',
  OTHER = 'other'
}

export const EXPENSE_TYPE_OPTIONS = [
  { label: 'Chi từ phòng', value: EXPENSE_TYPE.EXPENSE_FROM_ROOM },
  { label: 'Dịch vụ sinh hoạt', value: EXPENSE_TYPE.UTILITIES_SERVICES },
  { label: 'Điện', value: EXPENSE_TYPE.ELECTRICITY },
  { label: 'Nước', value: EXPENSE_TYPE.WATER },
  { label: 'Internet', value: EXPENSE_TYPE.INTERNET },
  { label: 'Truyền hình cáp', value: EXPENSE_TYPE.TELEVISION },
  { label: 'Điện thoại', value: EXPENSE_TYPE.PHONE },
  { label: 'Sửa chữa', value: EXPENSE_TYPE.MAINTENANCE },
  { label: 'Phụ cấp', value: EXPENSE_TYPE.ALLOWANCE },
  { label: 'Lương', value: EXPENSE_TYPE.SALARY },
  { label: 'Phụ phí', value: EXPENSE_TYPE.SURCHARGE },
  { label: 'Thuế', value: EXPENSE_TYPE.TAX },
  { label: 'Hoa hồng', value: EXPENSE_TYPE.COMMISSION },
  { label: 'Quảng cáo', value: EXPENSE_TYPE.ADVERTISE },
  { label: 'Mua hàng', value: EXPENSE_TYPE.PURCHASING },
  { label: 'Trả nợ nhà cung cấp', value: EXPENSE_TYPE.SUPPLIER },
  { label: 'Mua sắm đồ đạc', value: EXPENSE_TYPE.FURNITURE },
  { label: 'Nộp doanh thu', value: EXPENSE_TYPE.REVENUE },
  { label: 'Khác', value: EXPENSE_TYPE.OTHER }
];

export const PAYMENT_STATE_OPTIONS = [
  {
    label: 'Tất cả',
    value: ''
  },
  {
    label: 'Chưa đăng',
    value: PAYMENT_STATUS.DRAFT
  },
  {
    label: 'Đã đăng',
    value: PAYMENT_STATUS.POSTED
  },
  {
    label: 'Đã xóa',
    value: PAYMENT_STATUS.CANCELLED
  }
];

export const TRANSACTIONS_TYPE_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Posted',
    value: PAYMENT_STATUS.POSTED
  },
  {
    label: 'Deleted',
    value: PAYMENT_STATUS.CANCELLED
  }
];

export const PAYMENT_METHOD_FILTER_OPTIONS = [
  {
    label: 'Tất cả',
    value: ''
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.CASH],
    value: PAYMENT_METHOD.CASH
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.CREDIT],
    value: PAYMENT_METHOD.CREDIT
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.BANK_TRANSFER],
    value: PAYMENT_METHOD.BANK_TRANSFER
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.OTA_DEBT],
    value: PAYMENT_METHOD.OTA_DEBT
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.ONEPAY],
    value: PAYMENT_METHOD.ONEPAY
  },
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.DEBT],
    value: PAYMENT_METHOD.DEBT
  }
];

export const SHIFT_HANDOVER_FORM = [
  {
    label: 'Đầu ca',
    name: 'openingAmount'
  },
  { label: 'Tổng thu', name: 'incomeAmount' },
  { label: 'Tổng chi', name: 'expenseAmount' },
  { label: 'Số tiền hiện tại', name: 'currentAmount' },
  { label: 'Chênh lệch với két', name: 'difference' },
  { label: 'Thực tế trong két', name: 'actualAmount' }
];

export enum VIP_LEVEL {
  NORMAL = 'normal',
  HIGH_PRIORITY = 'high_priority',
  TOP_TIER = 'top_tier'
}

export const MAP_VIP_LEVEL: Record<string, string> = {
  [VIP_LEVEL.NORMAL]: 'Normal',
  [VIP_LEVEL.HIGH_PRIORITY]: 'High Priority',
  [VIP_LEVEL.TOP_TIER]: 'Top Tier'
};

export const VIP_LEVEL_OPTIONS = [
  {
    label: 'Normal',
    value: VIP_LEVEL.NORMAL
  },
  {
    label: 'High Priority',
    value: VIP_LEVEL.HIGH_PRIORITY
  },
  {
    label: 'Top Tier',
    value: VIP_LEVEL.TOP_TIER
  }
];

export enum RENTAL_TYPE {
  ALL = 'all',
  SHORT_STAY = 'short_stay',
  LONG_STAY = 'long_stay'
}

export const RENTAL_TYPE_OPTIONS = [
  {
    label: 'All',
    value: RENTAL_TYPE.ALL
  },
  {
    label: 'Short Stay',
    value: RENTAL_TYPE.SHORT_STAY
  },
  {
    label: 'Long Stay',
    value: RENTAL_TYPE.LONG_STAY
  }
];
