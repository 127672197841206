import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { Payment, ChargeDebtPayment } from '../type/payment.type';

export const getListPayments = async (bookingLineId: number): Promise<Payment[]> => {
  return axiosInstance.get(paths.payments(bookingLineId)).then(res => res.data.data);
};

export const makePayment = async (bookingLineId: number, data: any): Promise<Payment[]> => {
  return axiosInstance.post(paths.payments(bookingLineId), data).then(res => res.data.data);
};

export const chargeDebtPayment = async (params: ChargeDebtPayment): Promise<string> => {
  return axiosInstance.post(paths.chargePaymentDebt(), params).then(res => res.data);
};
