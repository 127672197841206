import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { useMediaQuery } from 'react-responsive';

import useModal from 'stores/useModal';

function SignatureModal() {
  const sigPad = useRef<any>();
  const wrapperRef = useRef<any>();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });
  const isIpadMobileHorizontal = useMediaQuery({
    query: '(max-width: 1080px) and (max-height: 810px)'
  });
  const [responsiveWidth, setResponsiveWidth] = useState(0);
  const [responsiveHeight, setResponsiveHeight] = useState(0);

  const { isOpenSignature, setIsOpenSignature, propsSignatureModal } = useModal();

  useEffect(() => {
    if (isOpenSignature) {
      setTimeout(() => {
        setResponsiveWidth(wrapperRef?.current?.clientWidth);
        setResponsiveHeight(wrapperRef?.current?.clientHeight);
      }, 800);
    }
  }, [isOpenSignature]);

  const handleCancel = () => {
    setIsOpenSignature(false);
    sigPad.current.clear();
  };

  const handleOk = () => {
    propsSignatureModal?.onOk(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
    setIsOpenSignature(false);
  };

  return (
    <Modal
      title={propsSignatureModal?.title || 'Chữ ký'}
      className="modal-signature"
      centered
      width={350}
      open={isOpenSignature}
      okText="Hoàn thành"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div className="signature-wrapper" ref={(e: any) => (wrapperRef.current = e)}>
        <SignatureCanvas
          canvasProps={{
            width: isIpadMobile || isIpadMobileHorizontal ? responsiveWidth : 350,
            height: isIpadMobile || isIpadMobileHorizontal ? responsiveHeight : 200,
            className: 'sigCanvas'
          }}
          ref={ref => {
            sigPad.current = ref;
          }}
        />
      </div>
    </Modal>
  );
}

export default SignatureModal;
