import React, { useEffect } from 'react';
import { Form, FormProps, Input, Modal, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

import useModalStore from 'stores/useModal';

import { MESSAGE_CODE } from 'constants/validate';
import { changePassword } from 'services/api/module/user.api';
import { ParamsChangePassword } from 'services/api/type/user.type';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

function ChangePasswordModal() {
  const [form] = Form.useForm();
  const { isOpenChangePassword, setIsOpenChangePassword } = useModalStore();

  const { mutateAsync: mutateChangePassword, isPending } = useMutation({
    mutationFn: (params: ParamsChangePassword) => changePassword(params)
  });

  useEffect(() => {
    if (!isOpenChangePassword) {
      form.resetFields();
    }
  }, [form, isOpenChangePassword]);

  const handleCloseChangePassword = () => {
    setIsOpenChangePassword(false);
  };

  const handleChangePassword = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutateChangePassword({
        old_password: values.currentPassword,
        password: values.newPassword
      });

      setIsOpenChangePassword(false);
      notification.success({
        message: 'Đổi mật khẩu thành công'
      });
      form.resetFields();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title="Đổi mật khẩu"
      centered
      width={480}
      open={isOpenChangePassword}
      onCancel={handleCloseChangePassword}
      closeIcon={<IconClose />}
      okText="Xác nhận"
      cancelText="Hủy"
      onOk={handleChangePassword}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-change-password"
      destroyOnClose
      confirmLoading={isPending}
    >
      <Form
        form={form}
        name="change-password-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Mật khẩu hiện tại"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_CURRENT_PASSWORD
            }
          ]}
        >
          <Input type="password" className="w-full" />
        </Form.Item>

        <Form.Item
          label="Mật khẩu mới"
          name="newPassword"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_NEW_PASSWORD
            },
            {
              min: 6,
              message: MESSAGE_CODE.REQUIRED_NEW_PASSWORD_MIN
            }
          ]}
        >
          <Input type="password" className="w-full" />
        </Form.Item>

        <Form.Item
          label="Xác nhận mật khẩu mới"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_CONFIRM_PASSWORD
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(MESSAGE_CODE.PASSWORD_NOT_MATCH));
              }
            })
          ]}
        >
          <Input type="password" className="w-full" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangePasswordModal;
