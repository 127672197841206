import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Button, Col, Form, FormProps, Modal, notification, Row, Select } from 'antd';
import { CellValueChangedEvent, RowSelectedEvent } from 'ag-grid-community';

import useModalStore from 'stores/useModal';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';

import { toUtcTime } from 'utils';
import { getGroupRoomingListCols } from 'constants/ag-grid-table';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

const today = dayjs();
const nextDate = today.add(1, 'day');

function GroupRoomingListModal() {
  const [form] = Form.useForm();
  const { isOpenGroupRoomingList, setIsOpenGroupRoomingList } = useModalStore();
  const [guests, setGuests] = useState<any[]>([]);
  const [selectedNodeIds, setSelectedNodeIds] = useState<(number | null)[]>([]);

  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    toUtcTime(today, 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(nextDate, 'YYYY-MM-DD HH:mm:ss')
  );

  useEffect(() => {
    if (!isOpenGroupRoomingList) {
      form.resetFields();
    }
  }, [form, isOpenGroupRoomingList]);

  const handleChangePassword = () => {
    form.submit();
  };

  const handleCloseGroupRooming = () => {
    setIsOpenGroupRoomingList(false);
  };

  const handleAddGuest = () => {
    setGuests(prev => {
      return [
        ...prev,
        {
          partner_name: '',
          gender: null,
          dob: dayjs(),
          country_id: null,
          contact_address_complete: '',
          room_id: null,
          adult: '',
          child: '',
          phone: '',
          email: ''
        }
      ];
    });
  };

  const handleDeleteGuest = () => {
    setGuests(prev => {
      return prev.filter((item, index) => !selectedNodeIds.includes(index));
    });
    setSelectedNodeIds([]);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      console.log(values);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleChangeCellValue = useCallback((event: CellValueChangedEvent) => {
    console.log(event);
    // const newBookings = _.cloneDeep(bookings);
    // newBookings[Number(event.rowIndex)] = event.data;
    // setBookings(newBookings);
  }, []);

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const nodeIds = event.api.getSelectedNodes().map(item => item.rowIndex);
    setSelectedNodeIds(nodeIds);
  };

  const renderedData = useMemo(() => {
    return [
      {
        label: 'Confirm#',
        value: 23954
      },
      {
        label: 'Group',
        value: 100000004917
      },
      {
        label: 'First Arrival',
        value: '25/10/2024'
      },
      {
        label: 'Last Departure',
        value: '29/10/2024'
      },
      {
        label: 'From Date',
        value: '25/10/2024'
      },
      {
        label: 'To Date',
        value: '29/10/2024'
      },
      {
        label: '#pickups',
        value: 0
      }
    ];
  }, []);

  const roomTypeOptions = useMemo(() => {
    return availRoomTypes.map(item => ({
      label: item.room_type_name,
      value: item.room_type_id
    }));
  }, [availRoomTypes]);

  const groupRoomingListCols = useMemo(() => {
    return getGroupRoomingListCols(today, nextDate);
  }, []);

  return (
    <Modal
      title="Group Rooming List"
      centered
      width={1200}
      open={isOpenGroupRoomingList}
      onCancel={handleCloseGroupRooming}
      closeIcon={<IconClose />}
      okText="Cập nhật"
      cancelText="Hủy"
      onOk={handleChangePassword}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-update-group-booking"
      destroyOnClose
      // confirmLoading={isPending}
    >
      <Form
        form={form}
        name="group-rooming-list-form"
        layout="horizontal"
        style={{ width: '100%' }}
        initialValues={{
          roomType: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[8, 16]}>
          {renderedData.map((item, index) => (
            <Col xs={24} sm={12} lg={8} xl={6} key={index}>
              <Row gutter={[16, 16]}>
                <Col span={12} className="text-right">
                  <b>{item.label}</b>
                </Col>
                <Col span={12}>
                  <b>{item.value}</b>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>

        <Row style={{ marginTop: 16 }}>
          <Col xs={24} sm={6}>
            <Form.Item
              label={<b>Room Type</b>}
              name="roomType"
              wrapperCol={{ span: 12 }}
              labelCol={{ span: 12 }}
              className="m-0"
            >
              <Select options={roomTypeOptions} loading={isFetching} />
            </Form.Item>
          </Col>
        </Row>

        <div className="group-rooming-list-table ag-theme-quartz">
          <div className="group-rooming-list__action">
            <Button onClick={handleDeleteGuest} type="primary" disabled={!selectedNodeIds.length}>
              Xóa khách
            </Button>
            <Button onClick={handleAddGuest} className="ant-btn-secondary">
              Thêm khách
            </Button>
          </div>

          <AgGridReact
            rowData={guests}
            columnDefs={groupRoomingListCols}
            rowSelection="multiple"
            // loadingOverlayComponent={isFetchingRoomTypes}
            // loadingCellRenderer={isFetchingRoomTypes}
            onCellValueChanged={handleChangeCellValue}
            onRowSelected={handleChangeSelectedRow}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default GroupRoomingListModal;
