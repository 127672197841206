import _ from 'lodash';
import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, FormProps, Input, InputNumber, notification } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { HKCheckMinibarParams, MinibarItem, SubHKRoom } from 'services/api/type/hk.type';
import { hkCheckMinibar, subHKFillMinibar } from 'services/api/module/hk.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import 'styles/mobile/sub-hk-fill-minibar.scss';

interface Props {
  mainBtnText: string;
  isSubHK?: boolean;
  selectedRoom?: SubHKRoom;
  minibar?: MinibarItem[];
  onBack?: () => void;
}

function MinibarForm({ mainBtnText, minibar, isSubHK, selectedRoom, onBack }: Props) {
  const [form] = Form.useForm();
  const servicesForm = Form.useWatch('services', form);

  const { mutateAsync: mutateHkCheckMinibar, isPending: isPendingHk } = useMutation({
    mutationFn: (params: HKCheckMinibarParams) => {
      return hkCheckMinibar(params);
    }
  });

  const { mutateAsync: mutateSubHKFillMinibar, isPending: isPendingSubHK } = useMutation({
    mutationFn: (params: HKCheckMinibarParams) => {
      return subHKFillMinibar(params);
    }
  });

  useEffect(() => {
    if (isSubHK) {
      form.setFieldValue(
        'services',
        minibar?.map(item => {
          const existedItem = selectedRoom?.minibar_check?.find(bar => bar.product_id === item.id);
          return {
            ...item,
            qty: existedItem?.check_qty || 0
          };
        })
      );
    } else {
      form.setFieldValue(
        'services',
        minibar?.map(item => ({
          ...item,
          qty: 0
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, minibar]);

  const handleIncrease = (key: any[], max: number) => {
    const currentValue = form.getFieldValue(key);
    if (currentValue === max) {
      return;
    }
    form.setFieldValue(key, (form.getFieldValue(key) || 0) + 1);
  };

  const handleDecrease = (key: any[]) => {
    const currentValue = form.getFieldValue(key);
    if (!currentValue || currentValue === 0) {
      return;
    }

    form.setFieldValue(key, form.getFieldValue(key) - 1);
  };

  const onFinishFillMinibar: FormProps['onFinish'] = async (values: any) => {
    try {
      const { note, services } = values;
      if (isSubHK) {
        await mutateSubHKFillMinibar({
          shift_id: selectedRoom?.shift_id || 0,
          products: services.map((item: MinibarItem) => ({
            product_id: item.id,
            actual_qty: item.qty
          })),
          note
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_SUB_HK_ROOM]
        });
      } else {
        await mutateHkCheckMinibar({
          shift_id: selectedRoom?.shift_id || 0,
          products: services.map((item: MinibarItem) => ({
            product_id: item.id,
            check_qty: item.qty
          })),
          note
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_HK_ROOM]
        });
      }
      onBack && onBack();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <>
      <div className="form-container">
        <p className="title">Kiểm tra Minibar</p>
        <Form
          form={form}
          name="sub-hk-fill-minibar-form"
          layout="inline"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          style={{ width: '100%' }}
          initialValues={{
            note: selectedRoom?.minibar_note || '',
            services: []
          }}
          onFinish={onFinishFillMinibar}
          autoComplete="off"
        >
          <Form.List name="services">
            {fields => {
              return (
                <div className="w-full">
                  {fields.map(field => {
                    const item: MinibarItem = form.getFieldValue(['services', field.name]);
                    const qty = _.get(servicesForm, `${field.name}.qty`, 0);
                    return (
                      <Form.Item
                        label={`${item.max_qty - qty}/${item.max_qty} ${item.name}`}
                        name={[field.name, 'qty']}
                        key={field.name}
                        className="w-full"
                        style={{ marginInlineEnd: 0 }}
                      >
                        <InputNumber
                          min={0}
                          max={item.max_qty}
                          addonBefore={
                            <MinusOutlined
                              onClick={() => {
                                handleDecrease(['services', field.name, 'qty']);
                              }}
                            />
                          }
                          addonAfter={
                            <PlusOutlined
                              onClick={() => {
                                handleIncrease(['services', field.name, 'qty'], item.max_qty);
                              }}
                            />
                          }
                        />
                      </Form.Item>
                    );
                  })}
                </div>
              );
            }}
          </Form.List>

          <Form.Item
            label="Ghi chú"
            name="note"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="sub-hk-fill-minibar-note"
          >
            <Input.TextArea rows={4} readOnly={isSubHK} />
          </Form.Item>
        </Form>
      </div>

      <div className="action-wrapper-fixed">
        <div className="inner">
          <Button
            className="ant-btn-secondary w-full"
            onClick={() => form.submit()}
            loading={isPendingHk || isPendingSubHK}
          >
            {mainBtnText}
          </Button>
        </div>
      </div>
    </>
  );
}

export default MinibarForm;
