import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

// import { useGetMe } from 'hooks/useGetMe';
import BranchesDropdown from 'components/common/branches-dropdown';

// import { ReactComponent as IconSort } from 'assets/images/sort.svg';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconArrowLeft } from 'assets/images/arrow-left.svg';

interface Props {
  selectedRooms: number[];
  isShowCheckMinibar: boolean;
  isShowShiftDivision?: boolean;
  onCancelSelect: () => void;
  onBack: () => void;
  onSelectAll?: () => void;
}

function SubHKHeaderMobile({
  selectedRooms,
  isShowCheckMinibar,
  isShowShiftDivision = false,
  onBack,
  onCancelSelect,
  onSelectAll
}: Props) {
  return (
    <header className="pms-app-sub-hk-header-mobile">
      <div
        className="header-inner"
        style={{ justifyContent: !!selectedRooms.length ? 'space-between' : '' }}
      >
        {!!selectedRooms.length && (
          <div>
            {isShowCheckMinibar || isShowShiftDivision ? (
              <Button className="btn-back" type="link" icon={<IconArrowLeft />} onClick={onBack} />
            ) : (
              <Button
                className="btn-close"
                type="link"
                icon={<IconClose />}
                onClick={onCancelSelect}
              />
            )}
          </div>
        )}

        {(!selectedRooms.length || isShowCheckMinibar || isShowShiftDivision) && (
          <h4 className="header-title">
            {isShowCheckMinibar
              ? 'CHECK MINIBAR'
              : isShowShiftDivision
                ? 'PHÂN CA'
                : dayjs().format('DD-MM-YYYY')}
          </h4>
        )}

        {!!selectedRooms.length && !isShowCheckMinibar && (
          <>
            <div
              className={classNames('pms-menu-center', {
                'pms-menu-center--absolute': !!onSelectAll
              })}
            >
              <p className="m-0">CHỌN ({selectedRooms.length})</p>
            </div>

            {onSelectAll && (
              <div className="pms-menu-right">
                <Button onClick={onSelectAll} className="ant-btn-secondary">
                  TẤT CẢ
                </Button>
              </div>
            )}
          </>
        )}
        <div className="branches">
          <BranchesDropdown />
        </div>
      </div>
    </header>
  );
}

export default SubHKHeaderMobile;
