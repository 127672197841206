import React, { useRef } from 'react';
import { Button, List } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { AttachmentType, BookingLine } from 'services/api/type/booking.type';
import { toLocalTime } from 'utils';

interface Props {
  bookingLine: BookingLine;
}

function AttachmentTab({ bookingLine }: Props) {
  const opener = useRef<Window | null>();
  const handleDownload = (item: AttachmentType) => {
    opener.current = window.open(item.url, '_blank');
    // opener.current?.addEventListener("load", handleFinishDownload, true);
  };

  // const handleFinishDownload = () => {
  //   opener.current?.close();
  // };

  return (
    <div className="modal-content">
      <List
        style={{ maxHeight: 400, overflow: 'auto' }}
        dataSource={bookingLine.attachments || []}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={<span>{item.name}</span>}
              description={
                <span>Tạo vào lúc: {toLocalTime(item.created_date, 'DD/MM/YYYY HH:mm')}</span>
              }
            />
            <Button type="link" icon={<EyeOutlined />} onClick={() => handleDownload(item)}>
              Xem file
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
}

export default AttachmentTab;
