import { ReservationForcecastType } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetReservationForecast = (filterOptions: ReservationForcecastType) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_RESERVATION_FORECAST, filterOptions],
    queryFn: () => API.report.getReservationForecast(filterOptions),
    enabled: !!filterOptions
  });

  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      date: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    status,
    isLoading,
    isFetching
  };
};
