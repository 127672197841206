import { Text, View } from '@react-pdf/renderer';

import styles from '../style-sheet';

interface ITitleProps {
  title: string;
  fromDate?: string;
  toDate?: string;
}

const Title = ({ title, fromDate, toDate }: ITitleProps) => {
  return (
    <View style={styles.billContainer}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px'
        }}
      >
        <Text style={{ ...styles.textBold, fontSize: 20, textTransform: 'uppercase' }}>
          {title}
        </Text>
        {fromDate && toDate && (
          <Text style={{ fontSize: 14, fontStyle: 'italic' }}>
            {fromDate} - {toDate}
          </Text>
        )}
      </View>
    </View>
  );
};

export default Title;
