import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Form, FormProps, Row, Col, Select, Spin, notification } from 'antd';

import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { ReactComponent as IconClose } from 'assets/images/close.svg';

import { changeRoomType } from 'services/api/module/room.api';
import QUERY_KEYS from 'services/api/queryKeys';

function ChangeRoomTypeModal() {
  const [formChangeRoomType] = Form.useForm();
  const queryClient = useQueryClient();

  const { isOpenChangeRoomType, setIsOpenChangeRoomType } = useModalStore();
  const { bookingLineDetail } = useBookingStore();

  const [rangeDate, setRangeDate] = useState(['', '']);
  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    rangeDate[0],
    rangeDate[1],
    isOpenChangeRoomType
  );

  const { mutateAsync: mutateChangeRoomType, isPending: isPendingChangeRoomType } = useMutation({
    mutationFn: (params: any = {}) => changeRoomType(params)
  });

  useEffect(() => {
    if (!_.isEmpty(bookingLineDetail)) {
      setRangeDate([bookingLineDetail?.check_in || '', bookingLineDetail?.check_out || '']);
    }
  }, [bookingLineDetail]);

  const handleCloseChangeRoomType = () => {
    setIsOpenChangeRoomType(false);
    formChangeRoomType.resetFields();
    formChangeRoomType.setFieldValue('room_type_id', bookingLineDetail?.room_type_id);
  };

  const handleChangeRoomType = async () => {
    formChangeRoomType.submit();
  };

  const onFinishChangeRoomType: FormProps['onFinish'] = async (values: any) => {
    const { room_type_id } = values;
    if (bookingLineDetail) {
      try {
        await mutateChangeRoomType({
          booking_line_id: bookingLineDetail.booking_line_id,
          room_type_id
        });

        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
        });

        setIsOpenChangeRoomType(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const roomTypeOptions = useMemo(() => {
    return (availRoomTypes || []).map(item => ({
      label: item.room_type_name,
      value: item.room_type_id
    }));
  }, [availRoomTypes]);

  return (
    <Modal
      title="Đổi hạng phòng"
      centered
      width={410}
      open={isOpenChangeRoomType}
      onCancel={handleCloseChangeRoomType}
      closeIcon={<IconClose />}
      okText="Lưu"
      cancelText="Đóng"
      onOk={handleChangeRoomType}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-change-room-type custom-modal-footer"
      confirmLoading={isPendingChangeRoomType}
      destroyOnClose
    >
      <div
        className="modal-content-wrapper"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        {isFetching ? (
          <Spin className="flex items-center justify-center" style={{ height: '200px' }} />
        ) : (
          <Form
            form={formChangeRoomType}
            name="change-room-form"
            layout="vertical"
            style={{ width: '100%' }}
            initialValues={{
              room_type_id: bookingLineDetail?.room_type_id,
              room_id: bookingLineDetail?.room_id,
              is_change_rate: false,
              pricelist_id: null
            }}
            onFinish={onFinishChangeRoomType}
            autoComplete="off"
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item label="Loại phòng" name="room_type_id">
                  <Select options={roomTypeOptions} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
}

export default ChangeRoomTypeModal;
