import {
  Modal,
  Form,
  FormProps,
  notification,
  Row,
  Select,
  Col,
  Input,
  Button,
  InputNumber
} from 'antd';
import useModal from 'stores/useModal';
import { chargeDebtPayment } from 'services/api/module/payment.api';
import { ChargeDebtPayment } from 'services/api/type/payment.type';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import useBookingStore from 'stores/useBooking';
import { PAYMENT_METHOD, PAYMENT_METHOD_OPTIONS } from 'constants/form';

function ChargeDebtPaymentModal() {
  const [form] = Form.useForm();
  const { isOpenViewChargeDebtPayment, paymentId, amount, state, setIsOpenViewChargeDebtPayment } =
    useModal();
  const { bookingLineId } = useBookingStore();

  const { mutateAsync: mutateChargeDebtPayment } = useMutation({
    mutationFn: (data: ChargeDebtPayment) => {
      return chargeDebtPayment(data);
    }
  });

  const handleCancel = () => {
    form.resetFields();
    setIsOpenViewChargeDebtPayment(false);
  };

  const onFinish: FormProps['onFinish'] = async (values: ChargeDebtPayment) => {
    if (paymentId) {
      try {
        await mutateChargeDebtPayment({
          payment_id: paymentId,
          payment_method: values.payment_method,
          note: values.note
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLineId]
        });
        notification.success({
          message: 'Payment successful!'
        });
        handleCancel();
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Thanh toán công nợ"
      centered
      width={450}
      bodyStyle={{ padding: '30px', height: '260px' }}
      open={isOpenViewChargeDebtPayment}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        name="payment-form"
        layout="vertical"
        initialValues={{
          note: '',
          payment_method: PAYMENT_METHOD.CASH,
          amount: amount || 0,
          state: state
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item label="Hình thức TT" name="payment_method" rules={[{ required: true }]}>
              <Select options={PAYMENT_METHOD_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Số tiền" name="amount" rules={[{ required: true }]}>
              <InputNumber readOnly={!!amount} min={0} className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="note">
          <Input.TextArea placeholder="Ghi chú" style={{ marginTop: '16px' }} />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right', marginTop: '20px' }}>
          <Button type="primary" htmlType="submit" disabled={state == 'cancelled'}>
            Trả công nợ
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default ChargeDebtPaymentModal;
