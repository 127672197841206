import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { PaginationType } from 'services/api/type/booking.type';
import get from 'lodash/get';
import { TravelAgencyResponse } from '@/services/api/type/travel-agency.type';

export const useGetTravelAgency = (id?: number) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES, id],
    queryFn: () => API.ta.getTravelAgency(Number(id)),
    enabled: !!id
  });

  return {
    data: data,
    isLoading
  };
};

export const useGetTravelAgencies = (values?: any, limit?: number, page?: number) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES, values, page],
    queryFn: () => API.ta.getTravelAgencies(values, limit, page),
    enabled: !!values
  });

  return {
    data: get(data, 'data', []) as TravelAgencyResponse[],
    pagination: get(data, 'pagination', {}) as PaginationType,
    isLoading
  };
};
