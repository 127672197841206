import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetCustomerById = (customerId: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMER_BY_ID, customerId],
    queryFn: () => API.customer.getCustomerById(customerId),
    enabled: !!customerId
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
