import { RoomAvailableType } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomAvailability = (filterOptions: RoomAvailableType) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROOM_AVAILABILITY, filterOptions],
    queryFn: () => API.report.getRoomAvailability(filterOptions),
    enabled: !!filterOptions
  });

  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      roomType: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    status,
    isLoading,
    isFetching
  };
};
