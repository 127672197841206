import React, { useEffect } from 'react';
import { Form, FormProps, Input, Modal, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { MESSAGE_CODE } from 'constants/validate';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { logEvent } from 'services/tracking/gaTracking';

function UndoCheckOutModal() {
  const [form] = Form.useForm();
  const location = useLocation();
  const { isOpenUndoCheckOut, setIsOpenUndoCheckOut } = useModal();
  const { bookingLineId } = useBookingStore();

  const { mutateAsync: mutateUndoCheckOut, isPending } = useMutation({
    mutationFn: (params: { bookingLineId: number; reason: string }) =>
      API.booking.undoCheckOut(params.bookingLineId, params.reason)
  });

  useEffect(() => {
    if (!isOpenUndoCheckOut) {
      form.resetFields();
    }
  }, [form, isOpenUndoCheckOut]);

  const handleCancel = () => {
    setIsOpenUndoCheckOut(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (bookingLineId) {
      try {
        await mutateUndoCheckOut({
          bookingLineId: bookingLineId,
          reason: values?.reason
        });

        setIsOpenUndoCheckOut(false);

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
        });

        refreshBookingList(location);
        refreshRoomMap(location);

        logEvent({
          category: 'booking',
          action: 'undo_check_out',
          label: `BL ID: ${bookingLineId}`
        });
        notification.success({
          message: 'Hoàn tác trả phòng thành công'
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  return (
    <Modal
      title="Xác nhận hoàn tác trả phòng"
      className="modal-undo-checkout"
      centered
      width={400}
      open={isOpenUndoCheckOut}
      okText="Xác nhận"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="undo-checkout-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          reason: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: MESSAGE_CODE.REQUIRED_REASON_FIX_ROOM
            }
          ]}
        >
          <Input.TextArea rows={5} placeholder="Lý do (bắt buộc)" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UndoCheckOutModal;
