import _ from 'lodash';
import React, { useMemo } from 'react';
import { Button, Checkbox, CheckboxProps } from 'antd';
import { PrinterFilled } from '@ant-design/icons';

import GroupTabCollapse from './group-tab-collapse';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { GroupBookingLineItem } from 'services/api/type/booking.type';

interface Props {
  bookingLines?: GroupBookingLineItem[];
  activeBookingLineId: number | undefined;
}
function GroupTab({ activeBookingLineId, bookingLines }: Props) {
  const { setIsOpenViewPDFGroup } = useModal();
  const { checkedListBookingLineId, setCheckListBookingLineId } = useBookingStore();

  const checkAll = bookingLines?.length === checkedListBookingLineId.length;
  const indeterminate =
    checkedListBookingLineId.length > 0 &&
    checkedListBookingLineId.length < _.get(bookingLines, 'length', 0);

  const handleViewPdf = () => {
    setIsOpenViewPDFGroup(true);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    setCheckListBookingLineId(e.target.checked ? bookingLines?.map(item => item.id) || [] : []);
  };

  const groupedBooking = useMemo(() => {
    return _.groupBy(bookingLines, item => item.room_type_name);
  }, [bookingLines]);

  return (
    <div className="modal-group-col">
      <div className="group-title">
        <div className="flex items-center" style={{ gap: 8 }}>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} />
          <p className="m-0">Thông tin đoàn ({bookingLines?.length})</p>
        </div>
        <Button
          type="link"
          onClick={handleViewPdf}
          className="flex-shrink-0"
          style={{ padding: 0, height: '24px', width: '24px' }}
        >
          <PrinterFilled style={{ color: '#000' }} />
        </Button>
      </div>

      <div className="group-list">
        {Object.keys(groupedBooking).map((key, index) => (
          <GroupTabCollapse
            key={index}
            title={key}
            data={groupedBooking[key]}
            activeBookingLineId={activeBookingLineId}
          />
        ))}
      </div>
    </div>
  );
}

export default GroupTab;
