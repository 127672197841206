// import _ from 'lodash';
// import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
// import { CLEANING_STATUS, MINIBAR_STATUS } from 'constants/common';
// import { SubHKRoom } from 'services/api/type/hk.type';
import { ROOM_STATUS } from 'constants/form';
import { useMemo } from 'react';

export const useGetSubHKRoom = () => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_SUB_HK_ROOM],
    queryFn: () => API.hk.getSubHKRoom(),
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    refetchInterval: 6000
  });

  // const sortRooms = (rooms: SubHKRoom[] = []) => {
  //   const bookedRooms: SubHKRoom[] = [];
  //   const rest: SubHKRoom[] = [];
  //   rooms.forEach(room => {
  //     if (room.room_status === 'booked') {
  //       bookedRooms.push(room);
  //     } else {
  //       rest.push(room);
  //     }
  //   });
  //   bookedRooms?.sort((a, b) => {
  //     if (a.attributes.room_no && b.attributes.room_no) {
  //       return a.attributes.room_no.localeCompare(b.attributes.room_no);
  //     }
  //     return 0;
  //   });
  //   rest?.sort((a, b) => {
  //     if (a.attributes.room_no && b.attributes.room_no) {
  //       return a.attributes.room_no.localeCompare(b.attributes.room_no);
  //     }
  //     return 0;
  //   });

  //   return [...bookedRooms, ...rest];
  // };

  // const sortedData = useMemo(() => {
  //   let checkedRooms: SubHKRoom[] = [];
  //   let notAssignedRooms: SubHKRoom[] = [];
  //   let assignedRooms: SubHKRoom[] = [];
  //   let lockedRooms: SubHKRoom[] = [];
  //   let rest: SubHKRoom[] = [];

  //   data?.forEach(room => {
  //     const approvedCleaning = [
  //       CLEANING_STATUS.APPROVE,
  //       CLEANING_STATUS.WAIT_FOR_APPROVAL
  //     ].includes(room.cleaning_status);
  //     const approvedShowMinibar = [MINIBAR_STATUS.APPROVE, MINIBAR_STATUS.CHECKED].includes(
  //       room.minibar_status
  //     );

  //     if (room.room_status === ROOM_STATUS.LOCKED.toLowerCase()) {
  //       lockedRooms.push(room);
  //     } else if (approvedCleaning || approvedShowMinibar) {
  //       checkedRooms.push(room);
  //     } else if (
  //       room.cleaning_status === CLEANING_STATUS.NEW ||
  //       room.minibar_status === MINIBAR_STATUS.NEW
  //     ) {
  //       assignedRooms.push(room);
  //     } else if (_.isEmpty(room.hk_assigned)) {
  //       notAssignedRooms.push(room);
  //     } else {
  //       rest.push(room);
  //     }
  //   });

  //   checkedRooms = sortRooms(checkedRooms);
  //   notAssignedRooms = sortRooms(notAssignedRooms);
  //   assignedRooms = sortRooms(assignedRooms);
  //   rest = sortRooms(rest);
  //   lockedRooms = sortRooms(lockedRooms);
  //   return [...checkedRooms, ...notAssignedRooms, ...assignedRooms, ...rest, ...lockedRooms];
  // }, [data]);

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      if (
        a.room_status === ROOM_STATUS.LOCKED.toLowerCase() &&
        b.room_status !== ROOM_STATUS.LOCKED.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });
  }, [data]);

  return {
    data: sortedData || [],
    isLoading,
    isFetching
  };
};
