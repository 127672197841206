import React from 'react';
import { Button } from 'antd';

// import { ReactComponent as IconSort } from 'assets/images/sort.svg';
// import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconArrowLeft } from 'assets/images/arrow-left.svg';
import BranchesDropdown from 'components/common/branches-dropdown';

interface Props {
  room?: number;
  onBack?: () => void;
}

function HKHeaderMobile({ room, onBack }: Props) {
  return (
    <header className="pms-app-hk-header-mobile">
      <div className="header-inner">
        {onBack && (
          <Button className="btn-back" type="link" icon={<IconArrowLeft />} onClick={onBack} />
        )}

        <h4 className="header-title">PHÒNG {room}</h4>
        <div className="branches">
          <BranchesDropdown />
        </div>
      </div>
    </header>
  );
}

export default HKHeaderMobile;
