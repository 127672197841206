import React, { useEffect, useState } from 'react';
import { Modal, Empty, Typography, List, Tag, Form, DatePicker, Select, Button } from 'antd';
import useModal from 'stores/useModal';
import { useGetTrackingLogs } from 'hooks/useGetTrackingLogs';
import { toLocalTime, getStartOfToday, getEndOfToday } from 'utils';
import { TrackingLogsType } from 'services/api/type/tracking-logs.type';
import { FormProps } from 'rc-field-form';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import 'styles/tracking-logs.scss';

const { Option } = Select;
const { Text, Title } = Typography;

// Generate logs following date
const groupLogsByDate = (logs: any) => {
  return logs.reduce((acc: any, log: any) => {
    const dateKey = toLocalTime(log.create_date, 'YYYY-MM-DD');
    acc[dateKey] = acc[dateKey] || [];
    acc[dateKey].push(log);
    return acc;
  }, {});
};

function TrackingLogsModal() {
  const [form] = Form.useForm();
  const { isOpenViewTrackingLogs, setIsOpenViewTrackingLogs, bookingLineId } = useModal();
  const [filterOptions, setFilterOptions] = useState<any>({
    booking_line_id: bookingLineId
  });
  const [range, setRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [initialized, setInitialized] = useState(false);

  const { data: trackingLogsData } = useGetTrackingLogs(filterOptions);

  const trackingLogs = trackingLogsData ? groupLogsByDate(trackingLogsData) : {};

  if (trackingLogsData && trackingLogsData.length > 0 && isOpenViewTrackingLogs && !initialized) {
    const dates = trackingLogsData.map((log: any) => dayjs(log.create_date).format('YYYY-MM-DD'));
    const oldestDate = dayjs(Math.min(...dates.map((d: string) => dayjs(d).valueOf())));
    const latestDate = dayjs(Math.max(...dates.map((d: string) => dayjs(d).valueOf())));

    setRange([oldestDate, latestDate]);
    setInitialized(true);

    form.setFieldsValue({
      rangeDate: [oldestDate, latestDate]
    });
  }

  useEffect(() => {
    if (!isOpenViewTrackingLogs) {
      form.resetFields();
      setRange(null);
      setInitialized(false);
    } else {
      setFilterOptions({
        booking_line_id: bookingLineId
      });
      setInitialized(false);
    }
  }, [isOpenViewTrackingLogs, form, bookingLineId]);

  const handleCancel = () => {
    setIsOpenViewTrackingLogs(false);
    form.resetFields();
  };

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, type, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        _.set(restFilterOptions, 'start_date', getStartOfToday(startDate.format('YYYY-MM-DD')));
        _.set(restFilterOptions, 'end_date', getEndOfToday(endDate.format('YYYY-MM-DD')));
        _.set(restFilterOptions, 'type', type);
        _.set(restFilterOptions, 'booking_line_id', bookingLineId);
      }
    }

    setFilterOptions(restFilterOptions);
  };

  return (
    <Modal
      title="Nhật ký hoạt động"
      centered
      width={1000}
      open={isOpenViewTrackingLogs}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      className="tracking-logs-modal"
      bodyStyle={{ padding: '20px', height: '80vh' }}
    >
      <Form form={form} layout="inline" onFinish={onFinish} className="form-container">
        <Form.Item name="rangeDate">
          <DatePicker.RangePicker
            className="range-picker"
            placeholder={['Từ ngày', 'Đến ngày']}
            format="DD-MM-YYYY"
            value={range}
            onChange={dates => {
              if (dates && dates[0] && dates[1]) {
                setRange([dates[0], dates[1]]);
              } else {
                setRange(null);
              }
            }}
          />
        </Form.Item>
        <Form.Item name="type" className="select-type-form-item">
          <Select placeholder="Chọn hành động" allowClear>
            {Object.keys(TrackingLogsType).map(key => (
              <Option key={key} value={key}>
                {TrackingLogsType[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            Tìm kiếm
          </Button>
        </Form.Item>
      </Form>

      <div className="logs-container">
        {Object.keys(trackingLogs).length ? (
          Object.keys(trackingLogs).map(dateKey => (
            <div key={dateKey} className="log-date">
              <Title level={5} className="log-title">
                {toLocalTime(dateKey, 'DD/MM/YYYY')}
              </Title>
              <List
                itemLayout="vertical"
                dataSource={trackingLogs[dateKey]}
                className="log-list"
                renderItem={(log: any) => (
                  <List.Item key={log.id} className="log-item">
                    <Text strong className="log-time">
                      {toLocalTime(log.create_date, 'HH:mm:ss')}{' '}
                      <Tag className="log-tag" color="blue">
                        {TrackingLogsType[log.type] || log.type}
                      </Tag>
                    </Text>
                    <Text className="log-detail">
                      {log.user_email}: {log.log}
                    </Text>
                  </List.Item>
                )}
              />
            </div>
          ))
        ) : (
          <Empty description="Không có dữ liệu phù hợp" className="empty-state" />
        )}
      </div>
    </Modal>
  );
}

export default TrackingLogsModal;
