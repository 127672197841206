import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { BranchesType } from 'services/api/type/branch.type';

export const getAllBranches = (): Promise<BranchesType[]> => {
  return axiosInstance.get(paths.getAllBranches()).then(item => item.data.data);
};

export const getCurrentBranch = (): Promise<BranchesType> => {
  return axiosInstance.get(paths.getCurrentBranch()).then(item => item.data.data);
};
