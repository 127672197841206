import { useEffect } from 'react';
import { queryClient } from 'index';
import { useMutation } from '@tanstack/react-query';

import { Modal, Form, notification } from 'antd';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';

import { useGetCountries } from 'hooks/useGetCountries';
import { TravelAgencyParams } from 'services/api/type/travel-agency.type';
import { useGetSources } from 'hooks/useGetSources';
import { useGetCompanies } from 'hooks/useGetCompanies';

import { renderFormRow } from 'components/modal/travel-agency-component/RowForTravelAgencyAction';

const CreateTravelAgency = ({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) => {
  const [form] = Form.useForm();
  const { data: countries } = useGetCountries();
  const { data: sources } = useGetSources();
  const { data: companies } = useGetCompanies();

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      form.setFieldsValue({
        is_travel_agency: true,
        is_company: false
      });
    }
  }, [form, isOpen]);

  const { mutateAsync: mutateCreateTravelAgency } = useMutation({
    mutationFn: (values: TravelAgencyParams) => API.ta.createTravelAgency(values)
  });

  const onFinish = async (values: TravelAgencyParams) => {
    try {
      await mutateCreateTravelAgency(values);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES] });
      notification.success({
        message: 'Lưu thành công'
      });
      onCancel();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal
      title="Tạo công ty du lịch mới"
      centered
      width={800}
      open={isOpen}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleOk}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      okText="Lưu"
      closeIcon={<IconClose />}
      className="modal-add-travel-agency-info"
    >
      <Form
        form={form}
        name="travel-agency-info-form"
        layout="vertical"
        style={{ width: '100%', padding: '16px' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {renderFormRow({ countries, companies, sources })}
      </Form>
    </Modal>
  );
};

export default CreateTravelAgency;
