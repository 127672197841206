import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { PAYMENT_STATUS, TYPE_OF_QUARTERS } from 'constants/form';

import { RangePickerProps } from 'antd/es/date-picker';
import { RangeValueType } from 'services/api/type/common.type';
import { nanoid } from 'nanoid';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);

export const formatInputMoney = (value: number) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const DATE_TIME_SHORT_FORMAT = 'YYYY-MM-DD HH:mm';

export const formatDate = (date?: string): string => {
  if (!date) {
    return '--';
  }

  const parsedDate = dayjs.utc(date);
  if (!parsedDate.isValid()) {
    return '--';
  }

  return parsedDate.local().format('DD-MM-YYYY');
};

export const getEarliestValidDate = (...dates: (string | undefined)[]): string => {
  const validDates = dates.map(date => formatDate(date)).filter(date => date !== '--');
  if (validDates.length === 0) {
    return '--';
  }
  return validDates.reduce((earliestDate, currentDate) => {
    return dayjs(currentDate, 'DD-MM-YYYY').isBefore(dayjs(earliestDate, 'DD-MM-YYYY'))
      ? currentDate
      : earliestDate;
  });
};

export const generateExportFileName = (title: string) => {
  return `${title}-${getStartOfToday()}-${getEndOfToday()}-${nanoid()}`;
};

export const handleRangeDateChange = (
  form: any,
  value: RangeValueType | null,
  nameForm: string = 'rangeDate'
) => {
  let startDate: Dayjs | null = _.get(value, '0', null);
  let endDate: Dayjs | null = _.get(value, '1', null);
  if (startDate) {
    startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
  }
  if (endDate) {
    endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
  }
  form.setFieldValue(nameForm, [startDate, endDate]);
};

export const renderWithLineThrough = (value: any, record: any) => {
  return React.createElement(
    'span',
    {
      className: classNames({ 'line-through': record.state === PAYMENT_STATUS.CANCELLED })
    },
    value
  );
};

export const renderWithLineThroughAgTable = (value: any, enable: boolean) => {
  return React.createElement(
    'span',
    {
      className: classNames({ 'line-through': enable })
    },
    value
  );
};

export const renderTextWithEllipsis = (text: string) => {
  return React.createElement(
    'p',
    {
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      },
      title: text
    },
    text
  );
};

export function handleDisableCheckInButton(bookingLine?: any): boolean {
  const today = dayjs().startOf('day');
  const checkInDate = bookingLine?.check_in ? dayjs(bookingLine.check_in).startOf('day') : null;

  if (checkInDate && (checkInDate.isSame(today) || checkInDate.isBefore(today))) {
    return false;
  } else {
    return true;
  }
}

export function getQuarterDates(quarter: string): { startDate: Dayjs; endDate: Dayjs } {
  const yearStart = dayjs().startOf('year');
  const quarterIndex = TYPE_OF_QUARTERS.indexOf(quarter);

  const startDate = yearStart.add(quarterIndex * 3, 'months');
  const endDate = startDate.add(2, 'months').endOf('month');

  return { startDate, endDate };
}

export function filterTruthyValues<T extends object>(obj: T): Partial<T> {
  return Object.keys(obj)
    .filter(key => Boolean(obj[key as keyof T]))
    .reduce((cur, next) => {
      return {
        ...cur,
        [next]: obj[next as keyof T]
      };
    }, {} as Partial<T>);
}

export const formatMoney = (value: number | string | undefined): string => {
  if (value === undefined || value === '') return '';
  return Number(value).toLocaleString('de-DE');
};

export const parseMoney = (value: number | string | undefined): number => {
  if (value === undefined || value === '') return 0;
  if (typeof value === 'number') return value;
  return Number(value.replace(/\./g, ''));
};

export const formatMoneyValue = (value: any): string => {
  return value !== undefined ? formatMoney(parseMoney(value)) : '';
};

export const formatNumber = (value: string | number): string => {
  return value !== '' ? Number(value).toLocaleString('vn') : '';
};

export function formatCurrency(num?: number, prefix = '', locale = 'vn') {
  if (_.isNil(num)) return '';
  if (prefix) {
    return `${prefix} ${num.toLocaleString(locale)}`;
  }
  return num.toLocaleString(locale) + ' đ';
}

export const getCurrentDateTime = () => {
  return toUtcTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
};

export const renderWithRowSpan = (value: any, row: any, rowSpanField: string) => ({
  children: value,
  props: {
    rowSpan: row[rowSpanField] || 0
  }
});

export function toLocalTime(datetime: string, format: string = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs.utc(datetime).local().format(format);
}

export function toUtcTime(
  datetime?: string | Dayjs | null,
  format: string = 'YYYY-MM-DD HH:mm:ss'
) {
  if (!datetime) return undefined;
  return dayjs(datetime).utc().format(format);
}

// get date today min 14PM using dayjs
export function getDateTodayMin2PM() {
  // min 14pm
  const now = dayjs();
  return now.hour(14).minute(0).second(0).millisecond(0);
}

export function getNextDateAt(day: number, hour: number) {
  return dayjs().add(day, 'day').hour(hour).minute(0).second(0).millisecond(0);
}

// get starting date of today local time and convert to utc
export function getStartOfToday(date?: string) {
  return dayjs(date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
}

// get ending date of today local time and convert to utc
export function getEndOfToday(date?: string) {
  return dayjs(date).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
}

export function trimTruthyValues(data: any) {
  const truthyValues: any = Object.keys(data)
    .filter(key => Boolean(data[key]))
    .reduce((cur, next) => {
      return {
        ...cur,
        [next]: data[next]
      };
    }, {});

  return truthyValues;
}

export function slugify(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    .replace(/đ/g, 'd')
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
}

export async function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(String(reader.result).replace(/^data:.+;base64,/, ''));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };

    return reader;
  });
}

export function readFile(file: any): Promise<any> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const disabledDateFuture: RangePickerProps['disabledDate'] = current => {
  // Can not select days after today and today
  return current && current > dayjs().endOf('day');
};

export function buildClassName(obj: any) {
  return Object.keys(obj)
    .filter(key => obj[key])
    .join(' ');
}

export function jsonToXml(json: any, includeHeader = false) {
  let xml = '';

  if (includeHeader) {
    xml += '<?xml version="1.0" encoding="UTF-8" ?>';
  }

  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      if (Array.isArray(json[key])) {
        // eslint-disable-next-line no-loop-func
        xml += `<${key}>${json[key].map((item: any) => jsonToXml(item)).join('')}</${key}>`;
      } else if (typeof json[key] === 'object') {
        xml += `<${key}>${jsonToXml(json[key])}</${key}>`;
      } else {
        xml += `<${key}>${json[key]}</${key}>`;
      }
    }
  }

  return xml;
}

/**
 * Rotates an image Blob by a specified angle
 * @param {Blob} imageBlob - The original image Blob
 * @param {number} angle - The rotation angle in degrees
 * @returns {Promise<Blob>} - A promise that resolves to the rotated image Blob
 */
export async function rotateImage(imageBlob: any, angle: number) {
  const createImageBitmap = (blob: File) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(blob);
      img.onload = () => {
        resolve(img);
        URL.revokeObjectURL(url);
      };
      img.onerror = reject;
      img.src = url;
    });

  const imageBitmap: any = await createImageBitmap(imageBlob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const radians = angle * (Math.PI / 180);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  // Calculate the new canvas dimensions
  const newWidth = Math.abs(imageBitmap.width * cos) + Math.abs(imageBitmap.height * sin);
  const newHeight = Math.abs(imageBitmap.width * sin) + Math.abs(imageBitmap.height * cos);

  canvas.width = newWidth;
  canvas.height = newHeight;

  // Move the origin to the center of the canvas
  ctx?.translate(newWidth / 2, newHeight / 2);
  ctx?.rotate(radians);
  ctx?.drawImage(imageBitmap, -imageBitmap.width / 2, -imageBitmap.height / 2);

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, imageBlob.type);
  });
}

export const generateUniqueId = () => {
  return nanoid();
};
