import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { getAllTrackingLogs } from 'services/api/module/tracking-logs.api';
import { ParamsGetTrackingLogs } from 'services/api/type/tracking-logs.type';

export const useGetTrackingLogs = (filterOptions: ParamsGetTrackingLogs) => {
  const { data, isLoading, isFetched, error } = useQuery({
    queryKey: [
      QUERY_KEYS.GET_TRACKING_LOGS,
      filterOptions.booking_line_id,
      filterOptions.start_date,
      filterOptions.end_date,
      filterOptions.type
    ],
    queryFn: () => getAllTrackingLogs(filterOptions),
    enabled: !!filterOptions
  });

  return {
    data,
    isLoading,
    isFetched,
    error
  };
};
