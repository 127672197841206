import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { PaginationType } from 'services/api/type/booking.type';
import { PAGE_SIZE_SHIFT_HANDOVER } from 'services/api/module/report.api';
import { ShiftHandoverResponse } from 'services/api/type/report.type';

interface FilterType {
  date_from: string;
  date_to: string;
}

export const useGetAllShiftHandover = (
  filterOptions: FilterType,
  page: number,
  pageSize: number = PAGE_SIZE_SHIFT_HANDOVER
) => {
  const { data, status, isLoading, isFetching } = useQuery<ShiftHandoverResponse>({
    queryKey: [QUERY_KEYS.GET_ALL_SHIFT_HANDOVER, page, pageSize],
    queryFn: () =>
      API.report.getListShiftHandover({
        ...filterOptions,
        page,
        limit: pageSize
      })
  });

  return {
    data: data?.data || [],
    pagination: data?.pagination || ({} as PaginationType),
    status,
    isLoading,
    isFetching
  };
};
