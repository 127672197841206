import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';

import useModalStore from 'stores/useModal';
import { useGetGroupBookingDetail } from 'hooks/useGetGroupBookings';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';

import { MappingColumnBookingStatus } from 'constants/table';
import { RangeValueType } from 'services/api/type/common.type';
import { formatCurrency, toUtcTime } from 'utils';
import { formatInputNumber } from 'utils/currency';
import { addSubGroup, updateSubgroup } from 'services/api/module/group.api';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ParamsAddSubGroup } from 'services/api/type/group.type';
import { MESSAGE_CODE } from 'constants/validate';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

function SubGroupDetailModal() {
  const { groupId } = useParams();
  const [form] = Form.useForm();
  const isOverrideForm = Form.useWatch('is_override', form);
  const roomTypeIdForm = Form.useWatch('room_type_id', form);
  const checkInForm = Form.useWatch('check_in', form);

  const { isOpenSubGroupDetail, subGroupDetail, setIsOpenSubGroupDetail, setSubGroupDetail } =
    useModalStore();

  const [rangeDate, setRangeDate] = useState<RangeValueType>([null, null]);

  const { data: dataGroup } = useGetGroupBookingDetail(Number(groupId));

  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm'),
    toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm')
  );

  const { mutateAsync: mutateAddSubGroup, isPending } = useMutation({
    mutationFn: (params: ParamsAddSubGroup) => {
      return addSubGroup(Number(groupId), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
      });
    }
  });

  const { mutateAsync: mutateUpdateSubGroup, isPending: isPendingUpdate } = useMutation({
    mutationFn: (params: ParamsAddSubGroup) => {
      return updateSubgroup(Number(subGroupDetail?.id), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
      });
    }
  });

  useEffect(() => {
    if (!_.isEmpty(dataGroup)) {
      setRangeDate([
        dayjs(dataGroup?.first_arrival).set('hour', 14).set('minute', 0).set('second', 0),
        dayjs(dataGroup?.last_departure).set('hour', 12).set('minute', 0).set('second', 0)
      ]);
    }
  }, [dataGroup]);

  useEffect(() => {
    if (!isOpenSubGroupDetail) {
      form.resetFields();
    } else if (isOpenSubGroupDetail) {
      let initialValues: any = {
        check_in: dayjs(dataGroup?.first_arrival),
        check_out: dayjs(dataGroup?.last_departure)
      };
      if (!_.isEmpty(subGroupDetail)) {
        initialValues = {
          ...initialValues,
          id: subGroupDetail.id,
          check_in: dayjs(subGroupDetail?.check_in),
          check_out: dayjs(subGroupDetail?.check_out),
          room_type_id: subGroupDetail.room_type_id,
          quantity: subGroupDetail.quantity,
          rate_amount: subGroupDetail.rate_amount,
          pax: subGroupDetail.pax
        };
      }
      form.setFieldsValue(initialValues);
    }
  }, [
    dataGroup?.first_arrival,
    dataGroup?.last_departure,
    form,
    isOpenSubGroupDetail,
    subGroupDetail
  ]);

  const handleUpdate = () => {
    form.submit();
  };

  const handleCloseSubGroupDetail = () => {
    setIsOpenSubGroupDetail(false);
    setSubGroupDetail(undefined);
  };

  const handleChangeArrival = (day: Dayjs) => {
    setRangeDate([day.hour(14).minute(0).second(0).millisecond(0), rangeDate[1]]);
  };

  const handleChangeDeparture = (day: Dayjs) => {
    setRangeDate([rangeDate[0], day.hour(12).minute(0).second(0)]);
  };

  const handleChangeOverride = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      const pricelist_id = form.getFieldValue('pricelist_id');
      const roomType = availRoomTypes.find(item => item.room_type_id === roomTypeIdForm);
      const priceItem = roomType?.pricing?.find(item => item.pricelist_id === pricelist_id);
      if (priceItem) {
        form.setFieldValue('rate_amount', priceItem.price);
      } else {
        form.setFieldValue('rate_amount', roomType?.price);
      }
    }
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const startDate: Dayjs = values.check_in;
      const endDate: Dayjs = values.check_out;
      if (subGroupDetail) {
        await mutateUpdateSubGroup({
          check_in: startDate.format('YYYY-MM-DD'),
          check_out: endDate.format('YYYY-MM-DD'),
          room_type_id: values.room_type_id,
          quantity: values.quantity,
          rate_amount: values.rate_amount,
          pax: values.pax
        });
      } else {
        await mutateAddSubGroup({
          check_in: startDate.format('YYYY-MM-DD'),
          check_out: endDate.format('YYYY-MM-DD'),
          room_type_id: values.room_type_id,
          quantity: values.quantity,
          rate_amount: values.rate_amount,
          pax: values.pax
        });
      }

      notification.success({
        message: subGroupDetail ? 'Sửa Sub group thành công' : 'Thêm Sub group thành công'
      });
      form.resetFields();
      handleCloseSubGroupDetail();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const disabledRangedDate = useCallback(
    (current: Dayjs) => {
      return Boolean(
        current &&
          dataGroup?.first_arrival &&
          dataGroup?.last_departure &&
          (current.isBefore(dayjs(dataGroup?.first_arrival), 'day') ||
            current.isAfter(dayjs(dataGroup?.last_departure), 'day'))
      );
    },
    [dataGroup?.first_arrival, dataGroup?.last_departure]
  );

  const disabledRangedDateCheckOut = useCallback(
    (current: Dayjs) => {
      return Boolean(
        current &&
          dataGroup?.first_arrival &&
          dataGroup?.last_departure &&
          (current.isBefore(dayjs(dataGroup?.first_arrival), 'day') ||
            current.isAfter(dayjs(dataGroup?.last_departure), 'day') ||
            current.isBefore(checkInForm, 'day') ||
            current.isSame(checkInForm, 'day'))
      );
    },
    [checkInForm, dataGroup?.first_arrival, dataGroup?.last_departure]
  );

  const renderedData = useMemo(() => {
    return [
      {
        label: 'Name',
        value: `Đoàn ${dataGroup?.name}`
      },
      {
        label: 'Confirm#',
        value: dataGroup?.id
      },
      {
        label: 'First Arrival',
        value: dayjs(dataGroup?.first_arrival).format('DD/MM/YYYY')
      },
      {
        label: 'Last Departure',
        value: dayjs(dataGroup?.last_departure).format('DD/MM/YYYY')
      },
      {
        label: 'Code',
        value: dataGroup?.code
      },
      {
        label: 'Status',
        value: MappingColumnBookingStatus[String(dataGroup?.state)]
      }
    ];
  }, [dataGroup]);

  const roomTypeOptions = useMemo(() => {
    return availRoomTypes.map(item => ({
      label: `${item.room_type_name} (available: ${item.available_rooms} phòng)`,
      value: item.room_type_id
    }));
  }, [availRoomTypes]);

  const priceListOptions = useMemo(() => {
    const roomType = availRoomTypes.find(item => item.room_type_id === roomTypeIdForm);

    const pricingList = [
      {
        label: `Giá mặc định - ${formatCurrency(roomType?.price)}`,
        value: ''
      },
      ...(roomType?.pricing || []).map(item => ({
        label: `${item.pricelist_name} - ${formatCurrency(item.price)}`,
        value: item.pricelist_id
      }))
    ];

    return pricingList;
  }, [availRoomTypes, roomTypeIdForm]);

  const handleChangePriceId = (pricelist_id: number) => {
    const roomType = availRoomTypes.find(item => item.room_type_id === roomTypeIdForm);
    const priceItem = roomType?.pricing?.find(item => item.pricelist_id === pricelist_id);
    if (priceItem) {
      form.setFieldValue('rate_amount', priceItem.price);
    } else {
      form.setFieldValue('rate_amount', roomType?.price);
    }
  };

  return (
    <Modal
      title="Sub Group Master Availability"
      centered
      width={1000}
      open={isOpenSubGroupDetail}
      onCancel={handleCloseSubGroupDetail}
      closeIcon={<IconClose />}
      okText={!_.isEmpty(subGroupDetail) ? 'Cập nhật' : 'Thêm mới'}
      cancelText="Hủy"
      onOk={handleUpdate}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-update-sub-group-booking"
      destroyOnClose
      confirmLoading={isPending || isPendingUpdate}
    >
      <Form
        form={form}
        name="sub-group-booking-form"
        layout="horizontal"
        style={{ width: '100%', padding: '8px 0' }}
        initialValues={{}}
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row gutter={[8, 16]}>
          {renderedData.map((item, index) => (
            <Col xs={24} sm={12} lg={8} xl={6} key={index}>
              <Row gutter={[16, 16]}>
                <Col span={12} className="text-right">
                  <span>{item.label}:</span>
                </Col>
                <Col span={12}>
                  <span>{item.value}</span>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>

        <Card
          title={!_.isEmpty(subGroupDetail) ? 'Edit group lock' : 'New group block'}
          className="w-full"
          style={{ marginTop: 16 }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              {!_.isEmpty(subGroupDetail) && (
                <Form.Item label="Sub Group" name="id">
                  <Input disabled />
                </Form.Item>
              )}

              <Form.Item label="Arrival Date" name="check_in">
                <DatePicker
                  allowClear={false}
                  format="DD/MM/YYYY"
                  className="w-full"
                  onChange={handleChangeArrival}
                  disabledDate={disabledRangedDate}
                />
              </Form.Item>

              <Form.Item
                label="Room Type"
                name="room_type_id"
                rules={[
                  {
                    required: true,
                    message: MESSAGE_CODE.REQUIRED_ROOM_TYPE
                  }
                ]}
              >
                <Select options={roomTypeOptions} loading={isFetching} />
              </Form.Item>

              <Form.Item label="Rate Code" name="pricelist_id">
                <Select
                  options={priceListOptions}
                  disabled={isOverrideForm}
                  onChange={handleChangePriceId}
                />
              </Form.Item>

              <Row gutter={[8, 8]}>
                <Col span={11}>
                  <Form.Item
                    label="Rate Override"
                    name="is_override"
                    valuePropName="checked"
                    labelCol={{ span: 18 }}
                    wrapperCol={{ span: 6 }}
                    className="m-0"
                  >
                    <Checkbox onChange={handleChangeOverride} />
                  </Form.Item>
                </Col>
                <Col span={13}>
                  <Form.Item
                    name="rate_amount"
                    style={{ flex: 1 }}
                    className="m-0 text-right"
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: MESSAGE_CODE.REQUIRED_RATE_AMOUNT
                      }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      disabled={!isOverrideForm}
                      className="w-full"
                      formatter={value => formatInputNumber(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12}>
              {/* <Form.Item label="Sub Ref Code" name="sub_ref_code">
                <Select options={[]} />
              </Form.Item> */}

              <Form.Item label="Departure Date" name="check_out">
                <DatePicker
                  allowClear={false}
                  format="DD/MM/YYYY"
                  className="w-full"
                  onChange={handleChangeDeparture}
                  disabledDate={disabledRangedDateCheckOut}
                />
              </Form.Item>

              <Row>
                <Col span={12}>
                  <Form.Item
                    label="#Room"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: MESSAGE_CODE.REQUIRED_NUMBER_ROOM
                      }
                    ]}
                  >
                    <InputNumber min={0} className="w-full" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="#Pax"
                    name="pax"
                    rules={[
                      {
                        required: true,
                        message: MESSAGE_CODE.REQUIRED_PAX
                      }
                    ]}
                  >
                    <InputNumber min={0} className="w-full" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
}

export default SubGroupDetailModal;
