import React, { useState } from 'react';
import { Button, Card, DatePicker, Form, FormProps, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { columnCleaningDetail, fakeDataCleaningDetail } from 'constants/table';

function CleaningDetail() {
  const [date, setDate] = useState(null);

  const onSearch: FormProps['onFinish'] = async () => {};

  const handleChangeDate = (value: any) => {
    setDate(value);
  };

  return (
    <div className="pms-cleaning-detail">
      <Card
        title={
          <div className="pms-cleaning-detail__filter">
            <div className="pms-cleaning-detail__filter__left">
              <Form
                name="search-form"
                layout="inline"
                initialValues={{
                  search: ''
                }}
                onFinish={onSearch}
                autoComplete="off"
              >
                <Form.Item name="search" className="search-input">
                  <Input placeholder="Tìm mã đặt phòng, tên khách" />
                </Form.Item>
                <Button htmlType="submit" icon={<SearchOutlined />} className="ant-btn-secondary" />
              </Form>
              <DatePicker value={date} onChange={handleChangeDate} placeholder="Chọn ngày" />
            </div>

            <div className="pms-cleaning-detail__status-section">
              <div className="pms-cleaning-detail__status-item">
                <span className="circle circle--secondary" />
                <span>
                  S <span className="caption">(Service)</span>
                </span>
              </div>
              <div className="pms-cleaning-detail__status-item">
                <span className="circle circle--secondary" />
                <span>
                  FS <span className="caption">(Full Service)</span>
                </span>
              </div>
              <div className="pms-cleaning-detail__status-item">
                <span className="circle circle--secondary" />
                <span>
                  ARR <span className="caption">(Khách đến)</span>
                </span>
              </div>
              <div className="pms-cleaning-detail__status-item">
                <span className="circle circle--secondary" />
                <span>
                  DEP <span className="caption">(Khách đi)</span>
                </span>
              </div>
            </div>
          </div>
        }
        style={{ width: '100%' }}
      >
        <Table
          rowKey="roomId"
          columns={columnCleaningDetail}
          dataSource={fakeDataCleaningDetail}
          pagination={{
            position: ['bottomCenter'],
            total: fakeDataCleaningDetail.length,
            pageSize: 10,
            showSizeChanger: true,
            locale: { items_per_page: '/ trang' },
            showTotal: total => `${total} items`
          }}
          footer={() => (
            <div className="pms-cleaning-detail__table-footer">
              <div className="pms-cleaning-detail__table-footer__left">
                <span>S/FS/AR/DE</span>
              </div>
              <div className="pms-cleaning-detail__table-footer__right">
                <span>0/0/0/0</span>
              </div>
            </div>
          )}
          scroll={{ x: 1000 }}
          locale={{
            emptyText: <span className="empty-data">Không có dữ liệu</span>
          }}
        />
      </Card>

      {/* <div className="pms-room-status__list-pagination">
          <Pagination
            showSizeChanger
            current={currentPage}
            total={50}
            onChange={setCurrentPage}
            locale={{ items_per_page: "/ trang" }}
            showTotal={(total) => `${total} items`}
          />
        </div> */}
    </div>
  );
}

export default CleaningDetail;
