import React from 'react';
import { Button } from 'antd';

import { SubHKRoom } from 'services/api/type/hk.type';
import { ReactComponent as IconArrowLeft } from 'assets/images/arrow-left.svg';

interface Props {
  selectedRoom?: SubHKRoom;
  onBack?: () => void;
}

function SubHKHeaderInspectMobile({ selectedRoom, onBack }: Props) {
  const handleBack = () => {
    onBack && onBack();
  };

  return (
    <header className="pms-app-sub-hk-header-mobile pms-app-header-mobile--inspect">
      <div className="header-inner">
        <div className="flex items-center" style={{ gap: '16px' }}>
          <Button
            className="btn-back"
            type="link"
            icon={<IconArrowLeft />}
            onClick={handleBack}
          ></Button>
        </div>

        <h4 className="header-title">
          {selectedRoom?.room_type_name} {selectedRoom?.attributes.room_no}
        </h4>
      </div>
    </header>
  );
}

export default SubHKHeaderInspectMobile;
