import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { CountryType, AreaType, CompanyType, SourceType } from '../type/common.type';

export const getCountries = (): Promise<CountryType[]> => {
  return axiosInstance.get(paths.getCountries()).then(item => item.data.data);
};

export const getAreas = (countryId: number): Promise<AreaType[]> => {
  return axiosInstance.get(paths.getAreas(countryId)).then(item => item.data.data);
};

export const getCompanies = (): Promise<CompanyType[]> => {
  return axiosInstance.get(paths.getCompanies()).then(item => item.data.data);
};

export const getSources = (): Promise<SourceType[]> => {
  return axiosInstance.get(paths.getSources()).then(item => item.data.data);
};
