import _ from 'lodash';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Checkbox, CheckboxProps, Collapse } from 'antd';

import useBookingStore from 'stores/useBooking';
import useModalStore from 'stores/useModal';
import { BookingStatus, GroupBookingLineItem } from 'services/api/type/booking.type';
import { MAP_ICON_ROOM_STATUS } from 'constants/icon';

interface Props {
  title: string;
  activeBookingLineId: number | undefined;
  data: GroupBookingLineItem[];
}

const { Panel } = Collapse;

function GroupTabCollapse({ title, data, activeBookingLineId }: Props) {
  const {
    checkedListBookingLineId,
    addCheckListBookingLineId,
    removeCheckListBookingLineId,
    addCheckListBookingLineIds,
    removeCheckListBookingLineIds,
    setBookingLineId
  } = useBookingStore();
  const { setBookingLineId: setBookingLineIdModal } = useModalStore();

  const ids = useMemo(() => {
    return data.map(item => item.id);
  }, [data]);

  const intersectionIds = useMemo(() => {
    return _.intersection(checkedListBookingLineId, ids);
  }, [checkedListBookingLineId, ids]);

  const checkAll = data.length === intersectionIds.length;
  const indeterminate = intersectionIds.length > 0 && intersectionIds.length < data.length;

  const onCheckAllChange: CheckboxProps['onChange'] = e => {
    if (e.target.checked) {
      addCheckListBookingLineIds(ids);
    } else {
      console.log(_.difference(checkedListBookingLineId, ids));
      removeCheckListBookingLineIds(ids);
    }
  };

  const handleChangeCheckBox = (e: any) => {
    if (e.target.checked) {
      addCheckListBookingLineId(e.target.value);
    } else {
      removeCheckListBookingLineId(e.target.value);
    }
  };

  const handleClickBookingItem = (bookingLineId: number) => {
    setBookingLineId(bookingLineId);
    setBookingLineIdModal(bookingLineId);
  };

  return (
    <div className="group-tab-collapse">
      <Collapse defaultActiveKey={'1'}>
        <Panel
          header={
            <div className="flex items-center" style={{ gap: 8 }}>
              <Checkbox
                onClick={e => e.stopPropagation()}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              />
              <span>{title}</span>
            </div>
          }
          key="1"
        >
          {!_.isEmpty(data) &&
            data.map(item => {
              const IconStatus = MAP_ICON_ROOM_STATUS[item.status];
              //
              return (
                <div
                  className={classNames('list-item', {
                    'list-item--active': item.id === activeBookingLineId
                  })}
                  key={item.id}
                  onClick={() => handleClickBookingItem(item.id)}
                >
                  <Checkbox
                    onClick={e => e.stopPropagation()}
                    checked={checkedListBookingLineId.includes(item.id)}
                    value={item.id}
                    onChange={handleChangeCheckBox}
                  />
                  <div className="flex items-center" style={{ gap: 4 }}>
                    {IconStatus && (
                      <IconStatus
                        className={classNames('icon-status flex-shrink-0', {
                          'icon-status--confirm': item.status === BookingStatus.CONFIRM,
                          'icon-status--checkin': item.status === BookingStatus.CHECK_IN,
                          'icon-status--checkout': item.status === BookingStatus.CHECK_OUT
                        })}
                      />
                    )}{' '}
                    <span>{item?.room_name || item?.room_type_name + ' N/A'}</span>
                  </div>
                </div>
              );
            })}
        </Panel>
      </Collapse>
    </div>
  );
}

export default GroupTabCollapse;
