// create axios instance for API_URL env
import axios from 'axios';
import { clearToken, getToken, getURL, getBranch } from 'services/storages/userStorage';

const baseURL =
  getURL() || process.env.REACT_APP_API_URL || 'http://ib-odoo-pms-12736990.dev.odoo.com/api';

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// add token to interceptors
axiosInstance.interceptors.request.use(
  config => {
    const accessToken = getToken();
    const branchId = getBranch();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}${branchId ? '|' + branchId : ''}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  data => data,
  error => {
    if (error.response.status === 401) {
      clearToken();
      window.location.reload();
    }
    return Promise.reject(error.response.data);
  }
);
