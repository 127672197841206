import { Text, View } from '@react-pdf/renderer';

import { BranchesType } from 'services/api/type/branch.type';
import Logo from './logo';

import styles from '../style-sheet';

interface IHotelInfoProps {
  currentBranch?: BranchesType;
}

const HotelInfo = ({ currentBranch }: IHotelInfoProps) => (
  <View style={styles.titleContainer}>
    <View style={styles.spaceBetween}>
      <View>
        <Text style={{ ...styles.textBold, textTransform: 'uppercase' }}>
          {currentBranch?.name}
        </Text>
        <Text style={styles.text}>Address: {currentBranch?.address}</Text>
        <Text style={styles.text}>
          {currentBranch?.phone_contact ? `Tel: ${currentBranch?.phone_contact}` : ''}
        </Text>
      </View>
      <Logo />
    </View>
  </View>
);

export default HotelInfo;
