import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';

export const useOpenBookingDetail = () => {
  const { setBookingLineId: setBookingLineId } = useBookingStore();
  const { setOpenBookingDetail } = useModal();

  const openBookingDetail = (bookingId: number) => {
    setBookingLineId(bookingId);
    setOpenBookingDetail(true, bookingId);
  };

  return { openBookingDetail };
};
