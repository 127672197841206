import dayjs from 'dayjs';

export const diffDaysWithSignal = (date1?: string, date2?: string): string => {
  if (!date1 || !date2) {
    return '';
  }
  const diff = dayjs(date1).diff(dayjs(date2), 'day');
  if (diff === 0) {
    return '';
  }
  if (diff > 0) {
    return `+${diff}`;
  }
  return `${diff}`;
};
