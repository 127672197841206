import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { OcrCustomerInfoResponse, ParamsType } from '../type/ocr-customer-info.type';

export const scanCustomerInfo = async (params: ParamsType): Promise<OcrCustomerInfoResponse> => {
  return axiosInstance.post(paths.scanCustomerInfo(), params).then(response => response.data.data);
};

export const getLatestOcrCustomerInfo = async (
  date_from: string
): Promise<OcrCustomerInfoResponse> => {
  return axiosInstance
    .get(paths.scanCustomerInfo(), {
      params: {
        date_from: date_from
      }
    })
    .then(response => response.data);
};
