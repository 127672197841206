import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import {
  ParamsAddSubGroup,
  ParamsChangeDate,
  ParamsGenerateRooming,
  ParamsGroupBooking,
  ResponseGenerateRooming,
  ResponseGroupBookingDetail,
  ResponseGroupBookingsWithOutPaging,
  ResponseGroupBookingsWithPaging
} from '../type/group.type';

export const createGroupBooking = (params: ParamsGroupBooking): Promise<any> => {
  return axiosInstance.post(paths.createGroupBooking(), params).then(item => item.data.data);
};

export const updateGroupBooking = (
  groupId: number,
  params: Partial<ParamsGroupBooking>
): Promise<any> => {
  return axiosInstance.put(paths.updateGroupBooking(groupId), params).then(item => item.data.data);
};

export const cancelGroupBooking = (groupId: number): Promise<any> => {
  return axiosInstance.put(paths.cancelGroupBooking(groupId)).then(item => item.data.data);
};

export const getGroupBookings = ({
  limit,
  page,
  search,
  filterOptions
}: {
  limit?: number;
  search?: string;
  page: number;
  filterOptions?: {
    first_arrival?: string;
    last_departure?: string;
    short_code?: string;
  };
}): Promise<ResponseGroupBookingsWithPaging> => {
  return axiosInstance
    .get(paths.getGroupBookings(), {
      params: {
        limit,
        page,
        search,
        ...filterOptions
      }
    })
    .then(item => item.data);
};

export const getGroupsBookingDetails = ({
  filterOptions
}: {
  filterOptions?: {
    first_arrival?: string;
    last_departure?: string;
    short_code?: string;
  };
}): Promise<ResponseGroupBookingsWithOutPaging> => {
  return axiosInstance
    .get(paths.getSubGroupsDetails(), {
      params: {
        ...filterOptions
      }
    })
    .then(item => item.data);
};

export const getGroupBookingDetail = (groupId: number): Promise<ResponseGroupBookingDetail> => {
  return axiosInstance.get(paths.getGroupBookingDetail(groupId)).then(item => item.data.data);
};

export const addSubGroup = (groupId: number, params: ParamsAddSubGroup): Promise<any> => {
  return axiosInstance.post(paths.addSubGroup(groupId), params).then(item => item.data.data);
};

export const generateRooming = (
  params: ParamsGenerateRooming
): Promise<ResponseGenerateRooming> => {
  return axiosInstance.post(paths.generateRooming(), params).then(item => item.data.data);
};

export const updateSubgroup = (subGroupId: number, params: ParamsAddSubGroup): Promise<any> => {
  return axiosInstance.put(paths.updateSubgroup(subGroupId), params).then(item => item.data.data);
};

export const changeDateSubgroup = (groupId: number, params: ParamsChangeDate): Promise<any> => {
  return axiosInstance.post(paths.changeDateSubgroup(groupId), params).then(item => item.data.data);
};

export const cancelSubgroup = (subGroupId: number, params: ParamsAddSubGroup): Promise<any> => {
  return axiosInstance.post(paths.cancelSubgroup(subGroupId), params).then(item => item.data.data);
};
