import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import get from 'lodash/get';
import QUERY_KEYS from 'services/api/queryKeys';
import { ParamsGetRoomLock, RoomLockType } from 'services/api/type/room.type';
import { PaginationType } from 'services/api/type/booking.type';

export const useGetRoomLock = (filterOptions: ParamsGetRoomLock, isEnable: boolean = true) => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ROOM_LOCK, filterOptions],
    queryFn: () => API.room.getRoomLock(filterOptions),
    enabled: isEnable
  });

  return {
    data: get(data, 'data', []) as RoomLockType[],
    pagination: get(data, 'pagination', {}) as PaginationType,
    isFetching,
    isLoading
  };
};
