import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetListMinibar = () => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_LIST_MINIBAR],
    queryFn: () => API.hk.getListMinibar()
  });

  return {
    data: data || [],
    isLoading,
    isFetching
  };
};
