import { useState } from 'react';
import useModal from 'stores/useModal';

import { Button, Card, Form, FormProps, Input, Table } from 'antd';

import { useGetTravelAgencies } from 'hooks/useGetTravelAgencies';
import { columnTravelAgency } from 'constants/table';
import { PAGE_SIZE_TRAVEL_AGENCY } from 'services/api/module/travel-agency.api';
import UpdateAndDeleteTravelAgency from 'components/modal/travel-agency-component/update-and-delete-travel-agency-modal';
import CreateTravelAgency from 'components/modal/travel-agency-component/create-travel-agency-modal';

import 'styles/travel-agency.scss';

function TravelAgency() {
  const [form] = Form.useForm();

  const {
    isOpenCreateTravelAgency,
    setIsOpenCreateTravelAgency,
    isOpenUpdateAndDeleteTravelAgency,
    setIsOpenUpdateAndDeleteTravelAgency
  } = useModal();
  const [selectValues, setSelectValues] = useState({});
  const [travelAgencyId, setTravelAgencyId] = useState<string>('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE_TRAVEL_AGENCY);
  const [page, setPage] = useState<number>(1);

  const {
    data: TravelAgencies,
    pagination,
    isLoading
  } = useGetTravelAgencies(selectValues, pageSize, page);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    if (truthyValues) {
      setSelectValues(truthyValues);
    }
    if (page !== 1) {
      setPage(1);
    }
  };

  const onHandleCreateNewTravelAgency = () => {
    setIsOpenCreateTravelAgency(true);
  };

  const onCancelCreateTravelAgency = () => {
    setIsOpenCreateTravelAgency(false);
  };

  const onHandleUpdateAndDeleteTravelAgency = (id: string) => {
    setIsOpenUpdateAndDeleteTravelAgency(true);
    setTravelAgencyId(id);
  };

  const onCancelUpdateAndDeleteTravelAgency = () => {
    setIsOpenUpdateAndDeleteTravelAgency(false);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    setSelectValues({ ...selectValues, page, limit: pageSize });
  };

  return (
    <div className="pms-travel-agency">
      <Card title="Danh sách công ty/ TA" style={{ width: '100%' }}>
        <Form form={form} layout={'inline'} onFinish={onFinish} autoComplete="off">
          <div className="pms-travel-agency__filter-wrapper">
            <div className="pms-travel-agency__filter">
              <Form.Item name="id">
                <Input placeholder="Mã công ty" />
              </Form.Item>
              <Form.Item name="name">
                <Input placeholder="Tên công ty" />
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
            <Button onClick={() => onHandleCreateNewTravelAgency()}>Tạo công ty</Button>
            <CreateTravelAgency
              isOpen={isOpenCreateTravelAgency}
              onCancel={() => onCancelCreateTravelAgency()}
            />
            <UpdateAndDeleteTravelAgency
              isOpen={isOpenUpdateAndDeleteTravelAgency}
              onCancel={onCancelUpdateAndDeleteTravelAgency}
              travelAgencyId={travelAgencyId}
            />
          </div>
        </Form>
        <div className="pms-travel-agency__table">
          <Table
            loading={isLoading}
            rowKey="id"
            size="small"
            columns={columnTravelAgency}
            dataSource={TravelAgencies}
            onRow={record => {
              return {
                onClick: () => {
                  onHandleUpdateAndDeleteTravelAgency(String(record.id));
                }
              };
            }}
            bordered
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showLessItems: true,
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 2700, y: 'calc(100vh - 400px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default TravelAgency;
