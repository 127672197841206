import { notification } from 'antd';
import { BATCH_ACTION_TYPE } from 'services/api/type/booking.type';

const createBatchActionHandler = (
  generateConfirm: (
    actionType: BATCH_ACTION_TYPE,
    confirmTitle: string,
    successMessage: string
  ) => void
) => {
  return async (actionType: BATCH_ACTION_TYPE, confirmTitle: string, successMessage: string) => {
    generateConfirm(actionType, confirmTitle, successMessage);
  };
};

export const handleBookingsBatchActions = async (
  currentAction: BATCH_ACTION_TYPE,
  generateConfirm: (
    actionType: BATCH_ACTION_TYPE,
    confirmTitle: string,
    successMessage: string
  ) => void
) => {
  const handler = createBatchActionHandler(generateConfirm);

  switch (currentAction) {
    case BATCH_ACTION_TYPE.AUTO_ASSIGN:
      await handler(BATCH_ACTION_TYPE.AUTO_ASSIGN, 'Xác nhận gán phòng', 'Gán phòng thành công');
      break;

    case BATCH_ACTION_TYPE.CHECK_OUT:
      await handler(BATCH_ACTION_TYPE.CHECK_OUT, 'Xác nhận trả phòng', 'Trả phòng thành công');
      break;

    case BATCH_ACTION_TYPE.CHECK_IN:
      await handler(BATCH_ACTION_TYPE.CHECK_IN, 'Xác nhận nhận phòng', 'Nhận phòng thành công');
      break;

    case BATCH_ACTION_TYPE.UNASSIGN:
      await handler(
        BATCH_ACTION_TYPE.UNASSIGN,
        'Xác nhận hủy gán phòng',
        'Hủy gán phòng thành công'
      );
      break;

    default:
      notification.error({ message: 'Action not recognized' });
  }
};
