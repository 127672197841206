import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  value: any;
  onValueChange: (value: any) => void;
  arrival?: Dayjs;
  departure?: Dayjs;
}

const DatePickerEditor = ({ value, arrival, departure, onValueChange }: Props) => {
  const [open, setIsOpen] = useState(false);
  const handleChangeValue = (value: any) => {
    onValueChange(value === '' ? null : value);
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const disabledRangedDate = useCallback(
    (current: Dayjs) => {
      return Boolean(
        current &&
          arrival &&
          departure &&
          (current.isBefore(arrival, 'day') || current.isAfter(departure, 'day'))
      );
    },
    [arrival, departure]
  );

  return (
    <DatePicker
      open={open}
      autoFocus
      defaultValue={dayjs(value)}
      className="date-picker-editor w-full h-full"
      style={{ border: 0, boxShadow: 'none' }}
      onChange={handleChangeValue}
      format="DD/MM/YYYY"
      onOpenChange={setIsOpen}
      disabledDate={disabledRangedDate}
    />
  );
};

export default DatePickerEditor;
