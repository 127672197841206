import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import * as FileSaver from 'file-saver';
import { NationalityStatisticType } from 'services/api/type/report.type';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';

interface Props {
  fileName: string;
  filterOptions: any;
  quarterType: any;
  dataToExport: NationalityStatisticType[];
  totalCustomers: number;
  totalMales: number;
  totalFemales: number;
}

const ExportNationalityStatistic = ({
  fileName,
  filterOptions,
  quarterType,
  dataToExport,
  totalCustomers,
  totalMales,
  totalFemales
}: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const filterOptionsObject = filterOptions.filterOptions;

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();

      const sheetName = `Quarter_${dayjs
        .utc(filterOptionsObject.start_date)
        .local()
        .format('DD-MM-YYYY')}_${dayjs
        .utc(filterOptionsObject.end_date)
        .local()
        .format('DD-MM-YYYY')}`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      const startDateString = filterOptionsObject.start_date;
      const startDate = new Date(startDateString);
      const year = startDate.getFullYear();

      sheet.mergeCells('A2:E2');
      sheet.getCell('A2').value = 'Phụ lục báo cáo';
      sheet.getCell('A2').font = { size: 12 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };
      // Support subtitle
      sheet.mergeCells('A3:E3');
      sheet.getCell('A3').value =
        `Tình hình, kết quả kinh doanh dịch vụ lưu trú - Quý ${quarterType} năm ${year}`;
      sheet.getCell('A3').font = { size: 12 };
      sheet.getCell('A3').alignment = { horizontal: 'center' };
      sheet.mergeCells('A4:E4');
      sheet.getCell('A4').value = '(Đính kèm mẫu ĐK13 ban hành kèm theo TT số 42/2017/TT-BCA)';
      sheet.getCell('A4').font = { italic: true, size: 12 };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      sheet.getCell('A4').alignment = { horizontal: 'center' };
      sheet.getCell('A6').alignment = { horizontal: 'center' };
      sheet.getCell('A7').alignment = { horizontal: 'center' };

      // Header columns
      sheet.getRow(6).values = [
        'Phân tích Quốc gia',
        'Số lượng khách lưu trú',
        'Nam',
        'Nữ',
        'Ghi chú'
      ];
      sheet.getRow(6).eachCell(function (cell) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' }; // separate alignment setting
      });

      // Mark column
      sheet.getRow(7).values = ['[1]', '[2]', '[3]', '[4]', '[5]'];
      sheet.getRow(7).eachCell(function (cell) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        cell.alignment = { horizontal: 'center' }; // separate alignment setting
      });

      sheet.columns = [
        {
          key: 'country_name',
          width: 25,
          style: { alignment: { horizontal: 'center' } }
        },
        { key: 'count', width: 30, style: { alignment: { horizontal: 'center' } } },
        { key: 'male', width: 10, style: { alignment: { horizontal: 'center' } } },
        { key: 'female', width: 10, style: { alignment: { horizontal: 'center' } } },
        { key: 'note', width: 20, style: { alignment: { horizontal: 'center' } } }
      ];

      dataToExport.forEach(item => {
        const row = sheet.addRow({
          country_name: item.country_name,
          count: item.count,
          male: item.gender.male,
          female: item.gender.female,
          note: ''
        });

        // Apply border to each cell in the row
        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      const totalRowIndex = dataToExport.length + 8; // Adjust the index based on data length

      ['A', 'B', 'C', 'D', 'E'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      });

      sheet.getCell(`A${totalRowIndex}`).value = 'Tổng số';
      sheet.getCell(`A${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`B${totalRowIndex}`).value = totalCustomers;
      sheet.getCell(`C${totalRowIndex}`).value = totalMales;
      sheet.getCell(`D${totalRowIndex}`).value = totalFemales;

      sheet.mergeCells(`C${totalRowIndex + 2}:E${totalRowIndex + 2}`);
      sheet.mergeCells(`C${totalRowIndex + 3}:E${totalRowIndex + 3}`);
      sheet.mergeCells(`C${totalRowIndex + 4}:E${totalRowIndex + 4}`);
      sheet.getCell(`C${totalRowIndex + 2}`).value = 'ĐẠI DIỆN CƠ SỞ KINH DOANH'; // Set values for each cell
      sheet.getCell(`C${totalRowIndex + 3}`).value =
        '............, ngày ..... tháng ..... năm ......'; // Set values for each cell
      sheet.getCell(`C${totalRowIndex + 4}`).value = '(Ký, ghi rõ họ tên, đóng dấu - nếu có)'; // Set values for each cell
      sheet.getCell(`C${totalRowIndex + 4}`).font = { italic: true };

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        Xuất dữ liệu
      </Button>
    </Dropdown>
  );
};

export default ExportNationalityStatistic;
