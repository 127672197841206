import { Select } from 'antd';
import { GENDER_OPTIONS } from 'constants/form';

interface Props {
  value: any;
  onValueChange: (value: any) => void;
}

const GenderPickerEditor = ({ value, onValueChange }: Props) => {
  return (
    <Select
      value={value}
      options={GENDER_OPTIONS}
      style={{ minWidth: 90 }}
      onChange={value => onValueChange(value === '' ? null : value)}
    />
  );
};

export default GenderPickerEditor;
