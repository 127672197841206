import { View, StyleSheet, Text } from '@react-pdf/renderer';

const borderColor = '#000';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    height: 45
  },
  date: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  detail: {
    width: '21%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  nlte: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  price: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  services: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  serviceFee: {
    width: '12%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  tax: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  amount: {
    width: '12%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  text: { fontSize: 11, fontFamily: 'Inter', fontWeight: 500 }
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <View style={styles.detail}>
      <Text style={styles.text}>Phòng/Khách</Text>
      <Text style={styles.text}>(Room/Guest)</Text>
    </View>
    <View style={styles.date}>
      <Text style={styles.text}>Ngày đến - đi</Text>
      <Text style={styles.text}>(Arrival - Departure)</Text>
    </View>
    <View style={styles.nlte}>
      <Text style={styles.text}>NL/TE</Text>
      <Text style={styles.text}>(Adult/Children)</Text>
    </View>
    <View style={styles.price}>
      <Text style={styles.text}>Tiền phòng</Text>
      <Text style={styles.text}>(Room charge)</Text>
    </View>
    <View style={styles.services}>
      <Text style={styles.text}>Dịch vụ</Text>
      <Text style={styles.text}>(Services)</Text>
    </View>
    <View style={styles.serviceFee}>
      <Text style={styles.text}>Phí dịch vụ</Text>
      <Text style={styles.text}>(Services charge)</Text>
    </View>
    <View style={styles.tax}>
      <Text style={styles.text}>Thuế</Text>
      <Text style={styles.text}>(Tax)</Text>
    </View>
    <View style={styles.amount}>
      <Text style={styles.text}>Cần thanh toán</Text>
      <Text style={styles.text}>(Amount)</Text>
    </View>
    <View style={styles.amount}>
      <Text style={styles.text}>Đã</Text>
      <Text style={styles.text}>thanh toán</Text>
      <Text style={styles.text}>(Paid money)</Text>
    </View>
    <View style={{ ...styles.amount, borderRightWidth: 0 }}>
      <Text style={styles.text}>Còn lại</Text>
      <Text style={styles.text}>(Remaining amount)</Text>
    </View>
  </View>
);

export default InvoiceTableHeader;
