import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Button, Form, FormProps, Input, List, Radio, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { useGetPmsUser } from 'hooks/useGetPmsUser';

import { shiftAssign } from 'services/api/module/hk.api';
import { ShiftAssignParams, SubHKRoom } from 'services/api/type/hk.type';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { slugify } from 'utils';
import { JobCode } from 'services/api/type/user.type';
import 'styles/mobile/sub-hk-inspect.scss';

interface Props {
  selectedRooms: SubHKRoom[];
  onBack?: () => void;
}

function ShiftDivisionForm({ selectedRooms, onBack }: Props) {
  const [form] = Form.useForm();
  const takerForm = Form.useWatch('taker', form);
  const [search, setSearch] = useState('');

  const { data: staffs } = useGetPmsUser();

  const { mutateAsync: mutateShiftAssign, isPending: isPendingAssign } = useMutation({
    mutationFn: (params: ShiftAssignParams) => shiftAssign(params)
  });

  const handleEnd = async () => {
    form.submit();
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (!_.isEmpty(selectedRooms)) {
      try {
        await mutateShiftAssign({
          rooms: selectedRooms.map(room => ({
            room_id: room.room_id
          })),
          hk_id: values.taker,
          date: dayjs().format('YYYY-MM-DD')
        });

        onBack && onBack();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_SUB_HK_ROOM]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const filteredList = useMemo(() => {
    return staffs.filter(
      staff =>
        staff.employee.job_code === JobCode.HK &&
        slugify(staff.name).toLowerCase().trim().includes(search?.toLowerCase()?.trim())
    );
  }, [search, staffs]);

  return (
    <>
      <div className="form-container">
        <p className="title" style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
          Danh sách phòng:{' '}
          {selectedRooms.map((room, index) => (
            <b key={room.room_id}>
              {room.room_name}
              {index !== selectedRooms.length - 1 ? ', ' : ''}
            </b>
          ))}
        </p>

        <div style={{ marginBottom: 16 }}>
          <Input
            allowClear
            value={search}
            onChange={handleChangeSearch}
            placeholder="Tìm kiếm theo tên"
          />
        </div>

        <Form
          form={form}
          name="shift-division-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            taker: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <List>
            <Form.Item label="Người nhận ca" name="taker">
              <Radio.Group>
                {filteredList.map(item => (
                  <Radio value={item.id} key={item.id}>
                    <List.Item>{item.name}</List.Item>
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </List>
        </Form>
      </div>

      <div className="action-wrapper-fixed">
        <div className="inner">
          <Button
            className="ant-btn-secondary w-full"
            loading={isPendingAssign}
            disabled={!takerForm}
            onClick={handleEnd}
          >
            Phân ca
          </Button>
        </div>
      </div>
    </>
  );
}

export default ShiftDivisionForm;
