import dayjs from 'dayjs';
import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import {
  HKCheckMinibarParams,
  MinibarItem,
  ShiftAssignParams,
  StaffType,
  SubHKRoom
} from '../type/hk.type';

export const getSubHKRoom = async (): Promise<SubHKRoom[]> => {
  return axiosInstance
    .get(paths.getSubHKRooms(), {
      params: {
        date: dayjs().format('YYYY-MM-DD')
      }
    })
    .then(item => item.data.data);
};

export const getHKRoom = async (): Promise<SubHKRoom[]> => {
  return axiosInstance
    .get(paths.getHKRooms(), {
      params: {
        date: dayjs().format('YYYY-MM-DD')
      }
    })
    .then(item => item.data.data);
};

export const shiftAssign = async (params: ShiftAssignParams): Promise<any> => {
  return axiosInstance.post(paths.shiftAssign(), params).then(item => item.data.data);
};

export const getPmsUser = async (): Promise<StaffType[]> => {
  return axiosInstance.get(paths.getPmsUsers()).then(item => item.data.data);
};

export const getListMinibar = async (): Promise<MinibarItem[]> => {
  return axiosInstance.get(paths.getListMinibar()).then(item => item.data.data);
};

export const hkCheckMinibar = async (params: HKCheckMinibarParams): Promise<any> => {
  return axiosInstance.post(paths.hkCheckMinibar(), params).then(item => item.data.data);
};

export const hkStartCleaning = async (shift_id: number): Promise<any> => {
  return axiosInstance.post(paths.hkStartCleaning(), { shift_id }).then(item => item.data.data);
};

export const hkFinishCleaning = async (params: any): Promise<any> => {
  return axiosInstance.post(paths.hkFinishCleaning(), params).then(item => item.data.data);
};

export const subHKApproveCleaning = async (params: any): Promise<any> => {
  return axiosInstance.post(paths.subHKApproveCleaning(), params).then(item => item.data.data);
};

export const subHKFillMinibar = async (params: HKCheckMinibarParams): Promise<any> => {
  return axiosInstance.post(paths.subHKFillMinibar(), params).then(item => item.data.data);
};
