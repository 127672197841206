import React, { useEffect, useMemo } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import Header from 'components/common/header';
import NavigateBar, { WITHOUT_NAVIGATION_BAR_URL } from 'components/common/navigate-bar';
import AssignRoomModal from 'components/modal/assign-room-modal';
import ChangeRoomTypeModal from 'components/modal/change-room-type-modal';
import CancelAssignRoomModal from 'components/modal/cancel-assign-room-modal';
import CancelRoomModal from 'components/modal/cancel-room-modal';
import ConfirmModal from 'components/modal/confirm-modal';
import CustomerModal from 'components/modal/customer-modal';
import ServiceModal from 'components/modal/service-modal';
import ChangeRoomModal from 'components/modal/change-room-modal';
import ChangeDateModal from 'components/modal/change-date-modal';
import ChangePriceModal from 'components/modal/change-price-modal';
import ChangePriceGroupModal from 'components/modal/change-price-group-modal';
import BookingLineDetailModal from 'components/modal/booking-line-detail-modal';
import ConfirmDeletePaymentModal from 'components/modal/confirm-delete-payment-modal';
import AddExpenseModal from 'components/modal/add-expense-modal';
import ResolveDocumentModal from 'components/modal/resolve-document-modal';
import FixRoomModal from 'components/modal/fix-room-modal';
import SetBreakfastModal from 'components/modal/set-breakfast-modal';
import UndoCheckInModal from 'components/modal/undo-checkin-modal';
import UndoCheckOutModal from 'components/modal/undo-checkout-modal';
import BillPDFModal from 'components/modal/bill-pdf-modal';
import BillGroupPDFModal from 'components/modal/bill-group-pdf-modal';
import ConfirmationPDFModal from 'components/modal/confirmation-pdf-modal';
import SignatureModal from 'components/modal/signature-modal';
import TrackingLogsModal from 'components/modal/tracking-logs-modal';
import ChargeDebtPaymentModal from 'components/modal/charge-debt-payment-modal';
import PaymentModal from 'components/modal/payment-modal';
import GroupRoomingListModal from 'components/modal/group/group-rooming-list-modal';
import SubGroupDetailModal from 'components/modal/group/sub-group-detail-modal';
import ChangeDateGroupBookingModal from 'components/modal/group/change-date-group-booking-modal';
import PostRoomChargeModal from 'components/modal/post-room-charge';

import { useGetMe } from 'hooks/useGetMe';

import { logPageView } from 'services/tracking/gaTracking';
import MoveTransaction from 'components/modal/transaction-modal/move-transaction';
import PostPaymentTransaction from 'components/modal/transaction-modal/post-payment-transaction';
import CancelTransactions from 'components/modal/transaction-modal/cancel-transactions';
import PostRefundTransaction from 'components/modal/transaction-modal/refund-payment-transaction';
import AddTransactionExpenseModal from 'components/modal/add-expense-transaction-modal';
import ConfirmDeleteExpenseTransactionModal from 'components/modal/confirm-delete-expense-transaction-modal';
import CreateInvoice from 'components/modal/transaction-modal/create-invoice-modal';
import PrintInvoiceModalV2 from 'components/modal/transaction-modal/print-invoice-v2';

function App() {
  const location = useLocation();

  const { isLoading } = useGetMe();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  const withoutSubmenu = useMemo(() => {
    // if (matchPath(PAGES.stayingGuests, location.pathname)) {
    //   return true;
    // }
    if (WITHOUT_NAVIGATION_BAR_URL.some(item => matchPath(item, location.pathname))) {
      return true;
    }
    return false;
  }, [location.pathname]);

  if (isLoading) {
    return <Spin fullscreen indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />;
  }

  return (
    <div className="pms-app">
      <Header />
      <NavigateBar />
      <main
        className={classNames('pms-app-content', {
          'pms-app-content--without-sub': withoutSubmenu
        })}
      >
        <Outlet />
      </main>

      <AssignRoomModal />
      <ChangeRoomTypeModal />
      <CancelRoomModal />
      <CancelAssignRoomModal />
      <ConfirmModal />
      <CustomerModal />
      <ServiceModal />
      <ChangeRoomModal />
      <ChangeDateModal />
      <ChangePriceModal />
      <ChangePriceGroupModal />
      <BookingLineDetailModal />
      <FixRoomModal />
      <ConfirmDeletePaymentModal />
      <ConfirmDeleteExpenseTransactionModal />
      <UndoCheckInModal />
      <UndoCheckOutModal />

      <AddExpenseModal />
      <AddTransactionExpenseModal />
      <ResolveDocumentModal />
      <SetBreakfastModal />
      <CreateInvoice />
      <PrintInvoiceModalV2 />

      <BillPDFModal />
      <BillGroupPDFModal />
      <ConfirmationPDFModal />
      <SignatureModal />
      <TrackingLogsModal />
      <ChargeDebtPaymentModal />
      <PaymentModal />

      <GroupRoomingListModal />
      <SubGroupDetailModal />
      <ChangeDateGroupBookingModal />
      <PostRoomChargeModal />
      <MoveTransaction />
      <PostPaymentTransaction />
      <CancelTransactions />
      <PostRefundTransaction />
    </div>
  );
}

export default App;
