import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Space, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { BranchesType } from 'services/api/type/branch.type';
import 'styles/branches-dropdown.scss';
import { setBranch, getBranch } from 'services/storages/userStorage';
import { useGetAllBranches } from 'hooks/useGetAllBranches';

// Function to generate menu items
const generateMenuItems = (
  branches: BranchesType[],
  loading: boolean,
  handleMenuClick: (branch: BranchesType) => void
): MenuProps['items'] => {
  if (loading) {
    return [
      {
        label: <Spin size="small" />,
        key: 'loading'
      }
    ];
  }
  return branches.map(branch => ({
    label: (
      <span onClick={() => handleMenuClick(branch)}>
        {branch.id} - {branch.name}
      </span>
    ),
    key: branch.id
  }));
};

const BranchesDropdown: React.FC = () => {
  const { data: branches, isLoading: loading } = useGetAllBranches();
  const [selectedBranch, setSelectedBranch] = useState<BranchesType | null>(null);

  useEffect(() => {
    if (branches && branches.length > 0) {
      const storedBranchId = getBranch();
      const branch = branches.find(branch => branch.id === storedBranchId) || branches[0];
      setSelectedBranch(branch);
      setBranch(branch.id);
    }
  }, [branches]);

  const handleMenuClick = (branch: BranchesType) => {
    setSelectedBranch(branch);
    setBranch(branch.id);
    window.location.reload();
  };

  const items = generateMenuItems(branches || [], loading, handleMenuClick);

  return (
    <Dropdown menu={{ items }} trigger={['click']} className="customDropdown">
      <Button className="customButton">
        <Space>
          {selectedBranch ? `${selectedBranch.id} - ${selectedBranch.name}` : 'Chọn chi nhánh'}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default BranchesDropdown;
