import React, { useCallback, useMemo, useState } from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  Select,
  Tooltip
} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  IdcardOutlined,
  PhoneFilled,
  UndoOutlined,
  UserOutlined,
  HomeFilled,
  ReloadOutlined
} from '@ant-design/icons';

import CustomerPhotos from './customer-photos';
import EditCustomerModal from 'components/modal/edit-customer-modal';
import Photos from 'components/common/photos';

import { useGetCountries } from 'hooks/useGetCountries';
import { useGetCustomers } from 'hooks/useGetCustomers';
import { useGetAreas } from 'hooks/useGetAreas';

import { ReactComponent as IconMale } from 'assets/images/male.svg';
import { ReactComponent as IconFemale } from 'assets/images/female.svg';
import { ReactComponent as IconEmail } from 'assets/images/email.svg';
import { ReactComponent as IconRegisterCard } from 'assets/images/register-card.svg';
import { ReactComponent as IconCake } from 'assets/images/cake.svg';

import { PersonalInfoType } from 'services/api/type/room.type';
import { SEX } from 'constants/form';
import { EmailRegex } from 'constants/regex';
import { MESSAGE_CODE } from 'constants/validate';
import { disabledDateFuture, trimTruthyValues } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import './CustomerForm.scss';
import { BookingLine } from 'services/api/type/booking.type';
import { TEMP_GUEST } from 'constants/common';

interface CustomerFormProps {
  selectedCustomerId: string | number;
  isShowUpload: boolean;
  name: string;
  form: FormInstance;
  isBookingForm?: boolean;
  isEdit?: boolean;
  onFillCustomerDetails?: (info: any) => void;
  bookingLineDetail?: BookingLine;
}

export const CustomerForm = (props: CustomerFormProps) => {
  const {
    selectedCustomerId,
    form,
    name,
    isShowUpload,
    isBookingForm,
    isEdit,
    onFillCustomerDetails,
    bookingLineDetail
  } = props;
  const { data: countries } = useGetCountries();
  const [searchName, setSearchName] = useState('');
  const [viewMode, setViewMode] = useState(false);
  const [isOpenEditCustomer, setIsOpenEditCustomer] = useState(false);
  const [isLoadingBookingDetail, setIsLoadingBookingDetail] = useState(false);

  const updateFormFields = (updatedInfo: any) => {
    const formattedInfo = {
      customers: {
        [selectedCustomerId]: {
          images: updatedInfo.customers[selectedCustomerId].images
        }
      }
    };
    const formValues = form.getFieldsValue();
    formValues.customers = updatedInfo.customers;
    form.setFieldsValue(formValues);
    onFillCustomerDetails && onFillCustomerDetails(formattedInfo);
  };

  const { data: customers } = useGetCustomers(
    { search: searchName },
    'person',
    !!searchName && searchName.length > 3
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchName = useCallback(
    _.debounce((value: string) => {
      setSearchName(value);
    }, 500),
    []
  );
  const formKey = name ? [name, selectedCustomerId] : [selectedCustomerId];
  const country_id = Form.useWatch([...formKey, 'country_id'], form);
  const { data: states } = useGetAreas(country_id);
  const profile_id = Form.useWatch([...formKey, 'profile_id'], form);
  const guest = Form.useWatch([...formKey, 'guest'], form);

  const reset = () => {
    form.resetFields(formKey);
    setViewMode(false);
  };

  const handleSelectCustomerAutoComplete = (customerId: number) => {
    const customer = _.find(customers, { id: customerId });
    // const customerForm = form.getFieldValue(["customers", selectedCustomerId]);
    if (customer) {
      const data: {
        [key: string]: any;
      } = {
        profile_id: customer.id,
        name: customer.name,
        gender: customer.gender,
        phone: customer.phone,
        email: customer.email,
        dob: customer.dob ? dayjs(customer.dob) : undefined,
        identification: customer.identification,
        country_id: customer.country?.id,
        state_id: customer.state?.id,
        street: customer.street,
        comment: customer.comment,
        attachments: customer.attachments,
        guest
      };
      const truthyValues: any = Object.keys(data)
        .filter(key => Boolean(data[key]))
        .reduce((cur, next) => {
          return {
            ...cur,
            [next]: data[next]
          };
        }, {});

      form.setFieldValue(formKey, truthyValues);

      if (isBookingForm) {
        setViewMode(true);
      }
    }
  };

  const handleOkEditCustomer = (customer: PersonalInfoType) => {
    form.setFieldValue(
      formKey,
      trimTruthyValues({
        profile_id: customer.id,
        name: customer.name,
        gender: customer.gender,
        phone: customer.phone,
        email: customer.email,
        dob: customer.dob ? dayjs(customer.dob) : undefined,
        identification: customer.identification,
        country_id: customer.country?.id,
        state_id: customer.state?.id,
        street: customer.street,
        comment: customer.comment,
        attachments: customer.attachments,
        remove_images: []
      })
    );
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CUSTOMERS, 'person', searchName]
    });
    setIsOpenEditCustomer(false);
  };
  const handleShowEditCustomer = () => {
    setIsOpenEditCustomer(true);
  };

  const handleCloseEditCustomer = () => {
    setIsOpenEditCustomer(false);
  };

  const handleReloadCustomer = async () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CUSTOMERS, 'person', searchName]
    });
  };

  const handleReloadCustomerInBooking = async () => {
    setIsLoadingBookingDetail(true);
    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
    });
    setIsLoadingBookingDetail(false);
  };

  const renderCustomerOptions = useMemo(() => {
    return customers?.map(item => ({
      label: (
        <div className="customer-autocomplete-item">
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IconMale />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.name}
              </p>
            </div>
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IdcardOutlined />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.identification || '-'}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IconEmail />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.email || '-'}
              </p>
            </div>
            <div className="flex items-center" style={{ gap: '8px' }}>
              <PhoneFilled />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.phone || '-'}
              </p>
            </div>
          </div>
        </div>
      ),
      value: item.id
    }));
  }, [customers]);

  const customer = form.getFieldValue(formKey);
  if (isBookingForm && viewMode) {
    const customerInfo: PersonalInfoType = form.getFieldValue(formKey);
    return (
      <div className="customer-info-container">
        <h5 className="title flex items-center gap-2">
          Khách hàng
          <Button onClick={reset} type="text" shape="circle" icon={<UndoOutlined />}></Button>
        </h5>
        <Form.Item hidden name={[selectedCustomerId, 'profile_id']}>
          <Input />
        </Form.Item>
        {!_.isEmpty(customer.attachments) && <Photos photos={customer.attachments} />}

        <div className="customer-info-item">
          <div className="flex items-center" style={{ gap: '8px' }}>
            <UserOutlined />
            <span>{customerInfo?.name}</span>
          </div>
          <div className="flex items-center" style={{ gap: '8px' }}>
            <IdcardOutlined />
            <span>{customerInfo?.identification}</span>
          </div>
        </div>
        <div className="customer-info-item">
          <div className="flex items-center" style={{ gap: '8px' }}>
            <PhoneFilled />
            <span>{customerInfo?.phone}</span>
          </div>
        </div>
        <div className="customer-info-item">
          <div className="flex items-center" style={{ gap: '8px' }}>
            <IconCake />
            <span>{customerInfo?.dob ? dayjs(customerInfo?.dob).format('DD/MM/YYYY') : ''}</span>
          </div>
          <div className="flex items-center" style={{ gap: '8px' }}>
            <span>
              {customerInfo?.dob ? `${dayjs().diff(dayjs(customerInfo?.dob), 'year')} tuổi` : ''}
            </span>
          </div>
        </div>
        <div className="customer-info-item">
          <div className="flex items-center" style={{ gap: '8px' }}>
            <HomeFilled />
            <span>{customerInfo?.contact_address_complete}</span>
          </div>
        </div>

        {/*<div className="customer-stats">*/}
        {/*  <div className="customer-info-item">*/}
        {/*    <span>Số lần khách ở</span>*/}
        {/*    <span className="customer-stats__text">1 lần</span>*/}
        {/*  </div>*/}
        {/*  <div className="customer-info-item">*/}
        {/*    <span>Tổng tiền đã tiêu</span>*/}
        {/*    <span className="customer-stats__text">879,200</span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="text-center" style={{ marginTop: '20px' }}>
          <Button onClick={handleShowEditCustomer}>Sửa thông tin khách</Button>
        </div>

        <EditCustomerModal
          isOpen={isOpenEditCustomer}
          customer={customer}
          onOk={handleOkEditCustomer}
          onCancel={handleCloseEditCustomer}
          onReload={handleReloadCustomer}
        />
      </div>
    );
  }

  return (
    <>
      <div className="modal-customer-body__form">
        <h5 className="title flex items-center gap-2">
          #{guest?.guest_id} Khách hàng
          {profile_id && (
            <Tooltip title="Làm mới" arrow={false}>
              <Button
                onClick={handleReloadCustomerInBooking}
                type="text"
                shape="circle"
                icon={<ReloadOutlined />}
                loading={isLoadingBookingDetail}
              ></Button>
            </Tooltip>
          )}
          {profile_id && !name && !isEdit && (
            <Button onClick={reset} type="text" shape="circle" icon={<UndoOutlined />}></Button>
          )}
        </h5>
        <div className="modal-customer-body__form-row">
          <Form.Item name={[selectedCustomerId, 'profile_id']} hidden>
            <input />
          </Form.Item>
          <Form.Item
            label={
              <span className="flex gap-2">
                Tên khách -{' '}
                {profile_id && profile_id != TEMP_GUEST ? `Hồ sơ ${profile_id}` : 'Hồ sơ mới'}
                {profile_id && !name ? <IconRegisterCard className="icon-sm" /> : ''}
              </span>
            }
            name={[selectedCustomerId, 'name']}
            style={{ flexGrow: 5 }}
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_FULL_NAME
              }
            ]}
          >
            <AutoComplete
              placeholder="Tìm kiếm tên, phone hoặc tạo mới"
              popupClassName="autocomplete-customer"
              onSearch={handleSearchName}
              onSelect={handleSelectCustomerAutoComplete}
              options={renderCustomerOptions}
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item label="Giới tính" name={[selectedCustomerId, 'gender']}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={SEX.MALE}>
                <IconMale />
                {/* Nam */}
              </Radio.Button>
              <Radio.Button value={SEX.FEMALE}>
                <IconFemale />
                {/* Nữ */}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        {bookingLineDetail && (
          <div className="modal-customer-body__form-row">
            <Form.Item name={[selectedCustomerId, 'guest', 'guest_id']} hidden>
              <input />
            </Form.Item>
            <Form.Item valuePropName="checked" name={[selectedCustomerId, 'guest', 'main_guest']}>
              <Checkbox>
                <span>Khách chính</span>
              </Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name={[selectedCustomerId, 'guest', 'is_child']}>
              <Checkbox>
                <span>Trẻ em</span>
              </Checkbox>
            </Form.Item>
          </div>
        )}

        <div className="modal-customer-body__form-row">
          <Form.Item label="Di động" name={[selectedCustomerId, 'phone']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name={[selectedCustomerId, 'email']}
            style={{ flexGrow: 5 }}
            rules={[
              {
                pattern: EmailRegex,
                message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="modal-customer-body__form-row">
          <Form.Item
            label="Ngày sinh"
            name={[selectedCustomerId, 'dob']}
            rules={[
              {
                required: isBookingForm,
                message: MESSAGE_CODE.REQUIRED_DOB
              }
            ]}
          >
            <DatePicker format="DD/MM/YYYY" disabledDate={disabledDateFuture} />
          </Form.Item>
          <Form.Item
            label="ID/Passport"
            name={[selectedCustomerId, 'identification']}
            rules={[
              {
                required: isBookingForm,
                message: MESSAGE_CODE.REQUIRED_ID
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Quốc tịch"
            name={[selectedCustomerId, 'country_id']}
            style={{ flexGrow: 2 }}
            rules={[
              {
                required: isBookingForm,
                message: MESSAGE_CODE.REQUIRED_NATIONALITY
              }
            ]}
          >
            <Select
              showSearch
              filterOption={(input: string, option: any) => {
                return option.name.toLowerCase().includes(input.toLowerCase().trim());
              }}
              options={countries || []}
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
        </div>

        <div className="modal-customer-body__form-row">
          <Form.Item label="Xã quận huyện" name={[selectedCustomerId, 'state_id']}>
            <Select
              showSearch
              filterOption={(input: string, option: any) => {
                return option.name.toLowerCase().includes(input.toLowerCase().trim());
              }}
              options={states || []}
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
          <Form.Item label="Địa chỉ" name={[selectedCustomerId, 'street']} style={{ flexGrow: 5 }}>
            <Input />
          </Form.Item>
        </div>

        <Form.Item label="Ghi chú" name={[selectedCustomerId, 'comment']} className="w-full">
          <Input.TextArea rows={4} placeholder="Ghi chú" />
        </Form.Item>
      </div>
      {isShowUpload && (
        <div className="modal-customer-body__upload">
          <CustomerPhotos
            selectedCustomerId={selectedCustomerId}
            isEdit={isEdit}
            form={form}
            onFillCustomerDetails={updateFormFields}
          />
        </div>
      )}
    </>
  );
};
