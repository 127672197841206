import { create } from 'zustand';
import { BranchesType } from 'services/api/type/branch.type';

export interface CommonType {
  activeMenuId: number;
  setActiveMenuId: (menuId: number) => void;

  activeMenuManageId: number;
  setActiveMenuManageId: (menuId: number) => void;

  currentBranch: BranchesType | undefined;
  setCurrentBranch: (branch: BranchesType | undefined) => void;
}

const useCommonStore = create<CommonType>(set => ({
  activeMenuId: 0,
  setActiveMenuId: (menuId: number) => set(() => ({ activeMenuId: menuId })),

  activeMenuManageId: 0,
  setActiveMenuManageId: (menuId: number) => set(() => ({ activeMenuManageId: menuId })),

  currentBranch: undefined,
  setCurrentBranch: (currentBranch: BranchesType | undefined) => set(() => ({ currentBranch }))
}));

export default useCommonStore;
