import _ from 'lodash';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const borderColor = '#000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 28
  },
  total: {
    width: '45%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 4px'
  },
  price: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  qty: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  serviceFee: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  tax: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  amount: {
    width: '15%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  textBold: { fontSize: 11, fontFamily: 'Inter', fontWeight: 600 }
});

interface Props {
  remainAmount: number;
  listTax: {
    [key: number]: boolean;
  };
}

const InvoiceTableFooter = ({ remainAmount, listTax }: Props) => {
  return (
    <View style={styles.row}>
      <View style={styles.total}>
        <Text style={styles.textBold}>Tổng (Total Amount)</Text>
      </View>

      <View style={styles.price}>
        <Text style={styles.textBold}></Text>
      </View>
      <View style={styles.qty}>
        <Text style={styles.textBold}></Text>
      </View>
      <View style={styles.serviceFee}>
        <Text style={styles.textBold}></Text>
      </View>
      {Object.keys(listTax).map((tax, index) => (
        <View style={styles.tax} key={index}>
          <Text style={styles.textBold}></Text>
        </View>
      ))}
      <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
        <Text style={styles.textBold}>{remainAmount?.toLocaleString('vn')}</Text>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
