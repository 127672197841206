import { getAllLabels } from 'services/api/module/labels.api';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { useMemo } from 'react';

export const useGetAllLabels = (labelName: string = '') => {
  const { data: dataLabels, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_LABELS, labelName],
    queryFn: () => getAllLabels(labelName || undefined)
  });

  const labels = useMemo(() => {
    return _.get(dataLabels, 'data', []);
  }, [dataLabels]);

  return {
    data: labels,
    isLoading
  };
};
