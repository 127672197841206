import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  notification
} from 'antd';
import { useMutation } from '@tanstack/react-query';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import {
  PAYMENT_FOR,
  PAYMENT_FOR_OPTIONS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_TYPE
} from 'constants/form';
import API from 'services/api';
import { MakePayment } from 'services/api/type/payment.type';
import { formatInputNumber } from 'utils/currency';

const today = dayjs();
function ResolveDocumentModal() {
  const [form] = Form.useForm();
  const { bookingLineDetail } = useBookingStore();
  const { isOpenResolveDocument, setIsOpenResolveDocument } = useModal();

  const { mutateAsync: mutateMakePayment, isPending } = useMutation({
    mutationFn: (params: MakePayment) =>
      API.payment.makePayment(Number(bookingLineDetail?.booking_line_id), params)
  });

  useEffect(() => {
    if (isOpenResolveDocument) {
      form.resetFields();
      form.setFieldsValue({
        date: today,
        name: bookingLineDetail?.partner_name,
        payment_method: PAYMENT_METHOD.CASH,
        income_type: PAYMENT_FOR.ROOM,
        amount: (bookingLineDetail?.total_price || 0) - (bookingLineDetail?.paid_amount || 0)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpenResolveDocument]);

  const handleCancel = () => {
    setIsOpenResolveDocument(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutateMakePayment({
        amount: Math.abs(values.amount),
        note: values.note,
        payment_method: values.payment_method,
        payment_type: values.amount > 0 ? PAYMENT_TYPE.INCOME : PAYMENT_TYPE.EXPENSE,
        income_type: values.income_type
      });
      setIsOpenResolveDocument(false);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
      });
      notification.success({
        message: 'Thanh toán thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title={`Thanh toán #${bookingLineDetail?.sale_order_name}`}
      className="modal-resolve-document"
      centered
      width={400}
      open={isOpenResolveDocument}
      okText="Thêm"
      cancelText="Đóng"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="resolve-document-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          date: today,
          name: bookingLineDetail?.partner_name,
          payment_method: PAYMENT_METHOD.CASH,
          income_type: PAYMENT_FOR.ROOM,
          amount: 0,
          note: bookingLineDetail?.note
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Ngày" name="date">
          <DatePicker format="DD/MM/YYYY" className="w-full" />
        </Form.Item>

        <Form.Item label="Tên" name="name">
          <Input readOnly />
        </Form.Item>

        <Form.Item label="Phương thức" name="payment_method">
          <Select options={PAYMENT_METHOD_OPTIONS} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Thanh toán cho" name="income_type">
              <Select options={PAYMENT_FOR_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số tiền" name="amount">
              <InputNumber
                min={0}
                formatter={value => formatInputNumber(value)}
                className="w-full"
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Ghi chú" name="note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ResolveDocumentModal;
