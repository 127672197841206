import React, { useMemo, useState, useRef } from 'react';
import dayjs from 'dayjs';

import { useMutation } from '@tanstack/react-query';
import { Button, notification, Spin } from 'antd';

import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';

import { ParamsType } from 'services/api/type/ocr-customer-info.type';
import { getBase64, rotateImage } from 'utils';
import 'styles/scan-customer-info.scss';

enum ScanType {
  CCCD = 'cccd',
  PASSPORT = 'passport'
}

function ScanCustomerInfo() {
  const [photoUrlScan, setPhotoUrlScan] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>(ScanType.CCCD);
  const [rotation, setRotation] = useState(0);
  const [showButtonChooseKey, setShowButtonChooseKey] = useState<boolean>(true);
  const [showCustomerDetail, setShowCustomerDetail] = useState<boolean>(false);
  const [showButtonAction, setShowButtonAction] = useState<boolean>(false);
  const [dataResponse, setDataResponse] = useState<any>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: mutateImageOCR, isPending } = useMutation({
    mutationFn: (values: ParamsType) => API.ocr.scanCustomerInfo(values)
  });

  const handleChangeTab = (key: string) => {
    setActiveKey(key);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const reset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setPhotoUrlScan('');
    setRotation(0);
  };

  const handleRestart = () => {
    reset();
    setShowButtonChooseKey(true);
    setShowCustomerDetail(false);
    setShowButtonAction(false);
    setDataResponse(null);
  };

  const handleRetry = () => {
    handleChangeTab(activeKey);
    setShowCustomerDetail(false);
    setShowButtonAction(true);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setShowButtonChooseKey(false);
      setShowButtonAction(true);
      const rotatedBlob: any = await rotateImage(file, rotation);
      setPhotoUrlScan(URL.createObjectURL(rotatedBlob));

      try {
        const fileRotateScan = new File([rotatedBlob], file.name, { type: file.type });

        const imageBase64 = await getBase64(fileRotateScan);
        const response = await mutateImageOCR({
          identification_card: activeKey,
          image_binary: String(imageBase64),
          method: 'camera',
          image_binary_to_scan: ''
        });

        setDataResponse(response);
        setShowCustomerDetail(true);

        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_LATEST_OCR_CUSTOMER_INFO] });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const ScanContent = useMemo(() => {
    if (photoUrlScan) {
      return <img className="main-image" src={photoUrlScan} alt="ocr" />;
    }
  }, [photoUrlScan]);

  return (
    <div className="scan-customer-info-page">
      <div className="scan-customer-info-buttons">
        <div>{ScanContent}</div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="image/png, image/jpeg, image/jpg, image/bmp"
        />
        {showButtonChooseKey && (
          <>
            <Button
              className="ant-btn-fifth"
              type={activeKey === ScanType.CCCD ? 'primary' : 'default'}
              onClick={() => handleChangeTab(ScanType.CCCD)}
            >
              CMND/CCCD
            </Button>
            <Button
              className="ant-btn-fourth"
              type={activeKey === ScanType.PASSPORT ? 'primary' : 'default'}
              onClick={() => handleChangeTab(ScanType.PASSPORT)}
            >
              Hộ chiếu
            </Button>
          </>
        )}
      </div>

      {isPending ? (
        <div className="loading-spinner pixed-spinner">
          <Spin size="large" tip="Đang xử lý..." />
        </div>
      ) : (
        dataResponse &&
        showCustomerDetail && (
          <div className="customer_details">
            {['Full Name', 'Identification code', 'Gender', 'Date of Birth', 'Nationality'].map(
              (label, index) => (
                <p key={index}>
                  <strong>{label}:</strong>{' '}
                  {
                    [
                      dataResponse.name,
                      dataResponse.identification,
                      dataResponse.gender,
                      dayjs(dataResponse.dob).format('DD/MM/YYYY'),
                      dataResponse.country_id
                    ][index]
                  }
                </p>
              )
            )}
          </div>
        )
      )}

      {showButtonAction && !isPending && (
        <div className="scan-customer-info-footer">
          <Button className="ant-btn-secondary" onClick={handleRetry}>
            Chụp lại
          </Button>
          <Button className="ant-btn-third" onClick={handleRestart}>
            Quay lại
          </Button>
        </div>
      )}
    </div>
  );
}

export default ScanCustomerInfo;
