import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { Labels } from '../type/labels.type';

export const getAllLabels = async (label_name?: string): Promise<Labels> => {
  return axiosInstance
    .get(paths.getLabels(), {
      params: label_name ? { label_name } : {}
    })
    .then(item => item.data);
};

export const createAndAssignLabels = async (
  bookingLineId: number,
  label_name: string
): Promise<any> => {
  return axiosInstance
    .post(paths.createAndAssignLabel(bookingLineId), {
      label_name
    })
    .then(response => response.data);
};

export const unassignLabels = async (bookingLineId: number, label_name: string): Promise<any> => {
  return axiosInstance
    .put(paths.unassignLabels(bookingLineId), {
      label_name
    })
    .then(response => response.data);
};
