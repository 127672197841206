import React, { useState } from 'react';

import 'styles/common.scss';

interface Props {
  photos: [
    {
      id: number;
      name: string;
      url: string;
    }
  ];
}

function Photos({ photos }: Props) {
  const [selectedPhoto, setSelectedPhoto] = useState<number>(0);

  const handleSelectPhoto = (index: number) => {
    setSelectedPhoto(index);
  };

  return (
    <div className="photos-display">
      <div className="main-image">
        <img src={photos[selectedPhoto].url} alt="thumbnail" />
      </div>
      <div className="photos-display-list">
        {photos.map((item, index) => (
          <div className="photos-display-item" key={index} onClick={() => handleSelectPhoto(index)}>
            <img src={item.url} alt="thumbnail" />
            {selectedPhoto !== index && <div className="overlay" />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Photos;
