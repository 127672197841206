import { GetFocsType } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetRoomsFoc = (filterOptions: GetFocsType, isEnable: boolean = true) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_FOC_DETAILS, filterOptions],
    queryFn: () => API.report.getRoomsFoc(filterOptions),
    enabled: isEnable
  });

  return {
    data: data?.rooms_foc ?? [],
    isLoading
  };
};

export const useGetServicesFoc = (filterOptions: GetFocsType, isEnable: boolean = true) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_FOC_DETAILS, filterOptions],
    queryFn: () => API.report.getServicesFoc(filterOptions),
    enabled: isEnable
  });

  return {
    data: data?.services_foc ?? [],
    isLoading
  };
};
