import { ReactElement, SVGProps } from 'react';
import { ReactComponent as IconCheckout } from 'assets/images/checkout.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { ReactComponent as IconCheckCalendar } from 'assets/images/check-calendar.svg';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { BookingStatus } from 'services/api/type/booking.type';

export const MAP_ICON_ROOM_STATUS: {
  [key: string]: (props: SVGProps<SVGElement>) => ReactElement;
} = {
  [BookingStatus.CONFIRM]: IconCheckCalendar,
  [BookingStatus.CHECK_IN]: IconBedUser,
  [BookingStatus.CHECK_OUT]: IconCheckout,
  [BookingStatus.CANCEL]: IconClose
};
