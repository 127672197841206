import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, Form, FormProps, Modal, notification, Slider, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import useModalStore from 'stores/useModal';
import { useGetGroupBookingDetail } from 'hooks/useGetGroupBookings';

import { getColumnChangeDateGroup } from 'constants/table';
import { RangeValueType } from 'services/api/type/common.type';
import { ParamsChangeDate, SubGroup } from 'services/api/type/group.type';
import { changeDateSubgroup } from 'services/api/module/group.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { diffDaysWithSignal } from 'utils/dateUtils';

function ChangeDateGroupBookingModal() {
  const [form] = Form.useForm();
  const { groupId } = useParams();
  const checkInForm = Form.useWatch('check_in', form);

  const { isOpenChangeDateGroupBooking, setIsOpenChangeDateGroupBooking } = useModalStore();

  const { data: dataGroup, isFetching: isFetchingGroup } = useGetGroupBookingDetail(
    Number(groupId)
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [rangeDate, setRangeDate] = useState<RangeValueType>([null, null]);
  const [subgroup, setSubgroup] = useState<SubGroup[]>([]);

  const { mutateAsync: mutateChangeDateSubGroup, isPending } = useMutation({
    mutationFn: (params: ParamsChangeDate) => {
      return changeDateSubgroup(Number(groupId), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GROUP_BOOKING_DETAIL, Number(groupId)]
      });
    }
  });

  useEffect(() => {
    if (!isOpenChangeDateGroupBooking) {
      form.resetFields();
      setSelectedRowKeys([]);
    } else {
      if (!_.isEmpty(dataGroup)) {
        const startDate = dayjs(dataGroup.first_arrival);
        const endDate = dayjs(dataGroup.last_departure);

        form.setFieldsValue({
          check_in: startDate,
          check_out: endDate
        });

        setRangeDate([startDate, endDate]);
        setSubgroup(
          dataGroup?.sub_groups.map(item => ({
            ...item,
            groupId: dataGroup.id
          }))
        );
        setSelectedRowKeys(dataGroup?.sub_groups.map(item => item.id));
      }
    }
  }, [dataGroup, form, isOpenChangeDateGroupBooking]);

  const handleChangeDate = () => {
    form.submit();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const applyChangeDate = (day: Dayjs, type: 'check_in' | 'check_out') => {
    if (!_.isEmpty(selectedRowKeys)) {
      const newSubgroup = subgroup.map(item => {
        if (selectedRowKeys.includes(item.id)) {
          const cloneItem = _.clone(item);
          _.set(cloneItem, type, day);
          return cloneItem;
        }
        return item;
      });
      setSubgroup(newSubgroup);
    }
  };

  const handleChangeArrival = (day: Dayjs) => {
    setRangeDate([day, rangeDate[1]]);
    applyChangeDate(day, 'check_in');
  };

  const handleChangeDeparture = (day: Dayjs) => {
    setRangeDate([rangeDate[0], day]);
    applyChangeDate(day, 'check_out');
  };

  const handleChangeCheckInSlide = (value: any) => {
    setRangeDate([dayjs(value), rangeDate[1]]);
  };

  const handleChangeCheckOutSlide = (value: any) => {
    setRangeDate([rangeDate[0], dayjs(value)]);
  };

  const handleChangeCheckInComplete = (value: any) => {
    const date = dayjs(value);
    form.setFieldValue('check_in', date);
    applyChangeDate(date, 'check_in');
  };

  const handleChangeCheckoutComplete = (value: any) => {
    const date = dayjs(value);
    form.setFieldValue('check_out', date);
    applyChangeDate(date, 'check_out');
  };

  const handleAdjustCheckIn = (value: number) => {
    if (rangeDate[0]) {
      const date = rangeDate[0]?.add(value, 'day');
      form.setFieldValue('check_in', date);
      applyChangeDate(date, 'check_in');
      setRangeDate([date, rangeDate[1]]);
    }
  };

  const handleAdjustCheckOut = (value: number) => {
    if (rangeDate[1]) {
      const date = rangeDate[1]?.add(value, 'day');
      form.setFieldValue('check_out', date);
      applyChangeDate(date, 'check_out');
      setRangeDate([rangeDate[0], date]);
    }
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      console.log(values);
      const startDate: Dayjs = values.check_in;
      const endDate: Dayjs = values.check_out;
      await mutateChangeDateSubGroup({
        first_arrival: startDate.format('YYYY-MM-DD'),
        last_departure: endDate.format('YYYY-MM-DD'),
        sub_group_ids: selectedRowKeys as number[]
      });

      notification.success({
        message: 'Thay đổi ngày thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const disabledRangedDateCheckOut = useCallback(
    (current: Dayjs) => {
      return Boolean(
        current && (current.isBefore(checkInForm, 'day') || current.isSame(checkInForm, 'day'))
      );
    },
    [checkInForm]
  );

  const rangeSlideCheckIn = useMemo(() => {
    return [
      dayjs(dataGroup?.first_arrival).subtract(5, 'day'),
      dayjs(dataGroup?.first_arrival).add(5, 'day')
    ];
  }, [dataGroup?.first_arrival]);

  const rangeSlideCheckOut = useMemo(() => {
    return [
      dayjs(dataGroup?.last_departure).subtract(5, 'day'),
      dayjs(dataGroup?.last_departure).add(5, 'day')
    ];
  }, [dataGroup?.last_departure]);

  const columnChangeDateGroup = useMemo(() => {
    return getColumnChangeDateGroup();
  }, []);

  return (
    <Modal
      title="Change date for group"
      centered
      width={1200}
      open={isOpenChangeDateGroupBooking}
      onCancel={() => setIsOpenChangeDateGroupBooking(false)}
      closeIcon={<IconClose />}
      okText="Xác nhận"
      cancelText="Hủy"
      onOk={handleChangeDate}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-change-date-group-booking"
      destroyOnClose
      confirmLoading={isPending}
    >
      <div className="pms-room-lock__table">
        <Table
          loading={isFetchingGroup}
          rowKey="id"
          columns={columnChangeDateGroup}
          dataSource={subgroup}
          locale={{
            emptyText: <span className="empty-data">Không có dữ liệu</span>
          }}
          scroll={{ x: 800, y: 'calc(100vh - 320px)' }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange
          }}
          pagination={false}
        />
      </div>

      <Form
        form={form}
        name="change-date-group-booking-form"
        layout="inline"
        style={{ width: '100%', marginTop: 24, justifyContent: 'center' }}
        initialValues={{}}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div style={{ marginRight: 50 }}>
          <div className="flex" style={{ gap: 8 }}>
            <div>
              <span className="text-gray-500">Old Arrival Date: {dataGroup?.first_arrival}</span>
            </div>
          </div>
          <Slider
            included={false}
            marks={{
              [dayjs(dataGroup?.first_arrival).valueOf()]: ' '
            }}
            value={rangeDate[0]?.startOf('day').valueOf()}
            min={rangeSlideCheckIn[0]?.startOf('day').valueOf()}
            max={rangeSlideCheckIn[1]?.startOf('day').valueOf()}
            step={24 * 60 * 60 * 1000} // 1 day in milliseconds
            tooltip={{ formatter: value => dayjs(value).format('YYYY-MM-DD') }}
            onChange={handleChangeCheckInSlide}
            onChangeComplete={handleChangeCheckInComplete}
          />
          <div className="flex" style={{ gap: 8 }}>
            <Form.Item label="New Date" name="check_in" className="m-0">
              <DatePicker allowClear={false} format="DD/MM/YYYY" onChange={handleChangeArrival} />
            </Form.Item>
            <Button
              onClick={() => handleAdjustCheckIn(1)}
              className="btn-adjust"
              disabled={rangeDate[0]?.isSame(rangeSlideCheckIn[1], 'day')}
            >
              {diffDaysWithSignal(rangeDate[0]?.format('YYYY-MM-DD'), dataGroup?.first_arrival)}{' '}
            </Button>
          </div>
        </div>

        <div>
          <div className="flex" style={{ gap: 8 }}>
            <div>
              <span className="text-gray-500">Old Departure Date: {dataGroup?.last_departure}</span>
            </div>
          </div>
          <Slider
            included={false}
            marks={{
              [dayjs(dataGroup?.last_departure).valueOf()]: ' '
            }}
            value={rangeDate[1]?.startOf('day').valueOf()}
            min={rangeSlideCheckOut[0]?.startOf('day').valueOf()}
            max={rangeSlideCheckOut[1]?.startOf('day').valueOf()}
            step={24 * 60 * 60 * 1000} // 1 day in milliseconds
            tooltip={{ formatter: value => dayjs(value).format('YYYY-MM-DD') }}
            onChange={handleChangeCheckOutSlide}
            onChangeComplete={handleChangeCheckoutComplete}
          />
          <div className="flex" style={{ gap: 8 }}>
            <Form.Item label="New Date" name="check_out" style={{ margin: 0 }}>
              <DatePicker
                allowClear={false}
                format="DD/MM/YYYY"
                onChange={handleChangeDeparture}
                disabledDate={disabledRangedDateCheckOut}
              />
            </Form.Item>
            <Button
              onClick={() => handleAdjustCheckOut(1)}
              className="btn-adjust"
              disabled={rangeDate[1]?.isSame(rangeSlideCheckOut[1], 'day')}
            >
              {diffDaysWithSignal(rangeDate[1]?.format('YYYY-MM-DD'), dataGroup?.last_departure)}{' '}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default ChangeDateGroupBookingModal;
