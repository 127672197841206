import { create } from 'zustand';

export interface HKStoreType {
  selectedRoomIds: number[];
  setSelectedRoomIds: (ids: number[]) => void;
}

const useHKStore = create<HKStoreType>(set => ({
  selectedRoomIds: [],
  setSelectedRoomIds: (ids: number[]) => set(() => ({ selectedRoomIds: ids }))
}));

export default useHKStore;
