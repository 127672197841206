import { Table } from 'antd';

interface CustomTableProps {
  rowKey: string;
  loading?: boolean;
  columns: any;
  dataSource: any[];
}

export default function CustomTable({ rowKey, loading, columns, dataSource }: CustomTableProps) {
  return (
    <Table
      rowKey={rowKey}
      loading={loading ? loading : false}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      scroll={{ x: 1000, y: 'calc(80vh - 260px)' }}
      bordered
      locale={{
        emptyText: <span className="empty-data">Không có dữ liệu</span>
      }}
    />
  );
}
