import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { ParamsCreateShiftHandover } from '../type/shift-handover.type';
import { ShiftHandover } from '../type/shift-handover.type';

export const createShiftHandover = async (params: ParamsCreateShiftHandover): Promise<number> => {
  return axiosInstance.post(paths.createShiftHandover(), params).then(response => response.data);
};

export const getCurrentShiftHandover = async (): Promise<ShiftHandover> => {
  return axiosInstance.get(paths.getCurrentShiftHandover()).then(item => item.data.data);
};
