import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetAreas = (countryId: number) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_AREAS, countryId],
    queryFn: () => API.common.getAreas(countryId),
    enabled: !!countryId
  });

  return {
    data,
    status,
    isLoading,
    isFetching
  };
};
